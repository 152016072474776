import axios, { type AxiosRequestConfig } from 'axios';

import { AUTH_SESSION_KEY } from './constants';
import { removeFalsyValues } from './utils';

axios.interceptors.request.use((req: AxiosRequestConfig) => {
  const authToken = sessionStorage.getItem(AUTH_SESSION_KEY);

  if (!authToken) return req;

  req.headers = removeFalsyValues({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken}`,
    ...req.headers,
  });

  return req;
});
