import { type ChangeEventHandler, useEffect, useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';
import debounce from 'lodash.debounce';
import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';
import { EmailWithDomainInput, FormCard, Input, Loader } from 'ui';

import { useSuggestedDraftsStore } from 'stores/suggested-drafts';
import useCustomerDomain from '../hooks/useCustomerDomain';
import { isDraftForSingleCustomer } from 'core/utils';

type FromFormProps = {
  open: boolean;
  onClick: () => void;
  disabled?: boolean;
};

type FormValues = {
  senderName: string;
  senderEmail: string;
};

const DEBOUNCE_DELAY_TIME_MS = 500;

const formValidation = yup.object({
  senderName: yup.string().min(2, 'Sender name is invalid.'),
  senderEmail: yup.string().test('email-prefix', 'Invalid value.', (val) => {
    if (!val || val?.length < 2) return false;
    return !val.includes('@');
  }),
});

const FromForm = (props: FromFormProps): JSX.Element => {
  const { open, onClick, disabled = false } = props;
  const { senderName, senderEmail, updateEmailConfig, tenantIds } = useSuggestedDraftsStore(
    useShallow((state) => ({
      senderName: state.draftDetails.emailConfig.senderName,
      senderEmail: state.draftDetails.emailConfig.senderEmail,
      updateEmailConfig: state.updateEmailConfig,
      tenantIds: state.draftDetails.tenantIds,
    })),
  );
  const isSingleCustomer = isDraftForSingleCustomer(tenantIds);
  const { loading, domain } = useCustomerDomain(isSingleCustomer ? tenantIds[0] : undefined);
  const form = useForm<FormValues>({
    initialValues: { senderName, senderEmail: senderEmail?.split('@')[0] || '' },
    validate: yupResolver(formValidation),
    validateInputOnChange: true,
  });
  const { reset } = form;

  useEffect(() => {
    if (!isSingleCustomer) {
      reset();
    }
  }, [isSingleCustomer, reset]);

  const debouncedUpdate = useMemo(() => debounce(updateEmailConfig, DEBOUNCE_DELAY_TIME_MS), [updateEmailConfig]);

  const handleEmailEdit: ChangeEventHandler<HTMLInputElement> = (e) => {
    const senderEmail = e.target.value;
    form.setFieldValue('senderEmail', senderEmail);
    const email = senderEmail ? `${senderEmail}@${domain}` : '';
    debouncedUpdate({ senderEmail: email });
  };

  const handleNameEdit: ChangeEventHandler<HTMLInputElement> = (e) => {
    const senderName = e.target.value;
    form.setFieldValue('senderName', senderName);
    debouncedUpdate({ senderName });
  };

  return (
    <FormCard
      title="From"
      subtitle="Who is receiving this campaign?"
      isExpanded={open}
      handleClick={onClick}
      checked={form.isValid() && isSingleCustomer}
      disabled={!isSingleCustomer}
    >
      <div className="space-y-6">
        <div className="space-y-4">
          <p className="text-base font-semibold">The name subscribers will see as the sender.</p>
          <Input
            id="name"
            label="Name"
            value={form.values.senderName}
            onChange={handleNameEdit}
            placeholder="Insert Name"
            disabled={disabled}
            error={!!form.errors.senderName}
            helperText={(form.errors.senderName as string) || ''}
            size="xl"
          />
        </div>
        <div className="relative space-y-4">
          <p className="text-base font-semibold">
            The address the subscribers will see as the sender. Must be from your domain
          </p>
          <EmailWithDomainInput
            id="youremail"
            label="Email Address"
            value={form.values.senderEmail}
            onChange={handleEmailEdit}
            authenticatedDomain={domain}
            placeholder="youremail"
            disabled={!domain || disabled}
            error={!!form.errors.senderEmail}
            helperText={(form.errors.senderEmail as string) || ''}
            size="xl"
          />
          {loading && <Loader className="top-5" />}
        </div>
      </div>
    </FormCard>
  );
};

export default FromForm;
