import { type FC } from 'react';

import './styles.css';

type SideBarIconButtonProps = {
  Icon: JSX.Element;
  SelectedIcon: JSX.Element;
  selected?: boolean;
  onClick?: () => void;
  text?: string;
};

const SideBarIconButton: FC<SideBarIconButtonProps> = ({
  Icon,
  SelectedIcon,
  onClick,
  selected = false,
  text = '',
}) => {
  return (
    <button onClick={onClick}>
      <div className={selected ? 'selected-sidebar-icon !bg-primary-700' : 'sidebar-icon'} id={text + '-button'}>
        {selected ? SelectedIcon : Icon}
        <span className="sidebar-tooltip !bg-primary-100 !text-primary-700">{text}</span>
      </div>
    </button>
  );
};

export default SideBarIconButton;
