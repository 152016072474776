import { type FC, useState, type ChangeEvent, useEffect } from 'react';
import { PencilIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { getNetworkError } from 'common/utils';
import { SelectInput, IconButton } from 'ui';

import { Input } from 'components/inputs';
import { type SelectInputOption } from 'core/types';

type InfoItemProps = {
  label: string;
  value: string | number;
  editable?: boolean;
  onSave?: (value: string) => Promise<void>;
  validate?: (value: string) => string;
  inputType?: 'text' | 'select';
  options?: SelectInputOption[];
};

const InfoItem: FC<InfoItemProps> = ({
  label,
  value,
  editable = false,
  onSave,
  validate,
  inputType = 'text',
  options,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [editableValue, setEditableValue] = useState('');
  const [validationError, setValidationError] = useState('');

  useEffect(() => {
    setEditableValue(String(value));
  }, [value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {
    const updatedValue = String(e.target.value);
    setEditableValue(updatedValue);

    if (!validate) return;
    setValidationError(validate(updatedValue));
  };

  const handleEditSaveAction = async (): Promise<void> => {
    if (validationError) {
      setEditMode((prevState) => !prevState);
      setEditableValue(String(value));
      setValidationError('');
      return;
    }

    try {
      if (editMode && onSave) {
        await onSave(editableValue);
      }
      setEditMode((prevState) => !prevState);
    } catch (err) {
      setValidationError(getNetworkError(err));
    }
  };

  const cancelEditMode = (): void => {
    setEditMode((prevState) => !prevState);
    setEditableValue(String(value));
    setValidationError('');
  };

  return (
    <div className={`my-2 flex w-full items-center justify-between ${!editable || 'mb-11'}`}>
      <p className="text-2xl font-semibold">{label}</p>
      {editMode ? (
        <div className="relative">
          {inputType === 'select' && options ? (
            <SelectInput
              id={label}
              value={editableValue}
              onChange={handleChange}
              options={options}
              containerClassName="w-full h-full mb-2"
              style={{ width: 300, minHeight: 44 }}
            />
          ) : (
            <Input
              id={label}
              type="text"
              containerClassName="w-full h-full !mb-2"
              inputStyle={{ width: 300, minHeight: 44 }}
              value={editableValue}
              onChange={handleChange}
              error={validationError}
            />
          )}

          {editable && (
            <div className="absolute -bottom-9 right-0 space-x-2">
              <IconButton
                Icon={<CheckIcon className="size-4" />}
                srOnly={`Save ${label}`}
                className="!p-1.5"
                onClick={() => {
                  void handleEditSaveAction();
                }}
              />

              {editMode && (
                <IconButton
                  Icon={<XMarkIcon className="size-4 stroke-2" />}
                  srOnly={`Cancel Edit ${label}`}
                  className="!p-1.5"
                  onClick={cancelEditMode}
                  color="gray"
                />
              )}
            </div>
          )}
        </div>
      ) : (
        <div
          className={`relative rounded-md border border-gray-100 bg-white-100 px-3 py-2 ${
            editable ? 'pr-10' : ''
          } flex items-center`}
          style={{ width: 300, minHeight: 44 }}
        >
          <span>{inputType === 'select' ? options?.find((option) => option.value === value)?.label : value}</span>

          {editable && (
            <IconButton
              Icon={<PencilIcon className="size-3.5" />}
              srOnly={`Edit ${label}`}
              className="absolute right-2 !p-1.5"
              onClick={() => {
                setEditMode(true);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default InfoItem;
