import axios from 'axios';

export const uploadImportFile = async (url: string, arrayBuffer: ArrayBuffer, type: string): Promise<void> => {
  await axios.put(url, arrayBuffer, {
    headers: {
      'Content-Type': type,
      Authorization: '',
    },
  });
};
