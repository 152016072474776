import { type FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  TableCellsIcon as OutlineTableCellIcon,
  UserCircleIcon as OutlineUserCircleIcon,
  EnvelopeIcon as OutlineEnvelopeIcon,
  ArrowUpTrayIcon as OutlineArrowUpTrayIcon,
} from '@heroicons/react/24/outline';
import {
  TableCellsIcon as SolidTableCellIcon,
  UserCircleIcon as SolidUserCircleIcon,
  UserPlusIcon as SolidUserPlusIcon,
  EnvelopeIcon as SolidEnvelopIcon,
  ArrowUpTrayIcon as SolidArrowUpTrayIcon,
} from '@heroicons/react/24/solid';

import { SideBarIconButton } from './components/sidebar-icon-button';

const SideBar: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="flex min-h-screen w-28 flex-col p-4 shadow-lg">
      <div className="flex-1">
        <SideBarIconButton
          Icon={<img src="logo.png" alt="Donor Spring" className="h-16 w-fit" />}
          SelectedIcon={<img src="logo.png" alt="Donor Spring" className="h-16 w-fit" />}
          selected={false}
          onClick={() => {
            navigate('/');
          }}
          text="Home"
        />

        <hr className="mb-2" />

        <SideBarIconButton
          Icon={<OutlineTableCellIcon className="size-10 text-white-100" />}
          SelectedIcon={<SolidTableCellIcon className="size-10 text-white-100" />}
          selected={location.pathname === '/reports'}
          onClick={() => {
            navigate('/reports');
          }}
          text="Reports"
        />

        <SideBarIconButton
          Icon={<SolidUserPlusIcon className="size-10 text-white-100" />}
          SelectedIcon={<SolidUserPlusIcon className="size-10 text-white-100" />}
          selected={location.pathname === '/invites'}
          onClick={() => {
            navigate('/invites');
          }}
          text="Invites"
        />

        <SideBarIconButton
          Icon={<OutlineEnvelopeIcon className="size-10 text-white-100" />}
          SelectedIcon={<SolidEnvelopIcon className="size-10 text-white-100" />}
          selected={location.pathname === '/suggested-drafts'}
          onClick={() => {
            navigate('/suggested-drafts');
          }}
          text="Suggested Drafts"
        />

        <SideBarIconButton
          Icon={<OutlineArrowUpTrayIcon className="size-10 text-white-100" />}
          SelectedIcon={<SolidArrowUpTrayIcon className="size-10 text-white-100" />}
          selected={location.pathname === '/imports'}
          onClick={() => {
            navigate('/imports');
          }}
          text="Imports"
        />
      </div>

      <hr className="mb-2" />

      <SideBarIconButton
        Icon={<OutlineUserCircleIcon className="size-10 text-white-100" />}
        SelectedIcon={<SolidUserCircleIcon className="size-10 text-white-100" />}
        selected={location.pathname === '/account'}
        onClick={() => {
          navigate('/account');
        }}
        text="Account"
      />
    </div>
  );
};

export default SideBar;
