import { useEffect } from 'react';
import toast from 'react-hot-toast';

import { ErrorToast, SuccessToast } from 'components/toasts';

type ToastType = 'error' | 'success';

const useToast = (id: string, type: ToastType, message: string, setMessage: (msg: string) => void): void => {
  useEffect(() => {
    return () => {
      setMessage('');
      toast.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (message) {
      const ToastComponent = type === 'error' ? ErrorToast : SuccessToast;

      toast.custom(
        (t) => (
          <ToastComponent
            visible={t.visible}
            message={message}
            onClose={() => {
              setMessage('');
              toast.remove(id);
            }}
          />
        ),
        { id },
      );
    }
  }, [message, setMessage, id, type]);
};

export default useToast;
