import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import axios from 'axios';
import { getNetworkError } from 'common/utils';

import { SETTINGS } from 'core/constants';
import { type CustomersActions, type CustomersState } from './types';
import { type ICustomer } from 'core/types';

const API_URL = `${SETTINGS.apiUrl}/customer-management`;

export const useCustomersStore = create<CustomersState & CustomersActions>()(
  devtools(
    persist(
      (set) => ({
        // State
        activeCustomers: [],
        customers: [],
        loading: false,
        error: '',
        success: '',
        // Actions
        getCustomers: async () => {
          set(() => ({ loading: true }));
          try {
            const response = await axios.get(API_URL);
            set(() => ({
              activeCustomers: response.data.filter((customer: ICustomer) => {
                const name = customer.name || customer.email || customer.user?.email;
                return customer.active !== false && !customer.sandbox && customer.tenantId !== 'demo' && name;
              }),
              customers: response.data,
            }));
          } catch (err) {
            set(() => ({ error: getNetworkError(err) }));
          }
          set(() => ({ loading: false }));
        },
      }),
      { name: 'customersStore' },
    ),
  ),
);
