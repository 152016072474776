import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/24/solid';
import { type Table } from '@tanstack/react-table';
import { SelectInput } from 'ui';

type CustomPaginationProps<T> = {
  table: Table<T>;
};

const CustomPagination = <T,>({ table }: CustomPaginationProps<T>): JSX.Element => {
  return (
    <div className="relative flex items-center space-x-4">
      <SelectInput
        id="table-pagination-select"
        value={table.getState().pagination.pageSize}
        onChange={(e) => {
          table.setPageSize(Number(e.target.value));
        }}
        options={[
          { label: '5', value: 5 },
          { label: '10', value: 10 },
          { label: '25', value: 25 },
        ]}
      />

      <div className="flex items-center justify-between" aria-label="Table navigation">
        <ul className="inline-flex items-center -space-x-px">
          <li>
            <button
              className="ml-0 block size-[40px] rounded-l-lg border border-gray-100 bg-white-100 px-3 py-2 leading-tight text-gray-950 hover:bg-gray-10 hover:text-gray-700"
              onClick={() => {
                table.setPageIndex(0);
              }}
              disabled={!table.getCanPreviousPage()}
            >
              <span className="sr-only">Start</span>
              <ChevronDoubleLeftIcon className="size-4 text-gray-800" aria-hidden="true" />
            </button>
          </li>
          <li>
            <button
              className="ml-0 block size-[40px] border border-gray-100 bg-white-100 px-3 py-2 leading-tight text-gray-950 hover:bg-gray-10 hover:text-gray-700"
              onClick={() => {
                table.previousPage();
              }}
              disabled={!table.getCanPreviousPage()}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="size-4 text-gray-800" aria-hidden="true" />
            </button>
          </li>
          <li>
            <button
              className="ml-0 block size-[40px] border border-gray-100 bg-white-100 px-3 py-2 leading-tight text-gray-950 hover:bg-gray-10 hover:text-gray-700"
              onClick={() => {
                table.nextPage();
              }}
              disabled={!table.getCanNextPage()}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="size-4 text-gray-800" aria-hidden="true" />
            </button>
          </li>
          <li>
            <button
              className="ml-0 block size-[40px] rounded-r-lg border border-gray-100 bg-white-100 px-3 py-2 leading-tight text-gray-950 hover:bg-gray-10 hover:text-gray-700"
              onClick={() => {
                table.setPageIndex(table.getPageCount() - 1);
              }}
              disabled={!table.getCanNextPage()}
            >
              <span className="sr-only">End</span>
              <ChevronDoubleRightIcon className="size-4 text-gray-800" aria-hidden="true" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CustomPagination;
