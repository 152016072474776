import { type ChangeEventHandler, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';
import debounce from 'lodash.debounce';
import { ChangeNameModal, Input } from 'ui';
import { DEBOUNCE_DELAY_TIME_MS } from 'common/constants';

import { useSuggestedDraftsStore } from 'stores/suggested-drafts';

type CampaignNameFormProps = {
  disabled?: boolean;
};

type FormValues = {
  name: string;
};

export const formValidation = yup.object({
  name: yup.string().required('Campaign name is required.').min(3, 'Invalid campaign name.'),
});

const CampaignNameForm = (props: CampaignNameFormProps): JSX.Element => {
  const { disabled = false } = props;
  const { name, draftId, loading, updateDraftDetails, createSuggestedDraft } = useSuggestedDraftsStore(
    useShallow((state) => ({
      draftId: state.draftDetails.draftId,
      name: state.draftDetails.name,
      loading: state.loading,
      updateDraftDetails: state.updateDraftDetails,
      createSuggestedDraft: state.createSuggestedDraft,
    })),
  );
  const [nameModalOpen, setNameModalOpen] = useState(!draftId);
  const navigate = useNavigate();
  const form = useForm<FormValues>({
    initialValues: { name },
    validate: yupResolver(formValidation),
    validateInputOnChange: true,
  });

  useEffect(() => {
    setNameModalOpen(!draftId);
  }, [draftId]);

  const debouncedUpdate = useMemo(() => debounce(updateDraftDetails, DEBOUNCE_DELAY_TIME_MS), [updateDraftDetails]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    form.setFieldValue('name', e.target.value);
    debouncedUpdate({ name: e.target.value });
  };

  const handleModalClose = (): void => {
    if (!draftId) {
      navigate(-1);
    } else {
      setNameModalOpen(false);
    }
  };

  const handleModalChange = (value: string): void => {
    form.setFieldValue('name', value);
    updateDraftDetails({ name: value });
    void createSuggestedDraft();
  };

  return (
    <>
      <div className="space-y-6 p-8">
        <h3 className="text-h4 font-semibold">Campaign Name</h3>

        <Input
          id="suggested-draft-name"
          label="Campaign Name"
          value={form.values.name}
          onChange={handleChange}
          placeholder="Campaign Name"
          required
          size="xl"
          error={!!form.errors.name}
          helperText={(form.errors.name as string) || ''}
          disabled={disabled}
        />
      </div>

      <ChangeNameModal
        title="Campaign Name"
        initialValue={name}
        open={nameModalOpen}
        onClose={handleModalClose}
        onConfirm={handleModalChange}
        loading={loading}
      />
    </>
  );
};

export default CampaignNameForm;
