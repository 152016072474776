import { useState } from 'react';
import { IconArrowRight, IconArrowLeft } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { Button, EmailTemplatesSection, IconButton, type EmailTemplate } from 'ui';

const emailTemplates: EmailTemplate[] = [
  {
    id: '1',
    title: 'Blank',
    image: 'blank',
    type: 'single',
    source: 'blank.json',
  },
  {
    id: '2',
    title: 'Letter',
    image: 'letter',
    type: 'single',
    source: 'letter.json',
  },
  {
    id: '3',
    title: 'Announcement',
    image: 'announcement',
    type: 'single',
    source: 'announcement.json',
  },
  {
    id: '4',
    title: 'Multi-Button Contribution',
    image: 'multi-button',
    type: 'single',
    source: 'multi-button-contribution.json',
  },
];

const EmailTemplates = (): JSX.Element => {
  const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplate | null>(null);
  const navigate = useNavigate();

  const handleGoBack = (): void => {
    navigate('/suggested-drafts/create', { replace: true });
  };

  const handleContinue = (): void => {
    navigate('/suggested-drafts/editor', { state: selectedTemplate });
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center space-x-1">
        <IconButton
          Icon={<IconArrowLeft size={20} />}
          color="transparent"
          className="-ml-2"
          onClick={handleGoBack}
          srOnly="Go back"
        />

        <h1 className="text-h3">Select a Template</h1>
      </div>

      <div className="relative min-h-[50px] w-full">
        <EmailTemplatesSection
          templates={emailTemplates}
          selectTemplate={(t) => {
            setSelectedTemplate(t);
          }}
          selectedTemplateId={selectedTemplate?.id}
        />
      </div>

      <div className="flex justify-end">
        <Button title="Continue" onClick={handleContinue} disabled={!selectedTemplate} RightIcon={IconArrowRight} />
      </div>
    </div>
  );
};

export default EmailTemplates;
