import { useCallback, useEffect, useMemo, useState } from 'react';
import { getNetworkError } from 'common/utils';
import { CampaignStatusLabel, Loader } from 'ui';
import { type SuggestedDraftStatus, type CampaignStatus } from 'common/types';

import { type SuggestedCampaign } from 'core/types';
import { getSentCampaigns } from 'core/api';
import { useCustomersStore } from 'stores/customers/store';
import { CAMPAIGN_SEND_STATUS, SUGGESTED_DRAFT_STATUS } from 'common/constants';

type SentCampaignsProps = {
  draftId: string;
  tenantIds: string[];
  status: SuggestedDraftStatus;
};

type CustomerCampaign = {
  id: string;
  name: string;
  sendStatus: CampaignStatus;
};

const SentCampaigns = (props: SentCampaignsProps): JSX.Element => {
  const { draftId, tenantIds, status } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [sentCampaigns, setSentCampaigns] = useState<SuggestedCampaign[]>([]);
  const customers = useCustomersStore((state) => state.customers);

  const getSuggestedCampaigns = useCallback(async () => {
    setLoading(true);
    try {
      const campaigns = await getSentCampaigns(draftId);
      setSentCampaigns(campaigns);
    } catch (err) {
      setError(getNetworkError(err));
    }
    setLoading(false);
  }, [draftId]);

  useEffect(() => {
    void getSuggestedCampaigns();
  }, [getSuggestedCampaigns]);

  const customerCampaigns: CustomerCampaign[] = useMemo(() => {
    if (tenantIds[0] === 'all') {
      // Hard deleted campaigns won't be listed in this case
      return sentCampaigns.map<CustomerCampaign>((campaign): CustomerCampaign => {
        const customer = customers.find((customer) => customer.tenantId === campaign.tenantId);
        return {
          id: campaign.campaignId,
          name: customer?.name || customer?.email || customer?.user?.email || '',
          sendStatus: campaign.sendStatus,
        };
      });
    }

    return tenantIds.reduce<CustomerCampaign[]>((campaigns, tenantId): CustomerCampaign[] => {
      const customer = customers.find((customer) => customer.tenantId === tenantId);
      const campaign = sentCampaigns.find((campaign) => campaign.tenantId === tenantId);
      campaigns.push({
        id: tenantId,
        name: customer?.name || customer?.email || customer?.user?.email || '',
        // Hard deleted campaigns won't be available but we can show the customer campaign with a 'deleted' status
        sendStatus: campaign?.deleted
          ? CAMPAIGN_SEND_STATUS.deleted
          : campaign?.sendStatus || CAMPAIGN_SEND_STATUS.deleted,
      });
      return campaigns;
    }, []);
  }, [customers, sentCampaigns, tenantIds]);

  return (
    <div className="flex space-x-3">
      <div className="w-[100px]">
        <p className="text-base text-gray-600">Customers</p>
      </div>

      <div className="relative min-w-0 flex-1 flex-col space-y-1 divide-y divide-gray-50">
        {loading ? (
          <Loader />
        ) : (
          customerCampaigns.map((customerCampaign) => (
            <div key={customerCampaign.id} className="flex h-[52px] items-center space-x-3">
              <p className="flex-1 truncate">{customerCampaign.name}</p>
              {status !== SUGGESTED_DRAFT_STATUS.scheduled && (
                <CampaignStatusLabel status={customerCampaign.sendStatus} />
              )}
            </div>
          ))
        )}

        {!!error && (
          <div className="flex h-[52px] items-center justify-center">
            <p className="text-base text-red-500">{error}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SentCampaigns;
