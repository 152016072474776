/* eslint-disable react/prop-types */
import { type FC, useMemo, useState, useCallback } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
  type ColumnDef,
} from '@tanstack/react-table';

import { type ImportJob, ImportJobStatus, IMPORT_JOB_ACTIONS } from 'core/types';
import { formatDate } from 'core/utils';
import { CustomPagination } from 'components/table';
import { ImportInfo } from '../import-info';
import { SplitButton } from 'components/button';
import { ErrorDetailsModal } from '../error-details-modal';

type TableProps = {
  data: ImportJob[];
  deleteImportJob: (createdAt: string) => Promise<void>;
  downloadImportErrors: (bucketName: string, documentKey: string, fileName: string) => Promise<void>;
};

const Table: FC<TableProps> = ({ data, deleteImportJob, downloadImportErrors }) => {
  const [importError, setImportError] = useState<string>('');

  const onSelectAction = useCallback(
    async (importJob: ImportJob, action: string) => {
      switch (action) {
        case IMPORT_JOB_ACTIONS.DETAILS: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          setImportError(importJob.errorDetails!);
          break;
        }
        case IMPORT_JOB_ACTIONS.DELETE: {
          await deleteImportJob(importJob.createdAt);
          break;
        }
        case IMPORT_JOB_ACTIONS.DOWNLOAD_IMPORT_ERRORS: {
          // Key format: 'uploads/TENANT_ID/CREATED_AT.csv'
          const [prefix, tenantId, suffix] = importJob.s3.key.split('/');
          const [suffixNoExtension] = suffix.split('.csv');
          const [filename] = importJob.filename.split('.csv');

          const documentKey = `${prefix}/${tenantId}/invalid/${suffixNoExtension}${
            importJob.listNumber ? `_${importJob.listNumber}` : ''
          }.csv`;

          const fileName = `${filename}${importJob.listNumber ? `_${importJob.listNumber}` : ''}_invalid_contacts.csv`;

          await downloadImportErrors(importJob.s3.bucket, documentKey, fileName);

          break;
        }
        default: {
          break;
        }
      }
    },
    [deleteImportJob, downloadImportErrors],
  );

  const columns = useMemo<Array<ColumnDef<ImportJob>>>(
    () => [
      {
        accessorKey: 'filename',
        header: () => 'File Name',
        footer: (props) => props.column.id,
        cell: (info) => <ImportInfo info={info.row.original} />,
      },
      {
        accessorKey: 'createdAt',
        header: () => 'Upload Date & Time',
        footer: (props) => props.column.id,
        cell: (info: any) => formatDate(info.getValue() as string),
      },
      {
        accessorKey: 'status',
        header: () => '',
        footer: (props) => props.column.id,
        cell: (info: any) => {
          const importJob = info.row.original;
          if (!importJob.invalidContacts && info.getValue() !== ImportJobStatus.FAILED) return null;
          const actions = importJob.invalidContacts ? ['Download Import Errors'] : ['Details', 'Delete'];
          return (
            <div className="flex justify-center">
              <SplitButton
                actions={actions}
                onSelectAction={(action) => {
                  void onSelectAction(importJob as ImportJob, action);
                }}
              />
            </div>
          );
        },
      },
    ],
    [onSelectAction],
  );

  const table = useReactTable({
    data,
    columns,
    defaultColumn: {
      // @ts-expect-error string values also work
      size: 'fit-content',
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const emptyRows = 5 - data.length;

  return (
    <>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full bg-white-100 text-left text-base text-gray-950 dark:text-gray-400">
          <thead className="text-sm uppercase text-gray-400 dark:bg-gray-700 dark:text-gray-400">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className="px-4 py-3"
                      style={{ width: header.column.columnDef.size, minWidth: header.column.columnDef.minSize }}
                    >
                      {header.isPlaceholder ? null : (
                        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, index) => {
              return (
                <tr
                  key={row.id}
                  className={`h-[84px] border-b border-gray-50 hover:bg-gray-10 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600`}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className="px-4 py-2"
                        style={{ width: cell.column.columnDef.size, minWidth: cell.column.columnDef.minSize }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {emptyRows > 0 &&
              Array(emptyRows)
                .fill('')
                .map((row, index) => (
                  <tr
                    key={`emptyRow-${index}`}
                    className={`h-[84px] border-b border-gray-50 hover:bg-gray-10 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600`}
                  >
                    <td className="px-4 py-2" colSpan={15} style={{ minWidth: 110 }}></td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>

      <div className="mt-4 flex items-center justify-between gap-2">
        <div>
          Showing{' '}
          <strong>
            {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1}-
            {Math.min(table.getState().pagination.pageSize * (table.getState().pagination.pageIndex + 1), data.length)}
          </strong>{' '}
          of <strong>{data.length}</strong>
        </div>

        <CustomPagination<ImportJob> table={table} />
      </div>

      <ErrorDetailsModal
        open={!!importError}
        error={importError}
        onClose={() => {
          setImportError('');
        }}
      />
    </>
  );
};

export default Table;
