import { type FC, useState, Fragment, type Dispatch, type SetStateAction } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import axios from 'axios';
import { getNetworkError } from 'common/utils';
import { Button, IconButton } from 'ui';

import { noop } from 'core/utils';
import { SETTINGS } from 'core/constants';
import { CustomerStatus, type ICustomer } from 'core/types';
import { useForm, yupResolver } from '@mantine/form';
import { Checkbox, Input } from 'components/inputs';
import { ErrorToast, SuccessToast } from 'components/toasts';

const API_URL = `${SETTINGS.apiUrl}/onboarding`;

type OnboardCustomerModalProps = {
  open: boolean;
  onClose: () => void;
  setCustomers: Dispatch<SetStateAction<ICustomer[]>>;
};

type FormValues = {
  name: string;
  email: string;
  sandbox: boolean;
};

const formValidation = yup.object({
  name: yup.string().min(2, 'Invalid name.').required('Customer name is required.'),
  email: yup.string().email('Invalid email.').required('Customer email is required'),
});

const initialValues: FormValues = {
  name: '',
  email: '',
  sandbox: false,
};

const OnboardCustomerModal: FC<OnboardCustomerModalProps> = ({ open, onClose, setCustomers }) => {
  const [loading, setLoading] = useState(false);

  const form = useForm<FormValues>({
    initialValues,
    validate: yupResolver(formValidation),
    validateInputOnChange: true,
  });

  const isFormValid = form.isValid();

  const handleClose = (): void => {
    form.reset();
    onClose();
  };

  const handleOnboard = async (values: FormValues): Promise<void> => {
    setLoading(true);
    try {
      await axios.post(`${API_URL}`, { ...values, createInvite: false, sendInviteEmail: false });

      setCustomers((prevState) => [
        ...prevState,
        {
          tenantId: '',
          signedUpAt: '',
          lastLoginAt: '',
          status: CustomerStatus.PROCESSING,
          email: values.email,
          name: values.name,
        },
      ]);
      toast.custom((t) => <SuccessToast visible={t.visible} message="Customer onboarded successfully!" />, {
        id: 'handleOnboardSuccess',
      });
      handleClose();
    } catch (err) {
      toast.custom((t) => <ErrorToast visible={t.visible} message={getNetworkError(err)} />, {
        id: 'handleOnboardError',
      });
    }
    setLoading(false);
  };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={loading ? noop : handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-950/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative w-full max-w-md overflow-hidden rounded-2xl bg-white-100 p-7 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h2" className="mt-2 text-center text-xl font-medium leading-6 text-gray-900">
                  Onboard Free Customer
                </Dialog.Title>

                <IconButton
                  Icon={<XMarkIcon className="size-5" />}
                  srOnly="Close"
                  onClick={handleClose}
                  className="absolute right-5 top-6 !p-1"
                  color="transparent"
                  disabled={loading}
                />

                <div className="relative mt-8">
                  {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                  <form noValidate onSubmit={form.onSubmit(handleOnboard)}>
                    <Input
                      id="name"
                      label="Customer Name"
                      placeholder="Customer Name"
                      disabled={loading}
                      value={form.values.name}
                      onChange={(e) => {
                        form.setFieldValue('name', e.target.value);
                      }}
                      error={form.errors.name as string} // Required
                    />

                    <Input
                      id="email"
                      label="Customer Email"
                      placeholder="Customer Email"
                      disabled={loading}
                      value={form.values.email}
                      onChange={(e) => {
                        form.setFieldValue('email', e.target.value);
                      }}
                      error={form.errors.email as string} // Required
                      autoComplete="off"
                    />

                    <Checkbox
                      id="sandbox"
                      label="Sandbox Mode"
                      disabled={loading}
                      {...form.getInputProps('sandbox', { type: 'checkbox' })}
                    />

                    <div className="mt-8 flex w-full items-center justify-between">
                      <Button
                        title="Confirm"
                        type="submit"
                        color="primary"
                        disabled={loading || !isFormValid}
                        loading={loading}
                      />

                      <Button title="Cancel" onClick={handleClose} disabled={loading} />
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default OnboardCustomerModal;
