import { type FC } from 'react';

type SmallTableProps = {
  data: Array<{ label: string; value: any }>;
};

const SmallTable: FC<SmallTableProps> = ({ data }) => {
  return (
    <div className="flex h-full flex-col">
      <div className="h-full overflow-hidden sm:-mx-6 lg:-mx-8">
        <div className="inline-block h-full min-w-full py-2 sm:px-6 lg:px-8">
          <div className="h-full overflow-hidden shadow-md sm:rounded-lg">
            <table className="min-w-full bg-white-100">
              <tbody>
                {data.map((item, index) => (
                  <tr
                    key={`${item.label}-${index}`}
                    className={`bg-white-100 dark:bg-gray-800 ${
                      index + 1 === data.length ? '' : 'border-b border-gray-50 dark:border-gray-700'
                    }`}
                  >
                    <td className="whitespace-nowrap px-6 py-4 text-base font-medium text-gray-900 dark:text-white-100">
                      {item.label}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-base text-gray-700 dark:text-gray-400">
                      {item.value}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmallTable;
