import { type ChangeEvent, type CSSProperties, type FC } from 'react';

type InputProps = {
  id: string;
  label?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  containerClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
  disabled?: boolean;
  inputStyle?: CSSProperties;
};

const Checkbox: FC<InputProps> = ({
  id,
  label,
  value,
  onChange,
  checked,
  containerClassName = '',
  labelClassName = '',
  inputClassName = '',
  disabled = false,
  inputStyle = {},
}) => (
  <div className={`mb-6 flex justify-between ${containerClassName}`}>
    {label && (
      <label
        htmlFor={id}
        className={`
          mb-2
          block
          text-base
          font-medium
          text-gray-900 
          ${labelClassName}
        `}
      >
        {label}
      </label>
    )}
    <input
      type="checkbox"
      id={id}
      className={`
        mt-[2px]
        size-4
        min-w-[16px]
        cursor-auto
        rounded
        border
        bg-white-100
        text-primary-700
        focus:outline-none
        focus:ring-2
        focus:ring-primary-300
        focus:ring-offset-1
        disabled:cursor-not-allowed
        disabled:bg-gray-10
        ${inputClassName}
      `}
      value={value}
      onChange={onChange}
      disabled={disabled}
      style={inputStyle}
      checked={checked}
    />
  </div>
);

export default Checkbox;
