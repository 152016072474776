import { type NavigateFunction } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import qs from 'qs';

import { AUTH_SESSION_KEY, SETTINGS } from '../constants';

export const hasValidSession = (): boolean => {
  const authToken = sessionStorage.getItem(AUTH_SESSION_KEY);

  if (authToken) {
    const { exp } = jwt_decode<{ exp: number }>(authToken);

    if (exp * 1000 > Date.now()) {
      return true;
    } else {
      sessionStorage.clear();
    }
  }

  return false;
};

export const authenticate = async (navigate: NavigateFunction): Promise<boolean> => {
  try {
    const isAuthenticated = hasValidSession();

    if (isAuthenticated) {
      navigate('/');
      return true;
    }

    const queryString = window.location.search;

    if (!queryString || !queryString.toLowerCase().includes('code')) {
      // if code not in url, route to log in page
      window.location.replace(
        `${SETTINGS.authUrl}/login?client_id=${SETTINGS.appClientId}&response_type=code&scope=email+openid+phone+profile&redirect_uri=${SETTINGS.redirectUrl}`,
      );
    }

    const code = queryString.split('?code=')[1];
    const data = qs.stringify({
      grant_type: 'authorization_code',
      code,
      client_id: SETTINGS.appClientId,
      redirect_uri: SETTINGS.redirectUrl,
    });

    const result = await axios.post(`${SETTINGS.authUrl}/oauth2/token`, data, {
      headers: {
        'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (!result.data.access_token) return false;

    sessionStorage.setItem(AUTH_SESSION_KEY, result.data.access_token as string);
    navigate('/');

    return true;
  } catch (error) {
    return false;
  }
};

export const signOut = (): void => {
  sessionStorage.clear();
  window.location.replace('https://donorspring.com');
};
