import { type FC, type ReactElement, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { hasValidSession, authenticate } from 'core/utils';
import { Loader } from 'components/loader';
import Layout from './Layout';

type ProtectedRouteProps = {
  children?: ReactElement<any, any> | null;
};

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => hasValidSession());
  const navigate = useNavigate();

  const initAuthentication = useCallback(async () => {
    const isAuth = await authenticate(navigate);
    setIsAuthenticated(isAuth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated) return;
    void initAuthentication();
  }, [initAuthentication, isAuthenticated]);

  if (!isAuthenticated) {
    return (
      <div className="relative flex min-h-screen">
        <Loader />
      </div>
    );
  }

  if (children) {
    return children;
  }

  return <Layout />;
};

export default ProtectedRoute;
