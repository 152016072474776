import { type FC } from 'react';

const Header: FC = () => {
  return (
    <header className="flex items-center justify-center border-b border-gray-50 px-5 py-4 drop-shadow-sm">
      <h1 className="-ml-28 text-2xl">Backend Customer Management</h1>
    </header>
  );
};

export default Header;
