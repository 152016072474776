import { type TenantImportStatus } from '../../types';

type StatusLabelProps = {
  status: TenantImportStatus;
  containerClassName?: string;
};

const getContainerColorClassNames = (status: TenantImportStatus): string => {
  switch (status) {
    case 'scheduled':
      return 'border-orange-200 bg-orange-50 text-orange-700';
    case 'error':
      return 'border-red-100 bg-red-50 text-red-500';
    default:
      // pending
      return 'border-gray-100 bg-gray-10 text-gray-500';
  }
};

const StatusLabel = (props: StatusLabelProps): JSX.Element => {
  const { status, containerClassName = '' } = props;

  return (
    <div className={`w-fit rounded border px-1.5 py-0.5 ${getContainerColorClassNames(status)} ${containerClassName}`}>
      <p className="truncate text-caption1 font-semibold uppercase">{status}</p>
    </div>
  );
};

export default StatusLabel;
