import { type FC, useCallback, useState } from 'react';
import Dropzone, { type FileRejection, type Accept } from 'react-dropzone';
import { CloudArrowUpIcon } from '@heroicons/react/24/solid';

import { Loader } from 'components/loader';

// 10MB -> in bytes
const maxFileSize = 10 * 10 ** 6;

const allowedFileTypes: Accept = { 'text/csv': ['.csv'] };

type FileDropProps = {
  loading: boolean;
  onChange: (file: File) => void;
};

const FileDrop: FC<FileDropProps> = ({ loading, onChange }) => {
  const [error, setError] = useState<string | boolean>(false);
  const [file, setFile] = useState<File | null>(null);

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      setError(false);
      setFile(acceptedFiles[0] || fileRejections[0] || null);
      const acceptedFile = acceptedFiles[0];
      if (
        fileRejections.length > 0 ||
        !/^donorspringupload_.*$/i.test(acceptedFile?.name ?? '') ||
        !acceptedFile?.name.endsWith('.csv')
      ) {
        setError('Invalid file.');
        return;
      }
      onChange(acceptedFile);
    },
    [onChange],
  );

  return (
    <Dropzone
      onDrop={(acceptedFiles, fileRejections) => {
        void onDrop(acceptedFiles, fileRejections);
      }}
      maxSize={maxFileSize}
      accept={allowedFileTypes}
      disabled={loading}
      multiple={false}
    >
      {({ getRootProps, getInputProps }) => (
        <section className="relative py-5" style={{ width: 400, height: 200 }}>
          <div className="flex size-full flex-col" style={{ opacity: loading ? 0.5 : 1 }}>
            <div
              {...getRootProps()}
              className={`border-2
              ${error ? 'border-red-500' : 'border-gray-100'}
              flex
              flex-1
              cursor-pointer
              flex-col
              items-center
              justify-center
              rounded-lg
              border-dashed
              p-10`}
            >
              <div className="flex w-full flex-1 flex-col items-center justify-center">
                <input {...getInputProps()} id="dropDocumentInput" />

                <CloudArrowUpIcon className="size-10 text-gray-500" />
                <p className="mt-1 text-center text-base font-semibold">{file ? file.name : 'Browse'}</p>
              </div>
            </div>

            {error && <p className="mt-1 text-sm italic text-red-500">{error}</p>}
          </div>

          {loading && <Loader style={{ top: '50%', left: 2.5, marginTop: -20 }} />}
        </section>
      )}
    </Dropzone>
  );
};

export default FileDrop;
