import { type ChangeEvent, type FC } from 'react';

type RadioInputProps = {
  id: string;
  label: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  disabled?: boolean;
  containerClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
  name?: string;
};

const RadioInput: FC<RadioInputProps> = ({
  id,
  label,
  value,
  onChange,
  checked,
  disabled,
  containerClassName = '',
  labelClassName = '',
  inputClassName = '',
  name,
}) => {
  return (
    <div className={`mb-4 flex justify-between ${containerClassName}`}>
      <label
        htmlFor={id}
        className={`text-base 
          font-medium 
          text-gray-900 
          dark:text-gray-300 
          ${labelClassName}`}
      >
        {label}
      </label>
      <input
        id={id}
        name={name || label}
        type="radio"
        value={value}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        className={`
          size-4
          min-w-[16px]
          cursor-auto
          border 
          border-gray-200 
          bg-white-100
          text-primary-700
          focus:outline-none
          focus:ring-2
          focus:ring-primary-300
          focus:ring-offset-1
          disabled:cursor-not-allowed
          disabled:bg-gray-10
          ${inputClassName}
        `}
      />
    </div>
  );
};

export default RadioInput;
