import { parse } from 'papaparse';

export const parseCsvFile = async (file: File): Promise<any[]> => {
  return await new Promise((resolve, reject) => {
    parse(file, {
      complete: (results) => {
        resolve(results.data);
      },
      error: (error) => {
        reject(error);
      },
    });
  });
};
