import { type FC } from 'react';

type ChipProps = {
  label: string;
  onRemove: () => void;
};

const Chip: FC<ChipProps> = ({ label, onRemove }) => {
  return (
    <span className="me-2 inline-flex min-w-max items-center space-x-1 rounded-xl bg-white-100 px-2 py-1 text-base font-medium text-gray-950">
      <span>{label}</span>
      <button
        type="button"
        className="ms-2 inline-flex items-center rounded-sm bg-transparent p-1 text-base text-gray-400 hover:bg-gray-200 hover:text-gray-900"
        aria-label="Remove"
        onClick={onRemove}
      >
        <svg
          className="size-[10px]"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
        <span className="sr-only">Remove {label}</span>
      </button>
    </span>
  );
};

export default Chip;
