import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import {
  Home,
  Customer,
  Account,
  Reports,
  Invites,
  SuggestedDrafts,
  CreateSuggestedDraft,
  EmailTemplates,
  EmailEditor,
  TemplatePreview,
  Imports,
} from 'pages';
import ProtectedRoute from './ProtectedRoute';

const AppRouter = (): JSX.Element => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ProtectedRoute />}>
          <Route index element={<Home />} />
          <Route path="customer/:tenantId" element={<Customer />} />
          <Route path="account" element={<Account />} />
          <Route path="reports" element={<Reports />} />
          <Route path="invites" element={<Invites />} />
          <Route path="imports" element={<Imports />} />
          <Route path="suggested-drafts">
            <Route index element={<SuggestedDrafts />} />
            <Route path=":selectedDraftId" element={<SuggestedDrafts />} />
            <Route path="create" element={<CreateSuggestedDraft />} />
            <Route path="edit" element={<CreateSuggestedDraft />} />
            <Route path="templates" element={<EmailTemplates />} />
            <Route path="editor" element={<EmailEditor />} />
          </Route>
        </Route>
        <Route
          path="/template-preview"
          element={
            <ProtectedRoute>
              <TemplatePreview />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
