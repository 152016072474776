import { useState, useCallback } from 'react';
import Dropzone, { type FileRejection, type Accept } from 'react-dropzone';
import { IconUpload } from '@tabler/icons-react';
import { Loader } from 'ui';

type MultipleFileDropProps = {
  onChange: (accepted: File[]) => Promise<void>;
  allowedFileTypes?: Accept;
  acceptedFileExtension?: string;
  filenameRegex?: RegExp;
  filenameErrorMessage?: string;
  loading?: boolean;
  containerClassName?: string;
  disabled?: boolean;
  error?: boolean;
  maxFileSize?: number;
};

type RejectedFile = {
  filename: string;
  errorMessage: string;
};

const errorCodeLabels: Record<string, string> = {
  'file-too-large': 'File is too large.',
  'file-invalid-type': 'File type must be text/csv.',
};

const MultipleFileDrop = (props: MultipleFileDropProps): JSX.Element => {
  const {
    onChange,
    allowedFileTypes = { 'text/csv': ['.csv'], 'application/vnd.ms-excel': [] },
    filenameRegex,
    filenameErrorMessage,
    acceptedFileExtension,
    loading,
    containerClassName,
    disabled,
    error,
    maxFileSize,
  } = props;
  const [rejectedFiles, setRejectedFiles] = useState<RejectedFile[]>([]);

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      const { accepted, rejected } = acceptedFiles.reduce(
        (files, file) => {
          if (filenameRegex && !filenameRegex.test(file.name)) {
            files.rejected.push({
              filename: file.name,
              errorMessage: filenameErrorMessage ?? 'File name format is invalid.',
            });
          } else if (acceptedFileExtension && !file.name.endsWith(acceptedFileExtension)) {
            files.rejected.push({
              filename: file.name,
              errorMessage: errorCodeLabels['file-invalid-type'],
            });
          } else {
            files.accepted.push(file);
          }
          return files;
        },
        { accepted: [] as File[], rejected: [] as RejectedFile[] },
      );

      const rejections: RejectedFile[] = fileRejections
        .map((rejection) => ({
          filename: rejection.file.name,
          errorMessage: errorCodeLabels[rejection.errors[0].code],
        }))
        .concat(rejected);

      setRejectedFiles(rejections);

      await onChange(accepted);
    },
    [acceptedFileExtension, filenameErrorMessage, filenameRegex, onChange],
  );

  return (
    <div className="space-y-2">
      <Dropzone
        onDrop={(acceptedFiles, fileRejections) => {
          void onDrop(acceptedFiles, fileRejections);
        }}
        maxSize={maxFileSize}
        accept={allowedFileTypes}
        disabled={loading || disabled}
        multiple
      >
        {({ getRootProps, getInputProps }) => (
          <section className={`relative mb-4 h-[200px] w-[400px] ${containerClassName}`}>
            <div className="flex size-full flex-col" style={{ opacity: loading ? 0.5 : 1 }}>
              <div
                {...getRootProps()}
                className={`
              border
              ${error ? 'border-red-500' : 'border-gray-100'}
              flex
              size-full
              flex-col
              items-center
              justify-center
              rounded-lg
              border-dashed
              bg-white-100
              ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}
              `}
              >
                <div className="flex w-full flex-1 flex-col items-center justify-center">
                  <input {...getInputProps()} id="dropDocumentInput" />

                  <IconUpload size={18} className="text-gray-800" />

                  <p className="mt-3 text-center text-base">
                    Drag and drop your file(s) here or <span className="text-primary-700">browse</span>
                  </p>
                </div>
              </div>
            </div>
            {loading && <Loader style={{ top: '50%', left: 2.5, marginTop: -20 }} />}
          </section>
        )}
      </Dropzone>

      {rejectedFiles.map((file) => (
        <div key={file.filename} className="text-sm">
          <p className="font-medium">{file.filename}:</p>
          <p className="font-medium text-red-500">{file.errorMessage}</p>
        </div>
      ))}
    </div>
  );
};

export default MultipleFileDrop;
