/* eslint-disable react/prop-types */
import { useCallback, useMemo } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
  type ColumnDef,
} from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { IconEye, IconPencil, IconMailCancel } from '@tabler/icons-react';
import { TablePagination, TableRowActions, SuggestedDraftStatusLabel } from 'ui';
import { type SuggestedDraft, type SuggestedDraftStatus } from 'common/types';

import { SUGGESTED_DRAFT_ACTIONS } from 'core/constants';
import { useSuggestedDraftsStore } from 'stores/suggested-drafts';
import CustomerNames from './CustomerNames';

type TableProps = {
  data: SuggestedDraft[];
  cancelScheduledDraft: (draftId: string) => Promise<void>;
};

const getActionOptions = (status: SuggestedDraftStatus): string[] => {
  switch (status) {
    case 'draft':
      return [SUGGESTED_DRAFT_ACTIONS.edit];
    case 'scheduled':
      return [SUGGESTED_DRAFT_ACTIONS.view, SUGGESTED_DRAFT_ACTIONS.cancel];
    case 'sent':
      return [SUGGESTED_DRAFT_ACTIONS.view];
    default:
      return [];
  }
};

const getActionIcon = (action: string): JSX.Element | null => {
  switch (action) {
    case SUGGESTED_DRAFT_ACTIONS.edit:
      return <IconPencil size={20} />;
    case SUGGESTED_DRAFT_ACTIONS.view:
      return <IconEye size={20} />;
    case SUGGESTED_DRAFT_ACTIONS.cancel:
      return <IconMailCancel size={20} />;
    default:
      return null;
  }
};

const Table = (props: TableProps): JSX.Element => {
  const { data, cancelScheduledDraft } = props;
  const { setDraftDetails, resetState } = useSuggestedDraftsStore((state) => ({
    setDraftDetails: state.setDraftDetails,
    resetState: state.resetState,
  }));
  const navigate = useNavigate();

  const handleSelectedAction = useCallback(
    (action: string, draft: SuggestedDraft) => {
      switch (action) {
        case SUGGESTED_DRAFT_ACTIONS.edit: {
          const { createdAt, updatedAt, status, acceptedCount, customersCount, ...rest } = draft;
          resetState();
          setDraftDetails({ ...rest, scheduleSend: !!rest.customerSendTime });
          navigate('edit');
          break;
        }
        case SUGGESTED_DRAFT_ACTIONS.view: {
          navigate(`/suggested-drafts/${draft.draftId}`);
          break;
        }
        case SUGGESTED_DRAFT_ACTIONS.cancel: {
          void cancelScheduledDraft(draft.draftId);
          break;
        }
        default: {
          break;
        }
      }
    },
    [cancelScheduledDraft, navigate, resetState, setDraftDetails],
  );

  const columns: Array<ColumnDef<SuggestedDraft>> = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: () => 'Name',
        footer: (props) => props.column.id,
        cell: (info) => (
          <p className="max-w-[420px] overflow-hidden text-ellipsis text-nowrap">{info.getValue() as string}</p>
        ),
        minSize: 420,
      },
      {
        accessorKey: 'tenantIds',
        header: () => 'Customers',
        footer: (props) => props.column.id,
        cell: (info) => {
          const tenantIds = info.getValue() as string[];
          const excludedTenantIds = info.row.original.excludedTenantIds ?? [];
          return <CustomerNames tenantIds={tenantIds} excludedTenantIds={excludedTenantIds} />;
        },
        minSize: 220,
      },
      {
        accessorKey: 'updatedAt',
        header: () => 'Last Edited',
        footer: (props) => props.column.id,
        cell: (info) => format(new Date((info.getValue() as number) * 1000), 'd MMM yyyy, h:mm a'),
        minSize: 120,
      },
      {
        accessorKey: 'status',
        header: () => 'Stage',
        footer: (props) => props.column.id,
        cell: (info) => <SuggestedDraftStatusLabel status={info.getValue() as SuggestedDraftStatus} />,
        minSize: 100,
      },
      {
        accessorKey: 'customerSendTime',
        header: () => 'Draft Send Time',
        footer: (props) => props.column.id,
        cell: (info) => {
          const customerSendTime = info.getValue() as string;
          return customerSendTime ? format(new Date(customerSendTime), 'd MMM yyyy, h:mm a') : '';
        },
        minSize: 120,
      },
      {
        accessorKey: 'acceptedCount',
        header: () => 'Accept.',
        footer: (props) => props.column.id,
        cell: (info) => {
          if (info.row.original.status !== 'sent') {
            return null;
          }
          return (
            <p>
              {info.getValue() as number} / {info.row.original.customersCount}
            </p>
          );
        },
        minSize: 80,
      },
      {
        accessorKey: 'sendTime',
        header: () => 'Campaign Send Time',
        footer: (props) => props.column.id,
        cell: (info) => {
          const sendTime = info.getValue() as string;
          return sendTime ? format(new Date(sendTime), 'd MMM yyyy, h:mm a') : '';
        },
        minSize: 120,
      },
      {
        accessorKey: 'draftId',
        header: () => '',
        footer: (props) => props.column.id,
        cell: (info) => (
          <TableRowActions
            actions={getActionOptions(info.row.original.status)}
            onSelectAction={(action) => {
              handleSelectedAction(action, info.row.original);
            }}
            getActionIcon={getActionIcon}
          />
        ),
        minSize: 60,
      },
    ],
    [handleSelectedAction],
  );

  const table = useReactTable({
    data,
    columns,
    defaultColumn: {
      // @ts-expect-error string values also work
      size: 'fit-content',
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const emptyRows = 5 - data.length;

  return (
    <div className="space-y-6">
      <div className="relative overflow-x-auto sm:rounded-lg">
        <table className="w-full text-left text-base text-gray-950 dark:text-gray-400">
          <thead className="bg-white-100 text-sm uppercase text-gray-400 dark:bg-gray-700 dark:text-gray-400">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className="px-4 py-3"
                      style={{ width: header.column.columnDef.size, minWidth: header.column.columnDef.minSize }}
                    >
                      {header.isPlaceholder ? null : (
                        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  className="h-[52px] border-b border-gray-50 bg-white-100 hover:bg-gray-10 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className="px-4 py-2"
                        style={{ width: cell.column.columnDef.size, minWidth: cell.column.columnDef.minSize }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {emptyRows > 0 &&
              Array(emptyRows)
                .fill('')
                .map((row, index) => (
                  <tr
                    key={`emptyRow-${index}`}
                    className="h-[52px] border-b border-gray-50 bg-white-100 hover:bg-gray-10 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                  >
                    <td className="px-4 py-2" colSpan={15} style={{ minWidth: 110 }}></td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>

      <TablePagination table={table} total={data.length} />
    </div>
  );
};

export default Table;
