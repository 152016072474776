import { DONATION_PAGE_DOMAINS } from 'common/constants';

import { type EnvType } from '../types';

// @ts-expect-error - env is defined
export const ENV = (import.meta.env.VITE_ENV ?? 'dev') as EnvType;

const AUTH_URLS = {
  prod: 'https://auth.donorspring.com',
  qa: 'https://auth-qa.donorspring.com',
  dev: 'https://auth-qa.donorspring.com',
};

const REDIRECT_URLS = {
  prod: 'https://backend.donorspring.com/',
  qa: 'https://backend-qa.donorspring.com/',
  dev: 'http://localhost:3000/',
};

const API_URLS = {
  prod: 'https://api.donorspring.com',
  qa: 'https://api-qa.donorspring.com',
  dev: 'https://api-qa.donorspring.com',
};

const APP_CLIENT_IDS = {
  prod: '6gf4cout5lql4kcl0dq63r3bic',
  qa: '3uhvv77qldats3d5c202ne76uk',
  dev: '3uhvv77qldats3d5c202ne76uk',
};

const CUSTOMER_APP_URLS = {
  prod: 'https://app.donorspring.com',
  qa: 'https://app-qa.donorspring.com',
  dev: 'https://app-qa.donorspring.com',
};

export const SETTINGS = {
  authUrl: AUTH_URLS[ENV],
  redirectUrl: REDIRECT_URLS[ENV],
  apiUrl: API_URLS[ENV],
  appClientId: APP_CLIENT_IDS[ENV],
  customerAppUrl: CUSTOMER_APP_URLS[ENV],
  donationPageDomain: DONATION_PAGE_DOMAINS[ENV],
};
