import axios from 'axios';

import { SETTINGS } from 'core/constants';

const API_URL = `${SETTINGS.apiUrl}/customer-management/suggested-drafts`;

export const createAIContent = async (prompt: string): Promise<string> => {
  const result = await axios.post<string>(`${API_URL}/content`, { prompt });
  return result.data;
};

export const sendTestEmail = async (data: {
  to: string;
  html: string;
  campaignId: string;
  tenantId?: string;
  subject?: string;
  previewText?: string;
}): Promise<void> => {
  await axios.post(`${API_URL}/test`, data);
};
