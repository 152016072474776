import axios, { type AxiosResponse } from 'axios';
import { type SuggestedDraft, type EmailTemplateHTML, type EmailTemplateReturnItem } from 'common/types';
import { SETTINGS } from 'core/constants';
import { type SuggestedDraftData, type SuggestedCampaign } from 'core/types';

const API_URL = `${SETTINGS.apiUrl}/customer-management/suggested-drafts`;

export const getTemplate = async (templateId: string, version: string): Promise<EmailTemplateHTML> => {
  const templateResponse = await axios.get<EmailTemplateReturnItem>(`${API_URL}/templates/${templateId}/${version}`);
  const template = templateResponse.data;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const htmlResponse = await axios.get(template.downloadUrl!, {
    headers: { Authorization: '' },
  });
  const html = htmlResponse.data;
  return { ...template, html };
};

export const getSentCampaigns = async (draftId: string): Promise<SuggestedCampaign[]> => {
  const response = await axios.get<SuggestedCampaign[]>(`${API_URL}/${draftId}/campaigns`);
  return response.data;
};

export const createSuggestedDraft = async (data: SuggestedDraftData): Promise<void> => {
  await axios.post<SuggestedDraft>(API_URL, data);
};

export const updateSuggestedDraft = async (data: SuggestedDraftData): Promise<void> => {
  await axios.put<SuggestedDraft>(API_URL, data);
};

export const sendSuggestedDraft = async (data: SuggestedDraftData): Promise<void> => {
  await axios.post<SuggestedDraft>(`${API_URL}/send`, data);
};

export const createSuggestedDraftTemplate = async (data: {
  design: string;
  tenantIds: string[];
}): Promise<AxiosResponse<EmailTemplateReturnItem>> => {
  const response = await axios.post<EmailTemplateReturnItem>(`${API_URL}/templates`, data);
  return response;
};

export const uploadTemplate = async (uploadUrl: string, html: string): Promise<void> => {
  await axios.put(uploadUrl, html, { headers: { 'Content-Type': 'text/html', Authorization: '' } });
};

export const updateSuggestedDraftTemplate = async (data: {
  templateId: string;
  version: string;
  design: string;
  tenantIds: string[];
}): Promise<AxiosResponse<EmailTemplateReturnItem>> => {
  const response = await axios.put<EmailTemplateReturnItem>(`${API_URL}/templates`, data);
  return response;
};

export const cancelScheduledSuggestedDraft = async (draftId: string): Promise<void> => {
  await axios.patch(`${API_URL}/${draftId}/cancel`);
};
