import { type ChangeEvent, type CSSProperties, type FC } from 'react';

type InputProps = {
  id: string;
  label?: string;
  type?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  containerClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
  disabled?: boolean;
  error?: string;
  rows?: number;
  autoComplete?: string;
  inputStyle?: CSSProperties;
};

const Input: FC<InputProps> = ({
  id,
  label,
  type = 'text',
  placeholder = '',
  value,
  onChange,
  containerClassName = '',
  labelClassName = '',
  inputClassName = '',
  disabled = false,
  error,
  rows = 4,
  autoComplete,
  inputStyle = {},
}) => {
  if (type === 'textarea') {
    return (
      <div className={`mb-6 ${containerClassName}`}>
        {label && (
          <label
            htmlFor={id}
            className={`mb-2
          block
          text-base
          font-medium
          text-gray-900
          dark:text-gray-400
          ${labelClassName}`}
          >
            {label}
          </label>
        )}
        <textarea
          id={id}
          rows={rows}
          className={`block
          w-full
          rounded-lg
          border
          border-gray-100
          bg-white-100
          p-2.5
          text-base
          text-gray-900
          focus:border-primary-500
          focus:ring-primary-500
          dark:border-gray-600
          dark:bg-gray-700
          dark:text-white-100
          dark:placeholder:text-gray-400
          dark:focus:border-primary-500
          dark:focus:ring-primary-500
          ${inputClassName}
          ${error ? 'border-red-500' : ''}`}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
          style={inputStyle}
        ></textarea>
        {!!error && <p className="mt-1 text-sm italic text-red-500">{error}</p>}
      </div>
    );
  }

  return (
    <div className={`mb-6 ${containerClassName}`}>
      {label && (
        <label
          htmlFor={id}
          className={`mb-2
        block
        text-base
        font-medium
        text-gray-900
        dark:text-gray-300
        ${labelClassName}`}
        >
          {label}
        </label>
      )}
      <input
        type={type}
        id={id}
        className={`block w-full
        rounded-lg
        border
        border-gray-100
        bg-white-100
        p-2.5
        text-base
        text-gray-900
        focus:border-primary-500
        focus:ring-primary-500
        dark:border-gray-600
        dark:bg-gray-700
        dark:text-white-100
        dark:placeholder:text-gray-400
        dark:focus:border-primary-500
        dark:focus:ring-primary-500
        ${inputClassName}
        ${error ? 'border-red-500' : ''}`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        autoComplete={autoComplete}
        style={inputStyle}
      />
      {!!error && <p className="mt-1 text-sm italic text-red-500">{error}</p>}
    </div>
  );
};

export default Input;
