import { type FC } from 'react';
import { Toaster } from 'react-hot-toast';

import AppRouter from 'router/AppRouter';

import 'core/interceptors';

const App: FC = () => {
  return (
    <>
      <AppRouter />
      <Toaster position="bottom-right" />
    </>
  );
};

export default App;
