import { IconTrash } from '@tabler/icons-react';
import { IconButton, Loader } from 'ui';
import { formatDate } from 'common/utils';

import { CounterInput } from 'components/inputs';
import { type TenantImport } from '../../types';
import StatusLabel from './StatusLabel';

type TableProps = {
  data: TenantImport[];
  onDelete: (tenantId: string) => void;
  onUpdateListsNumber: (tenantId: string, listsNumber: number) => void;
};

const Table = (props: TableProps): JSX.Element => {
  const { data, onDelete, onUpdateListsNumber } = props;

  return (
    <div className="overflow-x-auto rounded-lg">
      <table className="w-full bg-white-100 text-left text-base text-gray-950 dark:text-gray-400">
        <thead className="text-sm uppercase text-gray-400 dark:bg-gray-700 dark:text-gray-400">
          <tr className="bg-white-100 [&>th:not(:last-child)]:min-w-[150px]">
            <th className="px-4 py-2">Customer Name</th>
            <th className="px-4 py-2">Last Scheduled Upload</th>
            <th className="px-4 py-2">Total Contacts</th>
            <th className="!min-w-[250px] px-4 py-2">Import Lists</th>
            <th className="px-4 py-2">Status</th>
            <th className="sticky right-0 bg-inherit px-4 py-2"></th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => {
            const lastJobAt =
              item.lastScheduledJob?.importAt || item.lastImportedJob?.importAt || item.lastImportedJob?.createdAt;
            return (
              <tr
                key={item.tenantId}
                className="h-[52px] border-b border-gray-50 bg-white-100 [&>td:not(:last-child)]:min-w-[150px]"
              >
                <td className="px-4 py-2">
                  <p>{item.name}</p>
                  <p className="text-sm text-gray-600">{item.importFile.name}</p>
                  <p className="text-sm font-semibold text-gray-600">{item.subscriptionPlan}</p>
                </td>
                <td className="px-4 py-2">
                  <p>{item.lastScheduledJob?.filename || item.lastImportedJob?.filename}</p>
                  <p className="text-sm text-gray-600">{lastJobAt ? formatDate(lastJobAt) : ''}</p>
                  <p className="text-sm font-medium text-gray-600 ">
                    Total contacts: {item.lastScheduledJob?.totalContacts || item.lastImportedJob?.totalContacts}
                  </p>
                </td>
                <td className="px-4 py-2">{item.totalContacts}</td>
                <td className="!min-w-[250px] space-y-2 px-4 py-2">
                  <ol className="list-decimal pl-4">
                    {item.importLists.map((list) => (
                      <li key={list.tempId}>
                        <p>{list.contacts} contacts</p>
                        <p className="text-sm text-gray-600">{formatDate(list.importAt.toISOString())}</p>
                      </li>
                    ))}
                  </ol>
                  <CounterInput
                    id={`${item.tenantId}-lists-number`}
                    containerClassName="mb-0 mr-2"
                    value={item.importLists.length}
                    onChange={(value) => {
                      const listsNumber = Math.max(Math.min(value, item.totalContacts), 1);
                      onUpdateListsNumber(item.tenantId, listsNumber);
                    }}
                    size="sm"
                    disabled={item.loading || item.status !== 'pending'}
                  />
                </td>
                <td className="relative px-4 py-2">
                  {item.loading ? <Loader /> : <StatusLabel status={item.status} />}
                </td>
                <td className="sticky right-0 bg-inherit px-4 py-2">
                  <IconButton
                    Icon={<IconTrash />}
                    srOnly="Delete"
                    color="transparent"
                    onClick={() => {
                      onDelete(item.tenantId);
                    }}
                    disabled={item.loading || item.status !== 'pending'}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
