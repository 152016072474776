import axios from 'axios';

import { SETTINGS } from 'core/constants';
import { type ImportList, type ImportJob } from 'core/types';

const API_URL = `${SETTINGS.apiUrl}/customer-management`;

export const getTenantImports = async (tenantId: string): Promise<ImportJob[]> => {
  const response = await axios.get<ImportJob[]>(`${API_URL}/imports/${tenantId}`);
  return response.data;
};

export const createPresignedUploadUrl = async ({
  tenantId,
  filename,
  importLists,
}: {
  tenantId: string;
  filename: string;
  importLists: ImportList[];
}): Promise<string> => {
  const response = await axios.post(`${API_URL}/import`, { tenant_id: tenantId, filename, import_lists: importLists });
  return response.data.url;
};
