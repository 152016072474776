import { type FC, useState, Fragment, useRef } from 'react';
import { Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useOnClickOutside } from 'usehooks-ts';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Button, IconButton } from 'ui';

import { IMPORT_JOB_ACTIONS } from 'core/types';

const getActionIcon = (action: string): JSX.Element | null => {
  if (action === IMPORT_JOB_ACTIONS.DELETE) {
    return <TrashIcon className="mr-2 size-4" />;
  }
  return null;
};

type SplitButtonProps = {
  actions: string[];
  onSelectAction: (action: string) => void;
};

const SplitButton: FC<SplitButtonProps> = ({ actions, onSelectAction }) => {
  const [showOptions, setShowOptions] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(ref, () => {
    setShowOptions(false);
  });

  const [firstAction, ...menuActions] = actions;

  const handleClick = (action: string): void => {
    setShowOptions(false);
    onSelectAction(action);
  };

  if (actions.length === 1) {
    return (
      <Button
        title={firstAction}
        color="primary"
        onClick={() => {
          handleClick(firstAction);
        }}
      />
    );
  }

  return (
    <div className="relative w-fit" ref={ref}>
      <div className="flex rounded-lg bg-primary-700">
        <Button
          title={firstAction}
          color="primary"
          className="!rounded-none !rounded-l-lg"
          onClick={() => {
            handleClick(firstAction);
          }}
        />
        <IconButton
          Icon={<ChevronDownIcon className="size-5" />}
          srOnly="Select action"
          className="!rounded-none !rounded-r-lg"
          onClick={() => {
            setShowOptions((prevState) => !prevState);
          }}
        />
      </div>
      <Transition
        as={Fragment}
        show={showOptions}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute z-10 mt-1 max-h-60 w-full divide-y divide-gray-100 overflow-auto rounded-md bg-white-100 py-1 text-base shadow-lg ring-1 ring-gray-950/5 focus:outline-none sm:text-base dark:divide-gray-600 dark:bg-gray-700">
          <ul className="text-base text-gray-700 dark:text-gray-200" aria-labelledby="dropdownMenuIconButton">
            {menuActions.map((action) => (
              <li key={action}>
                <button
                  className="flex w-full items-center px-4 py-1.5 text-left hover:bg-gray-10 dark:hover:bg-gray-600 dark:hover:text-white-100"
                  onClick={() => {
                    handleClick(action);
                  }}
                >
                  {getActionIcon(action)}
                  {action}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </Transition>
    </div>
  );
};

export default SplitButton;
