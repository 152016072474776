import { useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useNavigate } from 'react-router-dom';
import { Button, ConfirmationModal } from 'ui';

import { useToast } from 'hooks';
import { useSuggestedDraftsStore } from 'stores/suggested-drafts';
import {
  CustomersForm,
  ExpirationForm,
  CampaignNameForm,
  ToForm,
  FromForm,
  SubjectForm,
  TimeForm,
  MessageForm,
} from './components/forms';
import { TemplatePreview } from './components/template-preview';
import CustomerSendTimeForm from './components/forms/CustomerSendTimeForm';

const expandComponents = {
  customers: 'customers',
  customerSendTime: 'customerSendTime',
  message: 'message',
  expiration: 'expiration',
  to: 'to',
  from: 'from',
  subject: 'subject',
  time: 'time',
  template: 'template',
};

const CreateSuggestedDraft = (): JSX.Element => {
  const [activeExpanded, setActiveExpanded] = useState(expandComponents.customers);
  const [saving, setSaving] = useState(false);
  const [sending, setSending] = useState(false);
  const [confirmModal, setConfirmModal] = useState({ open: false, message: '' });
  const {
    error,
    setError,
    updateSuggestedDraft,
    validateDraftDetails,
    sendSuggestedDraft,
    getSendConfirmationMessage,
  } = useSuggestedDraftsStore(
    useShallow((state) => ({
      error: state.error,
      setError: state.setError,
      updateSuggestedDraft: state.updateSuggestedDraft,
      validateDraftDetails: state.validateDraftDetails,
      sendSuggestedDraft: state.sendSuggestedDraft,
      getSendConfirmationMessage: state.getSendConfirmationMessage,
    })),
  );
  const navigate = useNavigate();
  useToast('suggested-drafts-error', 'error', error, setError);

  const setExpandedComponent = (component: string): void => {
    setActiveExpanded((prevComponent) => (prevComponent === component ? '' : component));
  };

  const handleSaveAndExit = async (): Promise<void> => {
    setSaving(true);
    void updateSuggestedDraft({
      onSuccess: () => {
        navigate('/suggested-drafts', { replace: true });
      },
      onError: () => {
        setSaving(false);
      },
    });
  };

  const handleSendToCustomers = async (): Promise<void> => {
    const errorMessage = validateDraftDetails();

    if (!errorMessage) {
      const message = getSendConfirmationMessage();
      setConfirmModal({ open: true, message });
    }
  };

  const sendToCustomers = async (): Promise<void> => {
    setSending(true);
    await sendSuggestedDraft({
      onSuccess: () => {
        navigate('/suggested-drafts', { replace: true });
      },
    });
    setSending(false);
  };

  const disabled = saving || sending;

  return (
    <div className="w-full space-y-6">
      <div className="flex justify-between space-x-3">
        <h1 className="text-h3">Edit Suggested Draft</h1>

        <div className="flex items-center space-x-3">
          <Button
            title="Save & Exit"
            onClick={() => {
              void handleSaveAndExit();
            }}
            loading={saving}
          />

          <Button
            title="Send to Customers"
            color="primary"
            onClick={() => {
              void handleSendToCustomers();
            }}
          />
        </div>
      </div>

      <div className="flex justify-center">
        <form className="w-full max-w-3xl divide-y divide-gray-50 rounded-xl bg-white-100" noValidate>
          <CustomersForm
            open={activeExpanded === expandComponents.customers}
            onClick={() => {
              setExpandedComponent(expandComponents.customers);
            }}
            disabled={disabled}
          />

          <CustomerSendTimeForm
            open={activeExpanded === expandComponents.customerSendTime}
            onClick={() => {
              setExpandedComponent(expandComponents.customerSendTime);
            }}
            disabled={disabled}
          />

          <MessageForm
            open={activeExpanded === expandComponents.message}
            onClick={() => {
              setExpandedComponent(expandComponents.message);
            }}
            disabled={disabled}
          />

          <ExpirationForm
            open={activeExpanded === expandComponents.expiration}
            onClick={() => {
              setExpandedComponent(expandComponents.expiration);
            }}
            disabled={disabled}
          />

          <CampaignNameForm disabled={disabled} />

          <ToForm
            open={activeExpanded === expandComponents.to}
            onClick={() => {
              setExpandedComponent(expandComponents.to);
            }}
            disabled={disabled}
          />

          <FromForm
            open={activeExpanded === expandComponents.from}
            onClick={() => {
              setExpandedComponent(expandComponents.from);
            }}
            disabled={disabled}
          />

          <SubjectForm
            open={activeExpanded === expandComponents.subject}
            onClick={() => {
              setExpandedComponent(expandComponents.subject);
            }}
            disabled={disabled}
          />

          <TimeForm
            open={activeExpanded === expandComponents.time}
            onClick={() => {
              setExpandedComponent(expandComponents.time);
            }}
            disabled={disabled}
          />

          <TemplatePreview
            open={activeExpanded === expandComponents.template}
            onClick={() => {
              setExpandedComponent(expandComponents.template);
            }}
            disabled={disabled}
          />
        </form>
      </div>

      <ConfirmationModal
        open={confirmModal.open}
        onClose={() => {
          setConfirmModal((prev) => ({ ...prev, open: false }));
        }}
        onConfirm={() => {
          void sendToCustomers();
        }}
        message={confirmModal.message}
        loading={sending}
      />
    </div>
  );
};

export default CreateSuggestedDraft;
