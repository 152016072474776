import { type FC } from 'react';
import { Button } from 'ui';

import { signOut } from 'core/utils';

const Account: FC = () => {
  return (
    <div className="flex flex-col">
      <div className="relative w-full max-w-7xl space-y-6 self-center">
        <h2 className="text-xl font-semibold">Welcome to the Account page</h2>

        <Button title="Sign Out" color="primary" onClick={signOut} className="w-fit" />
      </div>
    </div>
  );
};

export default Account;
