import { type FC } from 'react';

type CounterInputProps = {
  id: string;
  value: number;
  onChange: (value: number) => void;
  containerClassName?: string;
  inputClassName?: string;
  disabled?: boolean;
  error?: string;
  size?: 'sm' | 'md';
};

const CounterInput: FC<CounterInputProps> = ({
  id,
  value,
  onChange,
  containerClassName = '',
  inputClassName = '',
  disabled = false,
  size = 'md',
}) => {
  return (
    <div
      className={`
        custom-number-input 
        flex 
        rounded-lg 
        ${size === 'sm' ? 'w-24' : 'w-32'} 
        ${disabled ? 'cursor-not-allowed' : 'cursor-auto'} 
        ${containerClassName}
      `}
      style={{ height: size === 'sm' ? 36 : 42 }}
    >
      <button
        className={`
          h-full
          ${size === 'sm' ? 'w-16' : 'w-20'}
          cursor-pointer
          rounded-l-lg
          border
          !border-r-0
          border-gray-100
          text-gray-500
          outline-none
          hover:bg-gray-50
          hover:text-gray-800 
          ${disabled ? 'pointer-events-none' : ''}
        `}
        onClick={(e) => {
          e.preventDefault();
          onChange(value - 1);
        }}
        disabled={disabled}
      >
        <span className={`m-auto ${size === 'sm' ? 'text-lg' : 'text-2xl'} font-thin`}>−</span>
      </button>
      <input
        type="number"
        id={id}
        value={value}
        disabled={disabled}
        onChange={(e) => {
          onChange(Number(e.target.value));
        }}
        className={`
          md:text-basecursor-default
          counter-input
          flex
          w-full
          items-center
          border
          !border-x-0
          border-gray-100
          ${size === 'sm' ? 'p-2' : 'p-2.5'}
          text-center
          ${size === 'sm' ? 'text-sm' : 'text-base'}
          text-gray-900
          outline-none
          focus:outline-none
          focus:ring-0 
          ${disabled ? 'pointer-events-none' : ''} 
          ${inputClassName}
        `}
        style={{ outline: 'none' }}
      />
      <button
        className={`
          h-full
          ${size === 'sm' ? 'w-16' : 'w-20'}
          cursor-pointer
          rounded-r-lg
          border
          !border-l-0
          border-gray-100
          text-gray-500
          outline-none
          hover:bg-gray-100
          hover:text-gray-800 
          ${disabled ? 'pointer-events-none' : ''}
        `}
        onClick={(e) => {
          e.preventDefault();
          onChange(value + 1);
        }}
        disabled={disabled}
      >
        <span className={`m-auto ${size === 'sm' ? 'text-lg' : 'text-2xl'} font-thin`}>+</span>
      </button>
    </div>
  );
};

export default CounterInput;
