import { type FC, Fragment, useMemo } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { IconButton } from 'ui';

type ErrorDetailsModalProps = {
  open: boolean;
  error: string;
  onClose: () => void;
};

const ErrorDetailsModal: FC<ErrorDetailsModalProps> = ({ open, error, onClose }) => {
  const errorDetails: Record<string, any> = useMemo(() => {
    try {
      const details = JSON.parse(error);
      return details?.Cause ? JSON.parse(details.Cause as string) : {};
    } catch (error) {
      return {};
    }
  }, [error]);

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-950/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative w-full max-w-md overflow-hidden rounded-2xl bg-white-100 p-7 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h2" className="mt-2 text-center text-xl font-medium leading-6 text-gray-900">
                  Error Details
                </Dialog.Title>

                <IconButton
                  Icon={<XMarkIcon className="size-5" />}
                  srOnly="Close"
                  onClick={onClose}
                  className="absolute right-5 top-6 !p-1"
                  color="transparent"
                />

                <div className="relative mt-6">
                  {errorDetails && (
                    <ul className="ml-4 mt-1.5 list-inside list-none">
                      {Object.keys(errorDetails).map((key) => (
                        <li key={key} className="mt-1">
                          <span className="font-semibold capitalize">{key}</span>: <span>{errorDetails[key]}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ErrorDetailsModal;
