import { type ChangeEventHandler, useMemo } from 'react';
import debounce from 'lodash.debounce';
import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';
import { FormCard, Textarea } from 'ui';
import { useShallow } from 'zustand/react/shallow';

import { useSuggestedDraftsStore } from 'stores/suggested-drafts';

type MessageFormProps = {
  open: boolean;
  onClick: () => void;
  disabled?: boolean;
};

const DEBOUNCE_DELAY_TIME_MS = 500;

export const formValidation = yup.object({
  message: yup.string().min(2, 'Message is invalid.').required('Message is required.'),
});

const MessageForm = (props: MessageFormProps): JSX.Element => {
  const { open, onClick, disabled = false } = props;
  const { message, updateDraftDetails } = useSuggestedDraftsStore(
    useShallow((state) => ({
      message: state.draftDetails.message,
      updateDraftDetails: state.updateDraftDetails,
    })),
  );
  const form = useForm({
    initialValues: { message },
    validate: yupResolver(formValidation),
    validateInputOnChange: true,
  });

  const debouncedUpdateTarget = useMemo(
    () => debounce(updateDraftDetails, DEBOUNCE_DELAY_TIME_MS),
    [updateDraftDetails],
  );

  const handleMessageEdit: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    const message = e.target.value;
    form.setFieldValue('message', message);
    debouncedUpdateTarget({ message });
  };

  return (
    <FormCard
      title="Message"
      subtitle="What message should the customer see when reviewing the draft?"
      isExpanded={open}
      handleClick={onClick}
      checked={form.isValid()}
    >
      <Textarea
        id="message"
        label="Message"
        value={form.values.message}
        onChange={handleMessageEdit}
        placeholder="Insert Message"
        required
        disabled={disabled}
        error={!!form.errors.message}
        helperText={(form.errors.message as string) || ''}
        size="lg"
      />
    </FormCard>
  );
};

export default MessageForm;
