import { type ChangeEventHandler, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';
import { IconTag } from '@tabler/icons-react';
import { Button, FormCard, Input, MergeTagBuilderModal } from 'ui';
import { useShallow } from 'zustand/react/shallow';

import { useSuggestedDraftsStore } from 'stores/suggested-drafts';

type SubjectFormProps = {
  open: boolean;
  onClick: () => void;
  disabled?: boolean;
};

const DEBOUNCE_DELAY_TIME_MS = 500;

export const formValidation = yup.object({
  subject: yup.string().min(2, 'Subject is invalid.').required('Subject is required.'),
  previewText: yup.string().min(2, 'Preview text is invalid.').required('Preview text is required.'),
});

const SubjectForm = (props: SubjectFormProps): JSX.Element => {
  const { open, onClick, disabled = false } = props;
  const { subject, previewText, updateEmailConfig } = useSuggestedDraftsStore(
    useShallow((state) => ({
      subject: state.draftDetails.emailConfig.subject,
      previewText: state.draftDetails.emailConfig.previewText,
      updateEmailConfig: state.updateEmailConfig,
    })),
  );
  const [mergeTagBuilderOpen, setMergeTagBuilderOpen] = useState(false);
  const form = useForm({
    initialValues: { subject, previewText },
    validate: yupResolver(formValidation),
    validateInputOnChange: true,
  });

  const debouncedUpdateTarget = useMemo(() => debounce(updateEmailConfig, DEBOUNCE_DELAY_TIME_MS), [updateEmailConfig]);

  const handleSubjectEdit: ChangeEventHandler<HTMLInputElement> = (e) => {
    const subject = e.target.value;
    form.setFieldValue('subject', subject);
    debouncedUpdateTarget({ subject });
  };

  const handlePreviewTextChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const previewText = e.target.value;
    form.setFieldValue('previewText', previewText);
    debouncedUpdateTarget({ previewText });
  };

  return (
    <FormCard
      title="Subject"
      subtitle="What is the subject line?"
      isExpanded={open}
      handleClick={onClick}
      checked={form.isValid()}
    >
      <div className="space-y-6">
        <div className="space-y-4">
          <div className="space-y-4">
            <div className="space-y-1">
              <p className="text-base font-semibold">Subject Line</p>
              <p className="text-base">Be personal, concise, and descriptive.</p>
            </div>
            <Input
              id="subjectline"
              label="Subject Line"
              value={form.values.subject}
              onChange={handleSubjectEdit}
              placeholder="Insert Subject"
              required
              disabled={disabled}
              error={!!form.errors.subject}
              helperText={(form.errors.subject as string) || ''}
              size="xl"
            />
          </div>
          {!disabled && (
            <Button
              title="Merge Tags"
              size="sm"
              className="!mt-3 border-0 !p-0 text-primary-700 !shadow-none focus:!ring-0"
              onClick={() => {
                setMergeTagBuilderOpen(true);
              }}
              LeftIcon={IconTag}
              iconProps={{ size: 16 }}
            />
          )}
        </div>
        <div className="space-y-4">
          <div className="space-y-1">
            <p className="text-base font-semibold">Preview Text</p>
            <p className="text-base">Preview text appears in the inbox next to the subject line.</p>
          </div>
          <Input
            id="previewtext"
            label="Preview Text"
            value={form.values.previewText}
            onChange={handlePreviewTextChange}
            placeholder="Insert Preview"
            required
            disabled={disabled}
            error={!!form.errors.previewText}
            helperText={(form.errors.previewText as string) || ''}
            size="xl"
          />
        </div>
      </div>

      <MergeTagBuilderModal
        open={mergeTagBuilderOpen}
        onClose={() => {
          setMergeTagBuilderOpen(false);
        }}
      />
    </FormCard>
  );
};

export default SubjectForm;
