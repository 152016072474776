import { PREVIEW_HTML_KEY } from 'core/constants';

const TemplatePreview = (): JSX.Element => {
  const previewHtml =
    localStorage.getItem(PREVIEW_HTML_KEY) ??
    '<html><body><h1>Something went wrong, please close this tab and try again.</h1></body></html>';

  return (
    <div className="h-screen w-screen">
      <iframe title="Template preview" className="size-full" srcDoc={previewHtml} />
    </div>
  );
};

export default TemplatePreview;
