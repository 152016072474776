import { SideBar } from 'components/sidebar';
import { type FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../components/header';

const Layout: FC = () => {
  return (
    <div className="flex">
      <SideBar />

      <div className="flex min-h-screen flex-1 flex-col overflow-x-hidden">
        <Header />

        <div className="flex-1 bg-gray-10 p-8">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
