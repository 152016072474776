import { useCallback, useEffect, useMemo, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { FormCard, Loader, MultiSelectInput, Spinner, SwitchInput } from 'ui';
import { getNetworkError } from 'common/utils';

import { SETTINGS } from 'core/constants';
import { useSuggestedDraftsStore } from 'stores/suggested-drafts';
import { useCustomerSegments, useCustomerTags } from '../hooks';
import { isDraftForSingleCustomer } from 'core/utils';

type ToFormProps = {
  open: boolean;
  onClick: () => void;
  disabled?: boolean;
};

const MAX_CONDITIONS = 5;

const DEBOUNCE_DELAY_TIME_MS = 750;

const API_URL = `${SETTINGS.apiUrl}/customer-management/suggested-drafts/audience-count`;

const getAudienceSizeApi = async (queryObj: {
  tenantId: string;
  listIds: string[];
  segmentIds: string[];
  excludeListIds: string[];
  excludeSegmentIds: string[];
}): Promise<number> => {
  const params: Record<string, any> = { tenant_id: queryObj.tenantId };
  if (queryObj.listIds?.length) params.list_ids = queryObj.listIds.join(',');
  if (queryObj.segmentIds?.length) params.segment_ids = queryObj.segmentIds.join(',');
  if (queryObj.excludeListIds?.length) params.exclude_list_ids = queryObj.excludeListIds.join(',');
  if (queryObj.excludeSegmentIds?.length) params.exclude_segment_ids = queryObj.excludeSegmentIds.join(',');
  const res = await axios.get<{ count: number }>(API_URL, { params });
  return res.data.count;
};

const ToForm = (props: ToFormProps): JSX.Element => {
  const { open, onClick, disabled = false } = props;
  const { sendTo, exclude, updateSentTo, updateExclude, setError, tenantIds } = useSuggestedDraftsStore(
    useShallow((state) => ({
      sendTo: state.draftDetails.sendTo,
      exclude: state.draftDetails.exclude,
      updateSentTo: state.updateSentTo,
      updateExclude: state.updateExclude,
      setError: state.setError,
      tenantIds: state.draftDetails.tenantIds,
    })),
  );
  const [audienceSize, setAudienceSize] = useState(0);
  const [loading, setLoading] = useState(false);
  const [excludeContacts, setExcludeContacts] = useState(
    () => exclude.segmentIds.length > 0 || exclude.tagIds.length > 0,
  );
  const isSingleCustomer = isDraftForSingleCustomer(tenantIds);
  const { tags, loading: loadingTags } = useCustomerTags(isSingleCustomer ? tenantIds[0] : undefined);
  const { segments, loading: loadingSegments } = useCustomerSegments(isSingleCustomer ? tenantIds[0] : undefined);

  useEffect(() => {
    if (!isSingleCustomer) {
      setExcludeContacts(false);
    }
  }, [isSingleCustomer]);

  const handleExcludeContacts = (exclude: boolean): void => {
    if (disabled) return;
    setExcludeContacts(exclude);
    if (!exclude) {
      updateExclude({ segmentIds: [], tagIds: [] });
    }
  };

  const selectedAudienceIds = useMemo(() => {
    const audienceIds = [...(sendTo.tagIds ?? []), ...(sendTo.segmentIds ?? [])];
    if (sendTo.all) {
      audienceIds.push('all');
    }
    return audienceIds;
  }, [sendTo.all, sendTo.segmentIds, sendTo.tagIds]);

  // "All Contacts" is not considered to be a condition
  const totalConditions = [
    ...selectedAudienceIds.filter((audienceId) => audienceId !== 'all'),
    ...exclude.segmentIds,
    ...exclude.tagIds,
  ].length;

  const handleSelectAudience = (values: string[]): void => {
    if (disabled) return;

    let audienceIds = values;

    if (values.slice(-1)[0] === 'all') {
      // If 'all' was the last selected value
      audienceIds = ['all'];
    } else if (values.includes('all')) {
      // else, remove 'all' value
      audienceIds = values.filter((v) => v !== 'all');
    }

    if (!audienceIds.length && (exclude.segmentIds.length || exclude.tagIds.length)) {
      // If nothing is selected but we have excluded audience
      audienceIds = ['all'];
    }

    if (
      !audienceIds.includes('all') &&
      audienceIds.length > selectedAudienceIds.length &&
      totalConditions === MAX_CONDITIONS
    ) {
      if (audienceIds.length !== 2) {
        return;
      }
      audienceIds = audienceIds.slice(-1);
    }

    updateSentTo({
      all: audienceIds.includes('all'),
      segmentIds: segments
        .filter((segment) => audienceIds.includes(segment.segmentId))
        .map((segment) => segment.segmentId),
      tagIds: tags.filter((tag) => audienceIds.includes(tag.id)).map((tag) => tag.id),
    });

    updateExclude({
      segmentIds: exclude.segmentIds.filter((segmentId) => !audienceIds.includes(segmentId)),
      tagIds: exclude.tagIds.filter((tagId) => !audienceIds.includes(tagId)),
    });
  };

  const updateExcludedAudience = (propKey: 'segmentIds' | 'tagIds', excludedIds: string[]): void => {
    if (selectedAudienceIds.length === 0) {
      updateSentTo({ all: true });
      return;
    }
    updateSentTo({ [propKey]: sendTo[propKey].filter((id) => !excludedIds.includes(id)) });
  };

  const handleSelectExcludedSegments = (values: string[]): void => {
    if (disabled || (values.length > exclude.segmentIds.length && totalConditions === MAX_CONDITIONS)) {
      return;
    }
    updateExclude({ segmentIds: values });
    updateExcludedAudience('segmentIds', values);
  };

  const handleSelectExcludedTags = (values: string[]): void => {
    if (disabled || (values.length > exclude.tagIds.length && totalConditions === MAX_CONDITIONS)) {
      return;
    }
    updateExclude({ tagIds: values });
    updateExcludedAudience('tagIds', values);
  };

  const getAudienceSize = useCallback(async () => {
    if (disabled || !isSingleCustomer) return;
    if (!selectedAudienceIds.length && !exclude.segmentIds.length && !exclude.tagIds.length) {
      setAudienceSize(0);
      return;
    }
    setLoading(true);
    try {
      const audienceCount = await getAudienceSizeApi({
        tenantId: tenantIds[0],
        listIds: sendTo.tagIds,
        segmentIds: sendTo.segmentIds,
        excludeListIds: exclude.tagIds,
        excludeSegmentIds: exclude.segmentIds,
      });
      setAudienceSize(audienceCount);
    } catch (err) {
      setError(getNetworkError(err));
    }
    setLoading(false);
  }, [
    disabled,
    exclude.segmentIds,
    exclude.tagIds,
    selectedAudienceIds.length,
    sendTo.segmentIds,
    sendTo.tagIds,
    setError,
    isSingleCustomer,
    tenantIds,
  ]);

  const debouncedGetAudienceSize = useMemo(() => debounce(getAudienceSize, DEBOUNCE_DELAY_TIME_MS), [getAudienceSize]);

  useEffect(() => {
    void debouncedGetAudienceSize();
  }, [debouncedGetAudienceSize]);

  const audienceSections = useMemo(() => {
    const sections = [];
    if (tags.length > 0) {
      sections.push({
        title: 'Tags',
        options: tags.map((tag) => ({ id: tag.id, name: tag.name, type: 'tag' })),
      });
    }
    if (segments.length > 0) {
      sections.push({
        title: 'Segments',
        options: segments.map((segment) => ({ id: segment.segmentId, name: segment.name, type: 'segment' })),
      });
    }
    sections.push({ title: 'All', options: [{ id: 'all', name: 'All Contacts', type: 'all' }] });
    return sections;
  }, [tags, segments]);

  return (
    <FormCard
      title="To"
      subtitle="Who is sending this campaign?"
      isExpanded={open}
      handleClick={onClick}
      checked={(sendTo.all || sendTo.tagIds.length > 0 || sendTo.segmentIds.length > 0) && isSingleCustomer}
      disabled={!isSingleCustomer}
    >
      <div className="relative space-y-4">
        <div className="flex w-full space-x-6">
          <MultiSelectInput
            id="audience"
            label="Select Audience"
            sections={audienceSections}
            value={selectedAudienceIds}
            onChange={handleSelectAudience}
            containerClassName="flex-1 max-w-[290px]"
            disabled={disabled}
          />

          {!disabled && (
            <div className="flex flex-1 items-center">
              <p className="text-base italic">
                Estimated audience size: <span className="font-semibold">{audienceSize} contacts</span>
              </p>
              {loading && <Spinner className="ml-2 size-5" />}
            </div>
          )}
        </div>

        <SwitchInput
          label={excludeContacts ? 'Exclude the following:' : 'Do not exclude any segments'}
          checked={excludeContacts}
          onChange={handleExcludeContacts}
          disabled={disabled}
        />

        {excludeContacts && (
          <div className="!mt-5 flex w-full space-x-3">
            <MultiSelectInput
              id="segment"
              label="Segment"
              options={audienceSections.find((audience) => audience.title === 'Segments')?.options || []}
              value={exclude.segmentIds}
              onChange={handleSelectExcludedSegments}
              containerClassName="flex-1 max-w-[290px]"
              disabled={disabled}
            />

            <MultiSelectInput
              id="tag"
              label="Tag"
              options={audienceSections.find((audience) => audience.title === 'Tags')?.options || []}
              value={exclude.tagIds}
              onChange={handleSelectExcludedTags}
              containerClassName="flex-1 max-w-[290px]"
              disabled={disabled}
            />
          </div>
        )}

        <div className="!mt-1.5">
          <p className="text-base italic">You can only select up to {MAX_CONDITIONS} conditions.</p>
          {totalConditions === MAX_CONDITIONS && (
            <p className="text-base font-semibold italic">{totalConditions} selected conditions</p>
          )}
        </div>

        {(loadingTags || loadingSegments) && <Loader />}
      </div>
    </FormCard>
  );
};

export default ToForm;
