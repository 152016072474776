import { useCallback, useEffect, useMemo, useState } from 'react';
import { IconPlus } from '@tabler/icons-react';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Button,
  TableFilters,
  TableSorters,
  type FilterSection,
  type SelectedFilters,
  ActiveFilters,
  Loader,
} from 'ui';
import { getNetworkError, sortDataWithDirection } from 'common/utils';
import { type SortOrder, type SuggestedDraft } from 'common/types';

import { SETTINGS } from 'core/constants';
import { cancelScheduledSuggestedDraft as cancelScheduledSuggestedDraftApi } from 'core/api';
import { ErrorToast } from 'components/toasts';
import { customNanoid, sortData } from 'core/utils';
import { useSuggestedDraftsStore } from 'stores/suggested-drafts';
import { useCustomersStore } from 'stores/customers/store';
import { Table } from './components/table';
import { Drawer } from './components/drawer';
import { SUGGESTED_DRAFT_STATUS } from 'common/constants';

type FilterPropKeys = keyof SuggestedDraft;

const API_URL = `${SETTINGS.apiUrl}/customer-management/suggested-drafts`;

const suggestedDraftSortBy = {
  sendTime: 'sendTime',
  updatedAt: 'updatedAt',
  totalAccepted: 'totalAccepted',
};

const sortingOptions = [
  { label: 'Send Time', value: suggestedDraftSortBy.sendTime },
  { label: 'Last Edited', value: suggestedDraftSortBy.updatedAt },
  { label: 'Acceptance', value: suggestedDraftSortBy.totalAccepted },
];

const SuggestedDrafts = (): JSX.Element => {
  const [sortBy, setSortBy] = useState(suggestedDraftSortBy.updatedAt);
  const [sortDirection, setSortDirection] = useState<SortOrder>('desc');
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters<FilterPropKeys>>({});
  const [loading, setLoading] = useState(false);
  const [suggestedDrafts, setSuggestedDrafts] = useState<SuggestedDraft[]>([]);
  const navigate = useNavigate();
  const { setDraftDetails, resetState } = useSuggestedDraftsStore((state) => ({
    setDraftDetails: state.setDraftDetails,
    resetState: state.resetState,
  }));
  const { getCustomers, activeCustomers } = useCustomersStore((state) => ({
    getCustomers: state.getCustomers,
    activeCustomers: state.activeCustomers,
  }));
  const { selectedDraftId } = useParams();

  useEffect(() => {
    void getCustomers();
  }, [getCustomers]);

  const selectedDraft = useMemo(
    () => suggestedDrafts.find((draft) => draft.draftId === selectedDraftId),
    [selectedDraftId, suggestedDrafts],
  );

  useEffect(() => {
    if (!selectedDraft) {
      resetState();
      return;
    }
    const { createdAt, updatedAt, status, acceptedCount, customersCount, ...rest } = selectedDraft;
    resetState();
    setDraftDetails(rest);
  }, [resetState, selectedDraft, setDraftDetails]);

  const getSuggestedDrafts = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get<SuggestedDraft[]>(API_URL);
      setSuggestedDrafts(sortData<SuggestedDraft>(response.data ?? [], 'createdAt'));
    } catch (err) {
      toast.custom((t) => <ErrorToast visible={t.visible} message={getNetworkError(err)} />, {
        id: 'getInvitesError',
      });
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    void getSuggestedDrafts();
  }, [getSuggestedDrafts]);

  const cancelScheduledSuggestedDraft = useCallback(
    async (draftId: string): Promise<void> => {
      setLoading(true);
      try {
        await cancelScheduledSuggestedDraftApi(draftId);
        await getSuggestedDrafts();
      } catch (err) {
        toast.custom((t) => <ErrorToast visible={t.visible} message={getNetworkError(err)} />, {
          id: 'cancelScheduledSuggestedDraftError',
        });
      }
      setLoading(false);
    },
    [getSuggestedDrafts],
  );

  const handleDeleteActiveFilter = (propKey: FilterPropKeys, value: string): void => {
    setSelectedFilters((prev) => ({ ...prev, [propKey]: (prev[propKey] ?? []).filter((val) => val.value !== value) }));
  };

  const handleCrateDraft = (): void => {
    resetState();
    setDraftDetails({ tempDraftId: customNanoid() });
    navigate('create');
  };

  const activeFilterStringValues = useMemo(
    () =>
      Object.values(selectedFilters)
        .flat()
        .map((val) => val?.value),
    [selectedFilters],
  );

  const data = useMemo(() => {
    let filteredSuggestedDrafts = suggestedDrafts;

    if (activeFilterStringValues.length > 0) {
      const propKeys = Object.keys(selectedFilters) as FilterPropKeys[];

      propKeys.forEach((propKey) => {
        if (propKey in filteredSuggestedDrafts[0]) {
          filteredSuggestedDrafts = filteredSuggestedDrafts.filter((suggestedDraft) => {
            const filterValues = selectedFilters[propKey]?.map((filter) => filter.value) ?? [];
            // tenantIds filter requires special logic since it needs to also consider excludedTenantIds and 'all'
            // for multiple filtered tenantIds, we should show any draft that has at least one of the selected tenantIds
            if (propKey === 'tenantIds') {
              return (
                suggestedDraft[propKey].some((val) => filterValues.includes(val)) ||
                (suggestedDraft[propKey][0] === 'all' &&
                  !suggestedDraft.excludedTenantIds?.every((val) => filterValues.includes(val)))
              );
            }
            // We're only managing string values of suggested drafts - update to handle different types
            return filterValues.includes(suggestedDraft[propKey] as string);
          });
        }
      });
    }

    if (sortBy) {
      filteredSuggestedDrafts = sortDataWithDirection<SuggestedDraft>(filteredSuggestedDrafts, sortBy, sortDirection);
    }

    return filteredSuggestedDrafts;
  }, [activeFilterStringValues.length, selectedFilters, sortBy, sortDirection, suggestedDrafts]);

  const filterSections: Array<FilterSection<FilterPropKeys>> = useMemo(() => {
    return [
      {
        title: 'Customer',
        propKey: 'tenantIds',
        options: activeCustomers
          .map((customer) => ({
            label: customer.name || customer.email || customer.user?.email || '',
            value: customer.tenantId,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      },
      {
        title: 'Stage',
        propKey: 'status',
        options: [
          { label: 'Draft', value: SUGGESTED_DRAFT_STATUS.draft },
          { label: 'Scheduled', value: SUGGESTED_DRAFT_STATUS.scheduled },
          { label: 'Sent', value: SUGGESTED_DRAFT_STATUS.sent },
        ],
      },
    ];
  }, [activeCustomers]);

  return (
    <div className="w-full space-y-6">
      <div className="flex justify-between space-x-3">
        <h1 className="text-h3">Suggested Drafts</h1>

        <div className="flex items-center space-x-3">
          <TableSorters
            options={sortingOptions}
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
          />

          <TableFilters<FilterPropKeys>
            key={activeFilterStringValues.join(',')}
            filterSections={filterSections}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />

          <Button title="New Draft" color="primary" LeftIcon={IconPlus} onClick={handleCrateDraft} />
        </div>
      </div>

      {activeFilterStringValues.length > 0 && (
        <ActiveFilters
          filters={selectedFilters}
          onDelete={handleDeleteActiveFilter}
          onReset={() => {
            setSelectedFilters({});
          }}
        />
      )}

      <div className="relative">
        <Table data={data} cancelScheduledDraft={cancelScheduledSuggestedDraft} />

        {loading && <Loader />}
      </div>

      <Drawer selectedDraft={selectedDraft} />
    </div>
  );
};

export default SuggestedDrafts;
