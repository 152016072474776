/* eslint-disable react/prop-types */
import { type FC, useMemo } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
  type ColumnDef,
} from '@tanstack/react-table';
import { nanoid } from 'nanoid';
import { IconButton } from 'ui';

import { type Invite, InviteStatus } from 'core/types';
import { formatDate } from 'core/utils';
import { CustomPagination } from 'components/table';
import { SETTINGS } from 'core/constants';
import { ClipboardDocumentIcon } from '@heroicons/react/20/solid';

type TableProps = {
  data: Invite[];
};

const Table: FC<TableProps> = ({ data }) => {
  const columns = useMemo<Array<ColumnDef<Invite>>>(
    () => [
      {
        id: nanoid(),
        accessorKey: 'email',
        header: () => 'Email',
        footer: (props: any) => props.column.id,
        cell: (info: any) => {
          return (
            <div>
              <p className="text-base">{info.getValue()}</p>
              <p className="text-sm text-gray-600">{info.row.original.name}</p>
            </div>
          );
        },
      },
      {
        id: nanoid(),
        accessorKey: 'status',
        header: () => 'Status',
        footer: (props: any) => props.column.id,
        cell: (info: any) => {
          const status = info.getValue() as InviteStatus;
          let className = 'capitalize font-medium';
          if (status === InviteStatus.accepted) className += ' text-primary-700';
          if (status === InviteStatus.expired) className += ' text-red-600';
          return <span className={className}>{status}</span>;
        },
      },
      {
        id: nanoid(),
        accessorKey: 'createdAt',
        header: () => 'Created At',
        footer: (props: any) => props.column.id,
        cell: (info: any) => (info.getValue() ? formatDate(info.getValue() * 1000) : ''),
      },
      {
        id: nanoid(),
        accessorKey: 'expiresAt',
        header: () => 'Expires At',
        footer: (props: any) => props.column.id,
        cell: (info: any) => (info.getValue() ? formatDate(info.getValue() * 1000) : ''),
      },
      {
        id: nanoid(),
        accessorKey: 'inviteId',
        header: () => 'Invite Link',
        footer: (props: any) => props.column.id,
        cell: (info: any) => {
          const inviteId = info.getValue();
          if (!inviteId) return '';

          const inviteLink = `${SETTINGS.customerAppUrl}/signup/${info.getValue()}`;

          return (
            <div className="flex items-center justify-between">
              {inviteLink}
              <IconButton
                className="!p-1.5"
                Icon={<ClipboardDocumentIcon className="size-4" />}
                srOnly="Copy to clipboard"
                onClick={() => {
                  void navigator.clipboard.writeText(inviteLink);
                }}
              />
            </div>
          );
        },
      },
    ],
    [],
  );

  const table = useReactTable({
    data,
    columns,
    defaultColumn: {
      // @ts-expect-error string values also work
      size: 'fit-content',
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const emptyRows = 5 - data.length;

  return (
    <div className="space-y-6">
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full bg-white-100 text-left text-base text-gray-950 dark:text-gray-400">
          <thead className="text-sm uppercase text-gray-400 dark:bg-gray-700 dark:text-gray-400">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className="px-4 py-3"
                      style={{ width: header.column.columnDef.size, minWidth: header.column.columnDef.minSize }}
                    >
                      {header.isPlaceholder ? null : (
                        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, index) => {
              return (
                <tr
                  key={row.id}
                  className={`
                    h-[52px]
                    border-b
                    border-gray-50 
                    hover:bg-gray-10
                    dark:border-gray-700
                    dark:bg-gray-800
                    dark:hover:bg-gray-600
                  `}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className="px-4 py-2"
                        style={{ width: cell.column.columnDef.size, minWidth: cell.column.columnDef.minSize }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {emptyRows > 0 &&
              Array(emptyRows)
                .fill('')
                .map((row, index) => (
                  <tr
                    key={`emptyRow-${index}`}
                    className={`h-[52px] border-b border-gray-50 hover:bg-gray-10 dark:bg-gray-800`}
                  >
                    <td className="px-4 py-2" colSpan={15} style={{ minWidth: 110 }}></td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>

      <div className="flex items-center justify-between gap-2">
        <div>
          Showing{' '}
          <strong>
            {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1}-
            {Math.min(table.getState().pagination.pageSize * (table.getState().pagination.pageIndex + 1), data.length)}
          </strong>{' '}
          of <strong>{data.length}</strong>
        </div>

        <CustomPagination<Invite> table={table} />
      </div>
    </div>
  );
};

export default Table;
