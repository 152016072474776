import { useCallback, useEffect, useState } from 'react';
import { SETTINGS } from 'core/constants';
import axios from 'axios';
import { getNetworkError } from 'common/utils';
import { type Segment } from 'common/types';

type UseCustomerSegments = {
  loading: boolean;
  error: string;
  segments: Segment[];
};

const API_URL = `${SETTINGS.apiUrl}/customer-management/segments`;

const useCustomerSegments = (tenantId?: string): UseCustomerSegments => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [segments, setSegments] = useState<Segment[]>([]);

  const getCustomerSegments = useCallback(async () => {
    if (!tenantId) return;
    setLoading(true);
    try {
      const response = await axios.get<Segment[]>(`${API_URL}/${tenantId}`);
      setSegments(response.data);
    } catch (err) {
      setError(getNetworkError(err));
    }
    setLoading(false);
  }, [tenantId]);

  useEffect(() => {
    void getCustomerSegments();
  }, [getCustomerSegments]);

  return {
    loading,
    error,
    segments,
  };
};

export default useCustomerSegments;
