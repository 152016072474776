import { type FC, useState, useEffect, useCallback, useMemo } from 'react';
import toast from 'react-hot-toast';
import axios from 'axios';
import { useInterval } from 'usehooks-ts';
import { getNetworkError } from 'common/utils';

import { type IReport, ReportStatus } from 'core/types';
import { SETTINGS } from 'core/constants';
import { Loader } from 'components/loader';
import { ErrorToast } from 'components/toasts';
import { Table } from './components/table';

const API_URL = `${SETTINGS.apiUrl}/user-reports`;

const POLLING_INTERVAL_IN_MS = 15000;

const Reports: FC = () => {
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState<IReport[]>([]);
  const [reportErrors, setReportErrors] = useState<IReport[]>([]);

  useEffect(() => {
    return () => {
      toast.remove();
    };
  }, []);

  const getReports = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(API_URL);
      setReports(response.data as IReport[]);
      setReportErrors(response.data.filter((customer: IReport) => customer.status === ReportStatus.ERROR) as IReport[]);
    } catch (err) {
      toast.custom((t) => <ErrorToast visible={t.visible} message={getNetworkError(err)} />, {
        id: 'getCustomersError',
      });
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    void getReports();
  }, [getReports]);

  const isInProgress = useMemo(() => {
    return reports.some(({ status }) => status === ReportStatus.PROCESSING);
  }, [reports]);

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  useInterval(getReports, isInProgress ? POLLING_INTERVAL_IN_MS : null);

  const displayError = useCallback((reportError: IReport) => {
    toast.custom(
      (t) => (
        <ErrorToast
          visible={t.visible}
          message={
            <>
              <span>Onboarding error for customer </span>
              <span
                className="cursor-pointer underline"
                onClick={() => {
                  toast.remove(t.id);
                }}
              >
                {reportError.name}
              </span>
            </>
          }
          onClose={() => {
            toast.remove(t.id);
            setTimeout(() => {
              setReportErrors((prevState) => prevState.filter((item) => item.id !== reportError.id));
            }, 200);
          }}
        />
      ),
      { id: reportError.id, duration: Infinity },
    );
  }, []);

  useEffect(() => {
    if (reportErrors.length === 0) return;
    displayError(reportErrors[0]);
  }, [reportErrors, displayError]);

  return (
    <div className="w-full max-w-7xl space-y-6 self-center">
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-semibold">Reports</h2>
      </div>

      <div className="relative">
        <Table data={reports} />

        {loading && <Loader />}
      </div>
    </div>
  );
};

export default Reports;
