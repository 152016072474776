import { useCallback, useEffect, useState } from 'react';
import { SETTINGS } from 'core/constants';
import axios from 'axios';
import { getNetworkError } from 'common/utils';
import { type Tag } from 'common/types';

type UseCustomerTags = {
  loading: boolean;
  error: string;
  tags: Tag[];
};

const API_URL = `${SETTINGS.apiUrl}/customer-management/tags`;

const useCustomerTags = (tenantId?: string): UseCustomerTags => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [tags, setTags] = useState<Tag[]>([]);

  const getCustomerTags = useCallback(async () => {
    if (!tenantId) return;
    setLoading(true);
    try {
      const response = await axios.get<Tag[]>(`${API_URL}/${tenantId}`);
      setTags(response.data);
    } catch (err) {
      setError(getNetworkError(err));
    }
    setLoading(false);
  }, [tenantId]);

  useEffect(() => {
    void getCustomerTags();
  }, [getCustomerTags]);

  return {
    loading,
    error,
    tags,
  };
};

export default useCustomerTags;
