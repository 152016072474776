import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSuggestedDraftsStore } from 'stores/suggested-drafts';
import { Button, FormCard, Loader } from 'ui';
import { useShallow } from 'zustand/react/shallow';

type TemplatePreviewProps = {
  open: boolean;
  onClick: () => void;
  disabled?: boolean;
};

const TemplatePreview = (props: TemplatePreviewProps): JSX.Element => {
  const { open, onClick, disabled = false } = props;
  const [loading, setLoading] = useState(false);
  const { templateId, templateVersion, templateDesign, templateHtml, templateName, getSuggestedDraftTemplate } =
    useSuggestedDraftsStore(
      useShallow((state) => ({
        templateId: state.draftDetails.template.templateId,
        templateVersion: state.draftDetails.template.version,
        templateDesign: state.draftTemplate.design,
        templateHtml: state.draftTemplate.html,
        templateName: state.draftTemplate.templateName,
        getSuggestedDraftTemplate: state.getSuggestedDraftTemplate,
      })),
    );
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDraftTemplate = async (): Promise<void> => {
      setLoading(true);
      await getSuggestedDraftTemplate({ templateId, version: templateVersion });
      setLoading(false);
    };

    if (templateId && templateVersion && !templateDesign) {
      void fetchDraftTemplate();
    }
  }, [getSuggestedDraftTemplate, templateDesign, templateId, templateVersion]);

  const goToTemplateDesigner = (): void => {
    navigate(templateDesign ? '/suggested-drafts/editor' : '/suggested-drafts/templates');
  };

  const renderPreview = (): JSX.Element | null => {
    if (!templateHtml) return null;

    return (
      <div className="h-[544px] w-full">
        <iframe title={templateName} className="size-full" srcDoc={templateHtml} />
      </div>
    );
  };

  return (
    <FormCard
      title="Content"
      subtitle="What are you communicating?"
      isExpanded={open}
      handleClick={onClick}
      checked={!!templateId}
    >
      <div className="relative space-y-6">
        {!disabled && <Button title="Design Email" onClick={goToTemplateDesigner} />}

        {renderPreview()}

        {loading && <Loader />}
      </div>
    </FormCard>
  );
};

export default TemplatePreview;
