import {
  ScalingPricingSubscriptionItemType,
  SubscriptionBillingInterval,
  SubscriptionPlan,
  SubscriptionPricingModel,
} from 'core/types';

export const PLAN_LABELS = {
  [SubscriptionPlan.ESSENTIAL]: 'Essential',
  [SubscriptionPlan.TEAM]: 'Team',
  [SubscriptionPlan.FULL_SERVICE]: 'Full Service',
} as const;

export const PLAN_BILLING_PERIOD_LABELS = {
  [SubscriptionBillingInterval.MONTHLY]: 'Monthly',
  [SubscriptionBillingInterval.QUARTERLY]: 'Quarterly',
  [SubscriptionBillingInterval.YEARLY]: 'Yearly',
} as const;

export const PRICING_MODEL_LABELS = {
  [SubscriptionPricingModel.SCALING]: 'Scaling',
  [SubscriptionPricingModel.LEGACY]: 'Legacy',
} as const;

export const SCALING_PRICING_ITEM_LABELS = {
  [ScalingPricingSubscriptionItemType.FUNDRAISING_ESSENTIALS_SUITE]: 'Fundraising Essentials Suite',
  [ScalingPricingSubscriptionItemType.AUTOMATED_CULTIVATION]: 'Automated Cultivation',
  [ScalingPricingSubscriptionItemType.DONOR_ACQUISITION]: 'Donor Acquisition',
  [ScalingPricingSubscriptionItemType.FUNDRAISING_SUPPORT_SPECIALIST]: 'Fundraising Support Specialist',
} as const;

export const SCALING_PRICING_DEFAULT_FIXED_PRICES: Partial<Record<ScalingPricingSubscriptionItemType, number>> = {
  [ScalingPricingSubscriptionItemType.AUTOMATED_CULTIVATION]: 3000,
  [ScalingPricingSubscriptionItemType.FUNDRAISING_SUPPORT_SPECIALIST]: 42500,
} as const;

export const SCALING_PRICING_DEFAULT_USAGE_PRICES: Partial<Record<ScalingPricingSubscriptionItemType, number>> = {
  [ScalingPricingSubscriptionItemType.FUNDRAISING_ESSENTIALS_SUITE]: 5000,
  [ScalingPricingSubscriptionItemType.AUTOMATED_CULTIVATION]: 7000,
  [ScalingPricingSubscriptionItemType.FUNDRAISING_SUPPORT_SPECIALIST]: 10200,
} as const;
