import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconArrowLeft, IconFileCheck, IconFilePencil } from '@tabler/icons-react';
import { useShallow } from 'zustand/react/shallow';
import EmailEditor, { type Design } from 'react-email-editor';
import debounce from 'lodash.debounce';
import isEmpty from 'lodash.isempty';
import {
  Button,
  ConfirmExitPageModal,
  ContentGeneratorModal,
  type EmailTemplate,
  IconButton,
  MergeTagBuilderModal,
  TestEmailModal,
  useCallbackPrompt,
} from 'ui';
import { DEBOUNCE_DELAY_TIME_MS } from 'common/constants';

import { useSuggestedDraftsStore } from 'stores/suggested-drafts';
import useCustomerDomain from 'pages/create-suggested-draft/components/hooks/useCustomerDomain';
import { PREVIEW_HTML_KEY } from 'core/constants';
import { getDonationLink, getEditorOptions, getLinkTypes } from './utils';
import { createAIContent, sendTestEmail } from './api';
import { useToast } from 'hooks';
import { isDraftForSingleCustomer } from 'core/utils';

type LocationState = EmailTemplate & { design?: string };

const bodyValues = {
  fontFamily: {
    label: 'Lato',
    value: "'Lato', Tahoma, Verdana, sans-serif",
  },
};

const EmailEditorPage = (): JSX.Element => {
  const [testOpen, setTestOpen] = useState(false);
  const [blockExit, setBlockExit] = useState(true);
  const [mergeTagBuilderOpen, setMergeTagBuilderOpen] = useState(false);
  const [contentGeneratorOpen, setContentGeneratorOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editorLoading, setEditorLoading] = useState(true);
  const [saveExiting, setSaveExiting] = useState(false);
  const editorRef = useRef<EmailEditor | null>(null);
  const navigate = useNavigate();
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(blockExit);
  const location = useLocation();
  const {
    draftDetails,
    draftTemplate,
    createSuggestedDraftTemplate,
    updateSuggestedDraftTemplate,
    error,
    setError,
    success,
    setSuccess,
  } = useSuggestedDraftsStore(
    useShallow((state) => ({
      draftDetails: state.draftDetails,
      draftTemplate: state.draftTemplate,
      createSuggestedDraftTemplate: state.createSuggestedDraftTemplate,
      updateSuggestedDraftTemplate: state.updateSuggestedDraftTemplate,
      error: state.error,
      setError: state.setError,
      success: state.success,
      setSuccess: state.setSuccess,
    })),
  );
  const { donationPageSubdomain } = useCustomerDomain(
    isDraftForSingleCustomer(draftDetails.tenantIds) ? draftDetails.tenantIds[0] : undefined,
  );
  useToast('suggested-drafts-editor-error', 'error', error, setError);
  useToast('suggested-drafts-editor-success', 'success', success, setSuccess);

  const campaignId = draftDetails.draftId || draftDetails.tempDraftId;
  const selectedTemplate = location.state as LocationState;
  const templateId = draftTemplate.templateId;
  const templateVersion = draftTemplate.templateVersion;

  const exportHtml = useCallback(async (): Promise<string> => {
    return await new Promise((resolve, reject) => {
      if (!editorRef.current) reject(new Error('Editor is not ready'));
      else {
        editorRef.current.exportHtml((data) => {
          resolve(data.html);
        });
      }
    });
  }, []);

  const exportDesign = useCallback(async (): Promise<Design> => {
    return await new Promise((resolve, reject) => {
      if (!editorRef.current) reject(new Error('Editor is not ready'));
      else {
        editorRef.current.saveDesign((design) => {
          resolve(design);
        });
      }
    });
  }, []);

  const saveTemplate = useCallback(
    async (options: { onSuccess?: () => void; onError?: () => void } = {}) => {
      const { onSuccess, onError } = options;
      const html = await exportHtml();
      const design = await exportDesign();

      if (!html || isEmpty(design)) {
        throw new Error('Email template cannot be empty, please try again.');
      }

      if (templateId && templateVersion) {
        await updateSuggestedDraftTemplate(
          { templateId, version: templateVersion, html, design: JSON.stringify(design) },
          { onSuccess, onError },
        );
        return;
      }

      await createSuggestedDraftTemplate({ design: JSON.stringify(design), html }, { onSuccess, onError });
    },
    [exportHtml, exportDesign, templateId, templateVersion, createSuggestedDraftTemplate, updateSuggestedDraftTemplate],
  );

  const handleDesignUpdated = useCallback(async () => {
    if (editorLoading || saving) {
      return;
    }
    setSaving(true);
    await saveTemplate();
    setSaving(false);
  }, [editorLoading, saveTemplate, saving]);

  const debouncedHandleDesignUpdated = useMemo(
    () => debounce(handleDesignUpdated, DEBOUNCE_DELAY_TIME_MS),
    [handleDesignUpdated],
  );

  useEffect(() => {
    // @ts-expect-error editor is defined
    const editor = editorRef.current?.editor;
    editor?.addEventListener('design:updated', debouncedHandleDesignUpdated);

    return () => {
      editor?.removeEventListener('design:updated', debouncedHandleDesignUpdated);
    };
  }, [debouncedHandleDesignUpdated]);

  const handleGoBack = (): void => {
    navigate(-1);
  };

  const handleEditorReady = useCallback(async () => {
    let design = draftTemplate.design;
    if (!design) {
      design = JSON.stringify(await import(`../../data/templates/${selectedTemplate?.source}`));
    }
    const donationPageUrl = getDonationLink(donationPageSubdomain, { campaignId });
    design = design.replace(/yourdonationlink/g, donationPageUrl);
    editorRef.current?.loadDesign(JSON.parse(design) as Design);
    // @ts-expect-error editor is defined
    editorRef.current?.editor.setBodyValues(bodyValues);
    // @ts-expect-error editor is defined
    editorRef.current?.editor.setLinkTypes(getLinkTypes(donationPageUrl));
    setEditorLoading(false);
  }, [campaignId, donationPageSubdomain, draftTemplate.design, selectedTemplate?.source]);

  const previewTemplate = async (): Promise<void> => {
    try {
      const html = await exportHtml();
      localStorage.setItem(PREVIEW_HTML_KEY, html);
      window.open('/template-preview', '_blank');
    } catch (err: any) {
      setError((err as Error)?.message);
    }
  };

  const exitPage = (): void => {
    const to = draftDetails.draftId ? `/suggested-drafts/edit` : '/suggested-drafts/create';
    navigate(to, { replace: true });
  };

  const saveAndExit = async (): Promise<void> => {
    setSaveExiting(true);
    try {
      setBlockExit(false);
      await saveTemplate({
        onSuccess: () => {
          exitPage();
        },
        onError: () => {
          setBlockExit(true);
          setSaveExiting(false);
        },
      });
    } catch (err: any) {
      setError((err as Error)?.message);
      setBlockExit(true);
      setSaveExiting(false);
    }
  };

  const handleGenerateAIContent = async (prompt: string): Promise<string> => {
    setError('');
    const aiContent = await createAIContent(prompt);
    return aiContent;
  };

  const handleSendTestEmail = async (email: string, html: string): Promise<void> => {
    await sendTestEmail({
      to: email,
      html,
      campaignId: draftDetails.draftId,
      tenantId: isDraftForSingleCustomer(draftDetails.tenantIds) ? draftDetails.tenantIds[0] : undefined,
      subject: draftDetails.emailConfig.subject,
      previewText: draftDetails.emailConfig.previewText,
    });
  };

  const onEditorReady = useCallback(() => {
    void handleEditorReady();
  }, [handleEditorReady]);

  const editorOptions = useMemo(
    () => getEditorOptions(campaignId, donationPageSubdomain),
    [campaignId, donationPageSubdomain],
  );

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between space-x-3">
        <div className="flex items-center space-x-1">
          <IconButton
            Icon={<IconArrowLeft size={20} />}
            color="transparent"
            className="-ml-2"
            onClick={handleGoBack}
            srOnly="Go back"
          />
          <h1 className="text-h3">Design Email</h1>
        </div>
        <div className="flex flex-1 flex-wrap justify-end gap-3">
          <Button
            title="Generate AI Content"
            onClick={() => {
              setContentGeneratorOpen(true);
            }}
          />
          <Button
            title="Merge Tags"
            onClick={() => {
              setMergeTagBuilderOpen(true);
            }}
          />
          <Button
            title="Preview"
            onClick={() => {
              void previewTemplate();
            }}
          />
          <Button
            title="Test"
            onClick={() => {
              setTestOpen(true);
            }}
          />
          <Button
            title="Save & Exit"
            color="primary"
            onClick={() => {
              void saveAndExit();
            }}
            loading={saveExiting}
          />
        </div>
      </div>

      <div className="rounded-xl bg-white-100 px-12 pb-24 pt-8">
        <div className="mx-auto min-w-max max-w-7xl">
          {!editorLoading && (
            <div className="mb-1 inline-block self-start">
              <div
                className={`
                  ${saving ? 'bg-gray-400' : 'bg-green-500'}
                  flex
                  items-center
                  rounded
                  px-2
                  py-0.5
                  text-base
                  font-semibold
                  text-white-100
                `}
              >
                {saving ? (
                  <>
                    <IconFilePencil size={16} className="mr-1" /> Saving...
                  </>
                ) : (
                  <>
                    <IconFileCheck size={16} className="mr-1" /> Saved
                  </>
                )}
              </div>
            </div>
          )}

          <div className="border-4 border-gray-50">
            <EmailEditor ref={editorRef} onReady={onEditorReady} options={editorOptions} minHeight={550} />
          </div>
        </div>
      </div>

      <MergeTagBuilderModal
        open={mergeTagBuilderOpen}
        onClose={() => {
          setMergeTagBuilderOpen(false);
        }}
        includeAccountInfo
      />

      <ContentGeneratorModal
        open={contentGeneratorOpen}
        getAIContent={handleGenerateAIContent}
        onClose={() => {
          setContentGeneratorOpen(false);
        }}
        organizationName="{{OrganizationName}}"
        onError={(err) => {
          setError(err);
        }}
      />

      <TestEmailModal
        open={testOpen}
        onCancel={() => {
          setTestOpen(false);
        }}
        exportHtml={exportHtml}
        sendEmail={handleSendTestEmail}
        onError={(err) => {
          setError(err ?? '');
        }}
        onSuccess={() => {
          setSuccess('Test email sent successfully.');
        }}
      />

      <ConfirmExitPageModal open={showPrompt} onCancel={cancelNavigation} onConfirm={confirmNavigation} />
    </div>
  );
};

export default EmailEditorPage;
