import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import times from 'lodash.times';
import { nanoid } from 'nanoid';
import { addWeeks, differenceInDays } from 'date-fns';
import { useShallow } from 'zustand/react/shallow';
import { Button } from 'ui';

import { createPresignedUploadUrl, getCustomerAccount, getTenantImports } from 'core/api';
import { ErrorToast, SuccessToast } from 'components/toasts';
import { getNetworkError } from 'common/utils';
import { ImportJobStatus, type ImportJob } from 'core/types';
import { useCustomersStore } from 'stores/customers';
import { parseCsvFile, uploadImportFile } from 'core/utils';
import { type TenantImportStatus, type TenantImport } from './types';
import { MultipleFileDrop } from './components/multiple-file-drop';
import { Table } from './components/table';

const FILENAME_REGEX = /donorspringupload_(d[1-9]{1})_([0-9]{12})_.*\.csv/;

const getLastImportJobs = (importJobs: ImportJob[]): { lastImportedJob?: ImportJob; lastScheduledJob?: ImportJob } => {
  const sortedJobs = importJobs.sort((a, b) => {
    const aImportTimestamp = new Date(a.importAt || a.createdAt).getTime();
    const bImportTimestamp = new Date(b.importAt || b.createdAt).getTime();
    return bImportTimestamp - aImportTimestamp;
  });
  const lastScheduledJob = sortedJobs.find((job) => job.scheduledStatus === 'pending');
  const lastImportedJob = sortedJobs.find((job) => job.status === ImportJobStatus.DONE);
  return { lastImportedJob, lastScheduledJob };
};

const getImportLists = (
  listsNumber: number,
  totalContacts: number,
  initialImportAt: Date,
): Array<{
  tempId: string;
  contacts: number;
  importAt: Date;
}> => {
  return times(listsNumber, (idx) => {
    let contacts = Math.floor(totalContacts / listsNumber);
    if (idx + 1 === listsNumber) {
      contacts = totalContacts - contacts * idx;
    }
    return {
      tempId: nanoid(),
      contacts,
      importAt: addWeeks(initialImportAt, idx),
    };
  });
};

const Imports = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [tenantImports, setTenantImports] = useState<TenantImport[]>([]);
  const {
    loading: loadingCustomers,
    customers,
    getCustomers,
  } = useCustomersStore(
    useShallow((state) => ({
      loading: state.loading,
      customers: state.customers,
      getCustomers: state.getCustomers,
    })),
  );

  useEffect(() => {
    void getCustomers();

    return () => {
      toast.remove();
    };
  }, [getCustomers]);

  const handleFilesChange = useCallback(
    async (accepted: File[]): Promise<void> => {
      setLoading(true);
      try {
        const tenantIds = accepted.map((file) => file.name.match(FILENAME_REGEX)?.[2]).filter(Boolean) as string[];
        const tenants = customers.filter((customer) => tenantIds.includes(customer.tenantId));
        const results = await Promise.all(
          tenants.map(async ({ tenantId, name }) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const importFile = accepted.find((file) => file.name.includes(tenantId))!;
            const [importJobs, csvFile, customer] = await Promise.all([
              getTenantImports(tenantId),
              parseCsvFile(importFile),
              getCustomerAccount(tenantId),
            ]);
            const { lastImportedJob, lastScheduledJob } = getLastImportJobs(importJobs);
            let initialImportAt = new Date();
            if (lastImportedJob) {
              if (lastScheduledJob) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                initialImportAt = addWeeks(new Date(lastScheduledJob.importAt!), 1);
              } else {
                const lastImportedJobDate = new Date(lastImportedJob.importAt || lastImportedJob.createdAt);
                const daysSinceLastImport = differenceInDays(new Date(), lastImportedJobDate);
                if (daysSinceLastImport <= 7) {
                  initialImportAt = addWeeks(lastImportedJobDate, 1);
                }
              }
            }
            // Split contacts into 4 lists
            const totalContacts = csvFile.length - 1; // Subtract header row
            const importLists = getImportLists(4, totalContacts, initialImportAt);
            return {
              tenantId,
              name,
              lastImportedJob,
              lastScheduledJob,
              importFile,
              importLists,
              totalContacts,
              status: 'pending',
              loading: false,
              subscriptionPlan: customer?.account?.subscription?.planName,
            } satisfies TenantImport;
          }),
        );
        setTenantImports(results);
      } catch (err) {
        toast.custom((t) => <ErrorToast visible={t.visible} message={getNetworkError(err)} />, {
          id: 'handleFilesChangeError',
        });
      }
      setLoading(false);
    },
    [customers],
  );

  const updateTenantImportState = (tenantId: string, loading: boolean, status?: TenantImportStatus): void => {
    setTenantImports((prevState) => {
      return prevState.map((item) => {
        if (item.tenantId === tenantId) {
          return { ...item, loading, status: status || item.status };
        }
        return item;
      });
    });
  };

  const uploadTenantImport = async (tenantImport: TenantImport): Promise<void> => {
    updateTenantImportState(tenantImport.tenantId, true);
    try {
      const uploadUrl = await createPresignedUploadUrl({
        tenantId: tenantImport.tenantId,
        filename: tenantImport.importFile.name,
        importLists: tenantImport.importLists.map((list) => ({
          contacts: list.contacts,
          importAt: list.importAt.toISOString(),
        })),
      });
      const arrayBuffer = await tenantImport.importFile.arrayBuffer();
      await uploadImportFile(uploadUrl, arrayBuffer, tenantImport.importFile.type);
      updateTenantImportState(tenantImport.tenantId, false, 'scheduled');
      toast.custom(
        (t) => (
          <SuccessToast
            visible={t.visible}
            message={`Upload successfully completed!\n${tenantImport.importFile.name}`}
            onClose={() => {
              toast.remove(t.id);
            }}
          />
        ),
        {
          id: `uploadTenantImportSuccess${tenantImport.tenantId}`,
        },
      );
    } catch (err) {
      updateTenantImportState(tenantImport.tenantId, false, 'error');
      toast.custom(
        (t) => (
          <ErrorToast
            visible={t.visible}
            message={`${getNetworkError(err)}\n${tenantImport.importFile.name}`}
            onClose={() => {
              toast.remove(t.id);
            }}
          />
        ),
        {
          id: `uploadTenantImportError${tenantImport.tenantId}`,
        },
      );
    }
  };

  const handleUploads = async (): Promise<void> => {
    setUploading(true);
    for (const tenantImport of tenantImports) {
      await uploadTenantImport(tenantImport);
    }
    setCompleted(true);
    setUploading(false);
  };

  const handleDeleteTenantImport = (tenantId: string): void => {
    setTenantImports((prevState) => prevState.filter((t) => t.tenantId !== tenantId));
  };

  const handleUpdateListsNumber = (tenantId: string, listsNumber: number): void => {
    setTenantImports((prevState) =>
      prevState.map((tI) => {
        if (tI.tenantId === tenantId) {
          const initialImportAt = new Date(tI.importLists[0].importAt);
          const importLists = getImportLists(listsNumber, tI.totalContacts, initialImportAt);
          return { ...tI, importLists };
        }
        return tI;
      }),
    );
  };

  return (
    <div className="w-full space-y-6">
      <h1 className="text-h3">Imports</h1>

      <div className="flex items-center justify-center">
        <MultipleFileDrop
          onChange={handleFilesChange}
          filenameRegex={FILENAME_REGEX}
          filenameErrorMessage={`File name must be in the format "donorspringupload_{PARTNER_ID}_{TENANT_ID}_*.csv"`}
          loading={loading || loadingCustomers}
        />
      </div>

      {tenantImports.length > 0 && (
        <>
          <Table
            data={tenantImports}
            onDelete={handleDeleteTenantImport}
            onUpdateListsNumber={handleUpdateListsNumber}
          />

          {!completed && (
            <div className="flex items-center justify-end">
              <Button
                title="Confirm"
                color="primary"
                onClick={() => {
                  void handleUploads();
                }}
                loading={uploading}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Imports;
