import { type FC, type ReactNode } from 'react';

type ErrorToastProps = {
  visible: boolean;
  message: ReactNode | string;
  onClose?: () => void;
};

const ErrorToast: FC<ErrorToastProps> = ({ visible, message, onClose }) => {
  return (
    <div
      className={`${
        visible ? 'animate-enter' : 'animate-leave'
      } pointer-events-auto flex w-fit max-w-md rounded-lg bg-white-100 shadow-lg ring-1 ring-red-50/5`}
    >
      <div
        className="flex flex-1 rounded-lg bg-red-50 p-4 text-base text-red-700 dark:bg-red-200 dark:text-red-800"
        role="alert"
      >
        <svg
          aria-hidden="true"
          className="mr-3 inline size-5 shrink-0"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clipRule="evenodd"
          ></path>
        </svg>
        <span className="sr-only">Error</span>
        <div>
          <span className="font-medium">Error:</span>
          <div className="mt-1.5 text-red-700">{message}</div>
        </div>

        {onClose && (
          <button
            type="button"
            className="-m-1.5 ml-auto inline-flex size-7 rounded-lg bg-red-50 p-1.5 text-red-500 hover:bg-red-200 focus:ring-2 focus:ring-red-400 dark:bg-red-200 dark:text-red-700 dark:hover:bg-red-300"
            data-dismiss-target="#alert-2"
            aria-label="Close"
            onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <svg className="size-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};

export default ErrorToast;
