import { type SuggestedDraft, type CampaignStatus } from 'common/types';

export type EnvType = 'dev' | 'qa' | 'prod';

// amount is in pennies
type Donations = {
  netAmount: number;
  dsNetAmount: number;
  count: number;
} & Record<string, any>;

type User = {
  email: string;
} & Record<string, any>;

type Contacts = {
  dsCount: number;
  totalCount: number;
  optedInDsCount?: number;
};

export enum CustomerStatus {
  PROCESSING = 'PROCESSING',
  DONE = 'DONE',
  ERROR = 'ERROR',
}

type CustomerError = {
  Cause?: string;
  Error?: string;
};

export type ICustomer = {
  tenantId: string;
  signedUpAt: string;
  lastLoginAt: string;
  status: CustomerStatus;
  email: string;
  name: string;
  donations?: Donations;
  user?: User;
  contacts?: Contacts;
  error?: CustomerError;
  active?: boolean;
  sandbox?: boolean;
};

export enum ImportJobStatus {
  NOT_STARTED = 'NOT_STARTED',
  VALIDATED = 'VALIDATED',
  PROCESSING = 'PROCESSING',
  DONE = 'DONE',
  FAILED = 'FAILED',
}

export type ImportJob = {
  tenantId: string;
  createdAt: string;
  createdContacts: number;
  fieldMappings: Record<string, any>;
  filename: string;
  s3: {
    bucket: string;
    key: string;
  };
  skipUpdate: boolean;
  status: ImportJobStatus;
  totalContacts: number;
  type: string;
  updatedContacts: number;
  ignoredContacts: number;
  errorDetails?: string;
  importAt?: string;
  listNumber?: number;
  startIndex?: number;
  endIndex?: number;
  lastList?: boolean;
  invalidContacts?: number;
  suppressedContacts?: number;
  scheduledStatus?: 'pending';
};

export type CustomerSubscription = {
  status: 'pending' | 'active';
  planName?: string;
  startDate?: number; // in seconds
};

export type CustomerAccount = {
  tenantId: string;
  customerSupportEmail: string;
  legalAddress: string;
  legalName: string;
  organizationName: string;
  taxDocumentKey: string;
  taxId: string;
  websiteUrl: string;
  createdAt: string;
  updatedAt: string;
  donationPageSubdomain?: string;
  subscription?: CustomerSubscription;
  obscureContacts?: boolean;
};

export enum ReportStatus {
  PROCESSING = 'PROCESSING',
  DONE = 'DONE',
  ERROR = 'ERROR',
}

export type IReport = {
  id: string;
  name: string;
  dateRange: string;
  startDate: string;
  endDate: string;
  createdAt: string;
  status: ReportStatus;
};

export type IDateRange = {
  startDate: Date | null;
  endDate: Date | null;
};

export type GenerateReportPayload = {
  startDate: string;
  endDate: string;
};

export enum IMPORT_JOB_ACTIONS {
  DETAILS = 'Details',
  DELETE = 'Delete',
  DOWNLOAD_IMPORT_ERRORS = 'Download Import Errors',
}

export type SelectInputOption = {
  label: string;
  value: string | number;
};

export type Employee = {
  baseCommissionRate: number;
  baseCommissionRateCap: number;
  baseCommissionSalary: number;
  employeeId: string;
  name: string;
  startDate: string;
  tenantIds: string[];
};

export enum InviteStatus {
  pending = 'pending',
  accepted = 'accepted',
  expired = 'expired',
}

export type Invite = {
  createdAt: string;
  email: string;
  inviteId: string;
  name: string;
  tenantId: string;
  status: InviteStatus;
};

export type SuggestedCampaign = {
  tenantId: string;
  campaignId: string;
  sendStatus: CampaignStatus;
  deleted?: boolean;
};

export type SuggestedDraftData = Omit<
  SuggestedDraft,
  'status' | 'customersCount' | 'acceptedCount' | 'errors' | 'createdAt' | 'updatedAt'
>;

export type ImportList = {
  contacts: number;
  importAt: string;
};

export enum SubscriptionPlan {
  ESSENTIAL = 'essential',
  TEAM = 'team',
  FULL_SERVICE = 'full-service',
}

export enum SubscriptionBillingInterval {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  YEARLY = 'yearly',
}

export enum SubscriptionPricingModel {
  SCALING = 'scaling',
  LEGACY = 'legacy',
}

export type ScalingPricingSubscriptionItem = {
  enabled: boolean;
  yearlyBasePrice: number;
  monthlyUsagePrice?: number;
  contactFloor?: string;
};

export enum ScalingPricingSubscriptionItemType {
  FUNDRAISING_ESSENTIALS_SUITE = 'FUNDRAISING_ESSENTIALS_SUITE',
  AUTOMATED_CULTIVATION = 'AUTOMATED_CULTIVATION',
  DONOR_ACQUISITION = 'DONOR_ACQUISITION',
  FUNDRAISING_SUPPORT_SPECIALIST = 'FUNDRAISING_SUPPORT_SPECIALIST',
}
