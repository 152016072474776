import axios from 'axios';

import { SETTINGS } from 'core/constants';
import { type Tenant } from 'pages/customer/types';

const API_URL = `${SETTINGS.apiUrl}/customer-management`;

export const getCustomerAccount = async (tenantId: string): Promise<Tenant> => {
  const response = await axios.get(`${API_URL}/account/${tenantId}`);
  return response.data;
};
