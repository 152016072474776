export const formatDate = (date: string | number, withTime = true, timeZone = 'UTC'): string => {
  const dateObj = new Date(date);
  const day = dateObj.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone,
  });

  if (!withTime) return day;

  const time = dateObj.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  return `${day} at ${time}`;
};

export const getPercentage = (value: number, total: number): string => {
  return `${(Math.min((value || 0) / (total || 1), 1) * 100).toFixed(0)}%`;
};
