import { IconCurrencyDollar } from '@tabler/icons-react';

import { Input, SwitchInput } from 'ui';

type SubscriptionItemConfigProps = {
  label: string;
  id: string;
  disabled?: boolean;
  optional?: boolean;
  item: {
    enabled: boolean;
    yearlyBasePrice: number;
    monthlyUsagePrice?: number;
    contactFloor?: string;
  };
  onChange: (key: string, value: boolean | string | number) => void;
  errors?: any;
};
// Convert integer cents/pennies to a string currency in dollars and cents with no currency symbol
const getCurrencyString = (value: number): string => {
  return (value / 100).toFixed(2);
};

// Convert string back to integer cents/pennies
const getCurrencyNumber = (value: string): number => {
  return Math.round(Number(value) * 100);
};

const SubscriptionItemConfig = ({
  label,
  id,
  item,
  onChange,
  disabled = false,
  optional = true,
  errors,
}: SubscriptionItemConfigProps): JSX.Element => {
  return (
    <div className="grid grid-cols-2 items-start gap-4 rounded-lg border border-gray-100 p-4">
      <SwitchInput
        label={label}
        checked={item.enabled}
        onChange={(checked) => {
          onChange('enabled', checked);
        }}
        disabled={disabled || !optional}
      />

      <Input
        id={`${id}-yearlyBasePrice`}
        label="Base Annual Price"
        placeholder="Base Annual Price"
        type="number"
        step=".01"
        disabled={disabled || !item.enabled}
        value={getCurrencyString(item.yearlyBasePrice)}
        onChange={(e) => {
          onChange('yearlyBasePrice', getCurrencyNumber(e.target.value));
        }}
        required
        error={!!errors?.yearlyBasePrice}
        helperText={errors?.yearlyBasePrice as string}
        size="xl"
        LeftIcon={IconCurrencyDollar}
      />

      {item.contactFloor && (
        <Input
          id={`${id}-contactFloor`}
          label="Contact Floor"
          placeholder="Contact Floor"
          type="number"
          step="1000"
          disabled={disabled || !item.enabled}
          value={item.contactFloor}
          onChange={(e) => {
            onChange('contactFloor', e.target.value);
          }}
          required
          error={!!errors?.contactFloor}
          helperText={errors?.contactFloor as string}
          size="xl"
        />
      )}

      {item.monthlyUsagePrice && (
        <Input
          id={`${id}-monthlyUsagePrice`}
          label="Monthly Cost per 5k Contacts"
          placeholder="Monthly Cost per 5k Contacts"
          type="number"
          step=".01"
          disabled={disabled || !item.enabled}
          value={getCurrencyString(item.monthlyUsagePrice)}
          onChange={(e) => {
            onChange('monthlyUsagePrice', getCurrencyNumber(e.target.value));
          }}
          required
          error={!!errors?.monthlyUsagePrice}
          helperText={errors?.monthlyUsagePrice as string}
          size="xl"
          containerClassName="col-end-3"
          LeftIcon={IconCurrencyDollar}
        />
      )}
    </div>
  );
};

export default SubscriptionItemConfig;
