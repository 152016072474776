/* eslint-disable react/prop-types */
import { type FC, useMemo, useCallback, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
  type ColumnDef,
} from '@tanstack/react-table';
import { nanoid } from 'nanoid';
import toast from 'react-hot-toast';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { getNetworkError } from 'common/utils';
import { Button } from 'ui';

import { type IReport, ReportStatus } from 'core/types';
import { formatDate, mapReportStatusToLabel } from 'core/utils';
import { Loader } from 'components/loader';
import { CustomPagination } from 'components/table';
import { ErrorToast } from 'components/toasts';
import { SETTINGS } from 'core/constants';

const API_URL = `${SETTINGS.apiUrl}/user-reports`;

const createPresignedDownloadUrl = async (documentKey: string): Promise<{ url: string }> => {
  const response = await axios.post(`${API_URL}/download`, { documentKey });
  return response.data;
};

type TableProps = {
  data: IReport[];
};

const Table: FC<TableProps> = ({ data }) => {
  const [downloadingReport, setDownloadingReport] = useState(false);

  const handleDownloadReport = useCallback(async (documentName: string) => {
    setDownloadingReport(true);
    try {
      const { url: downloadUrl } = await createPresignedDownloadUrl(documentName);
      const res = await axios.get(downloadUrl, { responseType: 'blob', headers: { Authorization: '' } });
      fileDownload(res.data as Blob, documentName);
    } catch (err) {
      toast.custom((t) => <ErrorToast visible={t.visible} message={getNetworkError(err)} />, {
        id: 'handleDownloadDocumentError',
      });
    }
    setDownloadingReport(false);
  }, []);

  const columns = useMemo<Array<ColumnDef<IReport>>>(
    () => [
      {
        id: nanoid(),
        accessorKey: 'name',
        header: () => 'Name',
        footer: (props: any) => props.column.id,
      },
      {
        id: nanoid(),
        accessorKey: 'status',
        header: () => 'Status',
        footer: (props: any) => props.column.id,
        cell: (info: any) => {
          const status = info.getValue() as ReportStatus;
          if (status === ReportStatus.PROCESSING) {
            return (
              <div className="relative">
                <Loader />
              </div>
            );
          }
          let className = 'font-medium';
          if (status === ReportStatus.DONE) className += ' text-primary-700';
          if (status === ReportStatus.ERROR) className += ' text-red-600';
          return <span className={className}>{mapReportStatusToLabel[status] ?? ''}</span>;
        },
      },
      {
        id: nanoid(),
        accessorKey: 'createdAt',
        header: () => 'Date',
        footer: (props: any) => props.column.id,
        cell: (info: any) => (info.getValue() ? formatDate(info.getValue() * 1000) : ''),
      },
      {
        id: nanoid(),
        accessorKey: 'dateRange',
        header: () => 'Date Range',
        footer: (props: any) => props.column.id,
        cell: (info: any) =>
          `${formatDate(info.row.original.startDate as string, false, 'UTC')} - ${formatDate(
            info.row.original.endDate as string,
            false,
            'UTC',
          )}`,
      },
      {
        id: nanoid(),
        accessorKey: 'name',
        header: () => '',
        footer: (props: any) => props.column.id,
        cell: (info: any) => (
          <Button
            title="Download"
            color="primary"
            onClick={() => {
              void handleDownloadReport(info.getValue() as string);
            }}
            loading={downloadingReport}
          />
        ),
      },
    ],
    [handleDownloadReport, downloadingReport],
  );

  const table = useReactTable({
    data,
    columns,
    defaultColumn: {
      // @ts-expect-error string values also work
      size: 'fit-content',
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const emptyRows = 5 - data.length;

  return (
    <div className="space-y-6">
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full bg-white-100 text-left text-base text-gray-950 dark:text-gray-400">
          <thead className="text-sm uppercase text-gray-400 dark:bg-gray-700 dark:text-gray-400">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className="px-4 py-3"
                      style={{ width: header.column.columnDef.size, minWidth: header.column.columnDef.minSize }}
                    >
                      {header.isPlaceholder ? null : (
                        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, index) => {
              const customerStatus = row.original.status;
              return (
                <tr
                  key={row.id}
                  className={`
                    h-[52px]
                    border-b
                    border-gray-50
                    hover:bg-gray-10
                    dark:border-gray-700
                    dark:bg-gray-800
                    dark:hover:bg-gray-600
                    ${customerStatus === ReportStatus.PROCESSING ? 'pointer-events-none' : ''}
                  `}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className="px-4 py-2"
                        style={{ width: cell.column.columnDef.size, minWidth: cell.column.columnDef.minSize }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {emptyRows > 0 &&
              Array(emptyRows)
                .fill('')
                .map((row, index) => (
                  <tr key={`emptyRow-${index}`} className={`h-[52px] border-b border-gray-50 hover:bg-gray-10`}>
                    <td className="px-4 py-2" colSpan={15} style={{ minWidth: 110 }}></td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>

      <div className="flex items-center justify-between gap-2">
        <div>
          Showing{' '}
          <strong>
            {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1}-
            {Math.min(table.getState().pagination.pageSize * (table.getState().pagination.pageIndex + 1), data.length)}
          </strong>{' '}
          of <strong>{data.length}</strong>
        </div>

        <CustomPagination<IReport> table={table} />
      </div>
    </div>
  );
};

export default Table;
