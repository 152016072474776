import { customAlphabet } from 'nanoid';
import { type SortOrder } from 'common/types';

import { CustomerStatus, ReportStatus } from 'core/types';

export * from './auth';
export * from './http';
export * from './format';
export * from './csv';

export const noop = (): void => {};

export const waitFor = async (timeMs: number): Promise<void> => {
  await new Promise<void>((resolve) =>
    setTimeout(() => {
      resolve();
    }, timeMs),
  );
};

export const removeFalsyValues = (obj: Record<string, any>): Record<string, any> => {
  return Object.keys(obj).reduce<Record<string, any>>((acc, val) => {
    if (obj[val]) acc[val] = obj[val];
    return acc;
  }, {});
};

export const sortData = <T extends Record<string, any>>(data: T[], sortKey: string, order: SortOrder = 'desc'): T[] => {
  return [...data].sort((a, b) => {
    const aVal = a[sortKey] ?? 0;
    const bVal = b[sortKey] ?? 0;
    const descendingOrder = order === 'desc';
    if (aVal < bVal) {
      return descendingOrder ? 1 : -1;
    }
    if (aVal > bVal) {
      return descendingOrder ? -1 : 1;
    }
    return 0;
  });
};

export const mapCustomerStatusToLabel: Record<CustomerStatus, string> = {
  [CustomerStatus.PROCESSING]: 'Processing',
  [CustomerStatus.DONE]: 'Active',
  [CustomerStatus.ERROR]: 'Error',
};

export const mapReportStatusToLabel: Record<ReportStatus, string> = {
  [ReportStatus.PROCESSING]: 'Generating',
  [ReportStatus.DONE]: 'Completed',
  [ReportStatus.ERROR]: 'Error',
};

export const customNanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 7);

export const isDraftForSingleCustomer = (tenantIds: string[]): boolean => {
  return tenantIds.length === 1 && tenantIds[0] !== 'all';
};
