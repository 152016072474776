import { useEffect, useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';
import debounce from 'lodash.debounce';
import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';
import { DatePicker, TimePicker, HelperText, FormCard } from 'ui';

import { useSuggestedDraftsStore } from 'stores/suggested-drafts';

type TimeFormProps = {
  open: boolean;
  onClick: () => void;
  disabled?: boolean;
};

type FormValues = {
  sendTime: Date;
};

const DEBOUNCE_DELAY_TIME_MS = 500;

const getInitialValues = (sendTime: string): Date => {
  if (sendTime) {
    return new Date(sendTime);
  }
  const defaultDate = new Date();
  defaultDate.setHours(defaultDate.getHours() + 1);
  return defaultDate;
};

export const formValidation = yup.object({
  sendTime: yup
    .date()
    .required('Send time is required')
    .test('is-future-date', 'Send time must be a date and time at least 15 minutes into the future', (value) =>
      value ? value.getTime() > Date.now() + 1000 * 60 * 15 : false,
    ),
});

const TimeForm = (props: TimeFormProps): JSX.Element => {
  const { open, onClick, disabled = false } = props;
  const { sendTime, updateDraftDetails } = useSuggestedDraftsStore(
    useShallow((state) => ({ sendTime: state.draftDetails.sendTime, updateDraftDetails: state.updateDraftDetails })),
  );
  const form = useForm<FormValues>({
    initialValues: { sendTime: getInitialValues(sendTime) },
    validate: yupResolver(formValidation),
    validateInputOnChange: true,
  });

  useEffect(() => {
    if (disabled) return;
    if (form.values.sendTime) {
      // Update initial value
      updateDraftDetails({ sendTime: form.values.sendTime.toISOString() });
    }
    form.validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  const debouncedUpdateDraftDetails = useMemo(
    () => debounce(updateDraftDetails, DEBOUNCE_DELAY_TIME_MS),
    [updateDraftDetails],
  );

  const handleChange = (date: Date): void => {
    form.setFieldValue('sendTime', date);
    debouncedUpdateDraftDetails({ sendTime: date.toISOString() });
  };

  return (
    <FormCard
      title="Send Time"
      subtitle="When should the email be sent?"
      isExpanded={open}
      handleClick={onClick}
      checked={form.isValid()}
    >
      <div className="flex items-center space-x-3">
        <DatePicker value={form.values.sendTime} onChange={handleChange} required disabled={disabled} />

        <TimePicker value={form.values.sendTime} onChange={handleChange} required disabled={disabled} />
      </div>

      {!!form.errors.sendTime && (
        <HelperText message={form.errors.sendTime as string} error containerClassName="mt-1.5" />
      )}
    </FormCard>
  );
};

export default TimeForm;
