// import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-datepicker/dist/react-datepicker.css';

import './index.css';
import App from './App';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
  // NOTE: Makes email editor to be rendered twice
  // Check this - https://github.com/unlayer/react-email-editor/issues/241
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
);
