import { useNavigate } from 'react-router-dom';
import { type SuggestedDraft } from 'common/types';
import { CampaignDrawer } from 'ui';

import { getTemplate } from 'core/api';
import { isDraftForSingleCustomer } from 'core/utils';
import { useCustomerTags, useCustomerSegments } from 'pages/create-suggested-draft/components/hooks';
import SentCampaigns from './SentCampaigns';

type DrawerProps = {
  selectedDraft?: SuggestedDraft;
};

const Drawer = (props: DrawerProps): JSX.Element => {
  const { selectedDraft } = props;
  const navigate = useNavigate();

  const isSingleCustomer = isDraftForSingleCustomer(selectedDraft?.tenantIds ?? []);

  const { tags, loading: loadingTags } = useCustomerTags(isSingleCustomer ? selectedDraft?.tenantIds[0] : undefined);
  const { segments, loading: loadingSegments } = useCustomerSegments(
    isSingleCustomer ? selectedDraft?.tenantIds[0] : undefined,
  );

  const withAudience =
    isSingleCustomer &&
    !!selectedDraft &&
    (selectedDraft.sendTo.all || selectedDraft.sendTo.tagIds.length > 0 || selectedDraft.sendTo.segmentIds.length > 0);

  const withSender =
    isSingleCustomer &&
    !!selectedDraft &&
    !!selectedDraft.emailConfig.senderName &&
    !!selectedDraft.emailConfig.senderEmail;

  return (
    <CampaignDrawer
      open={!!selectedDraft}
      onClose={() => {
        navigate('/suggested-drafts');
      }}
      campaignDetails={selectedDraft}
      getTemplate={getTemplate}
      withAudience={withAudience}
      withSender={withSender}
      tags={tags}
      segments={segments}
      loadingAudience={loadingTags || loadingSegments}
    >
      {!!selectedDraft && (
        <SentCampaigns
          draftId={selectedDraft.draftId}
          tenantIds={selectedDraft.tenantIds}
          status={selectedDraft.status}
        />
      )}
    </CampaignDrawer>
  );
};

export default Drawer;
