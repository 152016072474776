import { type ChangeEventHandler, useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';
import debounce from 'lodash.debounce';
import { FormCard, Input } from 'ui';

import { useSuggestedDraftsStore } from 'stores/suggested-drafts';

type ExpirationFormProps = {
  open: boolean;
  onClick: () => void;
  disabled?: boolean;
};

type FormValues = {
  expirationDays: number;
};

const DEBOUNCE_DELAY_TIME_MS = 500;

export const formValidation = yup.object({
  expirationDays: yup.number().required('Expiration days is required.').min(1, 'Invalid expiration days.'),
});

const ExpirationForm = (props: ExpirationFormProps): JSX.Element => {
  const { open, onClick, disabled = false } = props;
  const { expirationDays, updateDraftDetails } = useSuggestedDraftsStore(
    useShallow((state) => ({
      expirationDays: state.draftDetails.expirationDays,
      updateDraftDetails: state.updateDraftDetails,
    })),
  );
  const form = useForm<FormValues>({
    initialValues: { expirationDays },
    validate: yupResolver(formValidation),
    validateInputOnChange: true,
  });

  const debouncedUpdate = useMemo(() => debounce(updateDraftDetails, DEBOUNCE_DELAY_TIME_MS), [updateDraftDetails]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = parseInt(e.target.value) || 0;
    form.setFieldValue('expirationDays', value);
    debouncedUpdate({ expirationDays: value });
  };

  return (
    <FormCard
      title="Expiration"
      subtitle="How long should this draft be available before disappearing?"
      isExpanded={open}
      handleClick={onClick}
      checked={form.isValid()}
    >
      <Input
        id="expiration-days"
        type="number"
        label="Days"
        required
        value={form.values.expirationDays.toString()}
        onChange={handleChange}
        size="xl"
        containerClassName="w-[160px]"
        error={!!form.errors.expirationDays}
        helperText={(form.errors.expirationDays as string) || ''}
        disabled={disabled}
      />
    </FormCard>
  );
};

export default ExpirationForm;
