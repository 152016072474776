/* eslint-disable react/prop-types */
import { type FC, useCallback, useMemo, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
  type ColumnDef,
} from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { getNetworkError, parseDonationAmount } from 'common/utils';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { ArrowTopRightOnSquareIcon, PencilIcon } from '@heroicons/react/24/solid';
import { IconButton } from 'ui';

import { formatDate, getPercentage, mapCustomerStatusToLabel } from 'core/utils';
import { CustomPagination } from 'components/table';
import { CustomerStatus, type ICustomer } from 'core/types';
import { Loader } from 'components/loader';
import { SETTINGS } from 'core/constants';
import { ErrorToast } from 'components/toasts';

type TableProps = {
  data: ICustomer[];
};

const API_URL = `${SETTINGS.apiUrl}/customer-management`;

const Table: FC<TableProps> = ({ data }) => {
  const navigate = useNavigate();
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const handleLogin = useCallback(async (tenantId: string): Promise<void> => {
    setIsLoggingIn(true);
    try {
      await axios.post(`${API_URL}/login-as-tenant-id`, { tenantId });
      window.open(SETTINGS.customerAppUrl, '_blank');
    } catch (err) {
      toast.custom((t) => <ErrorToast visible={t.visible} message={getNetworkError(err)} />, {
        id: 'loginAsTenantIdError',
      });
    } finally {
      setIsLoggingIn(false);
    }
  }, []);

  const columns = useMemo<Array<ColumnDef<ICustomer>>>(
    () => [
      {
        id: nanoid(),
        accessorKey: 'tenantId',
        header: () => '#',
        footer: (props: any) => props.column.id,
        minSize: 140,
      },
      {
        id: nanoid(),
        accessorKey: 'name',
        header: () => 'Customer',
        footer: (props: any) => props.column.id,
        cell: (info: any) => info.getValue() ?? info.row.original?.email ?? info.row.original?.user?.email ?? '',
        minSize: 160,
      },
      {
        id: nanoid(),
        accessorKey: 'status',
        header: () => 'Status',
        footer: (props: any) => props.column.id,
        cell: (info: any) => {
          const status = info.getValue() as CustomerStatus;
          if (status === CustomerStatus.PROCESSING) {
            return (
              <div className="relative">
                <Loader />
              </div>
            );
          }
          if (typeof info.row.original?.active !== 'undefined' && !info.row.original?.active) {
            return <span className="font-medium text-gray-950">Inactive</span>;
          }
          let className = 'font-medium';
          if (status === CustomerStatus.DONE) className += ' text-primary-700';
          if (status === CustomerStatus.ERROR) className += ' text-red-600';
          return <span className={className}>{mapCustomerStatusToLabel[status] ?? ''}</span>;
        },
        minSize: 90,
      },
      {
        id: nanoid(),
        accessorKey: 'sandbox',
        header: () => 'Sandbox',
        footer: (props: any) => props.column.id,
        cell: (info: any) => (info.getValue() ? 'Yes' : 'No'),
        minSize: 90,
      },
      {
        id: nanoid(),
        accessorKey: 'contacts',
        header: () => '# of Contacts',
        footer: (props: any) => props.column.id,
        cell: (info: any) => info.getValue()?.totalCount ?? 0,
        minSize: 135,
      },
      {
        id: nanoid(),
        accessorKey: 'contacts',
        header: () => '# of DS Contacts',
        footer: (props: any) => props.column.id,
        cell: (info: any) => info.getValue()?.dsCount ?? 0,
        minSize: 155,
      },
      {
        id: nanoid(),
        accessorKey: 'contacts',
        header: () => '% DS',
        footer: (props: any) => props.column.id,
        cell: (info: any) => {
          const contacts = info.getValue();
          if (contacts?.totalCount && contacts?.dsCount) {
            return getPercentage(contacts.dsCount as number, contacts.totalCount as number);
          }
          return '0%';
        },
        minSize: 80,
      },
      {
        id: nanoid(),
        accessorKey: 'contacts',
        header: () => '# of Opted-in DS contacts',
        footer: (props: any) => props.column.id,
        cell: (info: any) => {
          const contacts = info.getValue();
          return contacts?.optedInDsCount || 0;
        },
        minSize: 215,
      },
      {
        id: nanoid(),
        accessorKey: 'contacts',
        header: () => '% opted-in DS',
        footer: (props: any) => props.column.id,
        cell: (info: any) => {
          const contacts = info.getValue();
          if (contacts?.dsCount && contacts?.optedInDsCount) {
            return getPercentage(contacts.optedInDsCount as number, contacts.dsCount as number);
          }
          return '0%';
        },
        minSize: 125,
      },
      {
        id: nanoid(),
        accessorKey: 'donations',
        header: () => 'Total Raised',
        footer: (props: any) => props.column.id,
        cell: (info: any) => parseDonationAmount((info.getValue()?.netAmount || 0) / 100), // Amount is in penies
        minSize: 125,
      },
      {
        id: nanoid(),
        accessorKey: 'donations',
        header: () => 'Total Raised Via DS',
        footer: (props: any) => props.column.id,
        cell: (info: any) => parseDonationAmount((info.getValue()?.dsNetAmount || 0) / 100), // Amount is in penies
        minSize: 165,
      },
      {
        id: nanoid(),
        accessorKey: 'signedUpAt',
        header: () => 'Sign Up',
        footer: (props: any) => props.column.id,
        cell: (info: any) => (info.getValue() ? formatDate(info.getValue() * 1000) : ''),
        minSize: 210,
      },
      {
        id: nanoid(),
        accessorKey: 'lastLoginAt',
        header: () => 'Last Login',
        footer: (props: any) => props.column.id,
        cell: (info: any) => (info.getValue() ? formatDate(info.getValue() * 1000) : ''),
        minSize: 210,
      },
      {
        id: nanoid(),
        accessorKey: 'updatedAt', // Value must not be empty
        header: () => '',
        footer: (props: any) => props.column.id,
        cell: (info: any) => (
          <>
            <IconButton
              Icon={<PencilIcon className="size-4 stroke-2" />}
              className="text-gray-950"
              onClick={() => {
                navigate(`/customer/${info.row.original.tenantId}`, { state: { customer: info.row.original } });
              }}
              color="transparent"
              srOnly="Manage"
              title="Manage"
            />
            <IconButton
              Icon={<ArrowTopRightOnSquareIcon className="size-4 stroke-2" />}
              className="text-gray-950"
              onClick={() => {
                void handleLogin(info.row.original?.tenantId as string);
              }}
              color="transparent"
              srOnly="Log In"
              title="Log In"
            />
          </>
        ),
        minSize: 100,
      },
    ],
    [navigate, handleLogin],
  );

  const table = useReactTable({
    data,
    columns,
    defaultColumn: {
      // @ts-expect-error string values also work
      size: 'fit-content',
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const emptyRows = 5 - data.length;

  return (
    <div className="space-y-6">
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        {isLoggingIn && <Loader className="z-10" />}
        <table className="w-full bg-white-100 text-left text-base text-gray-950 dark:text-gray-400">
          <thead className="text-sm uppercase text-gray-400 dark:bg-gray-700 dark:text-gray-400">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="bg-white-100">
                {headerGroup.headers.map((header, index) => {
                  const stickyCol = index === headerGroup.headers.length - 1;
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={`bg-inherit px-4 py-3 ${stickyCol ? 'sticky right-0' : ''}`}
                      style={{ width: header.column.columnDef.size, minWidth: header.column.columnDef.minSize }}
                    >
                      {header.isPlaceholder ? null : (
                        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, index) => {
              const customerStatus = row.original.status;
              return (
                <tr
                  key={row.id}
                  className={`
                    h-[52px] 
                    border-b
                    border-gray-50
                    bg-white-100
                    hover:bg-gray-10
                    ${customerStatus === CustomerStatus.PROCESSING ? 'pointer-events-none' : ''}
                  `}
                >
                  {row.getVisibleCells().map((cell, index) => {
                    const stickyCol = index === row.getVisibleCells().length - 1;
                    return (
                      <td
                        key={cell.id}
                        className={`bg-inherit px-4 py-2 ${stickyCol ? 'sticky right-0' : ''}`}
                        style={{ width: cell.column.columnDef.size, minWidth: cell.column.columnDef.minSize }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {emptyRows > 0 &&
              Array(emptyRows)
                .fill('')
                .map((row, index) => (
                  <tr key={`emptyRow-${index}`} className={`h-[52px] border-b border-gray-50 hover:bg-gray-10`}>
                    <td className="px-4 py-2" colSpan={15} style={{ minWidth: 110 }}></td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>

      <div className="flex items-center justify-between gap-2">
        <div>
          Showing{' '}
          <strong>
            {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1}-
            {Math.min(table.getState().pagination.pageSize * (table.getState().pagination.pageIndex + 1), data.length)}
          </strong>{' '}
          of <strong>{data.length}</strong>
        </div>

        <CustomPagination<ICustomer> table={table} />
      </div>
    </div>
  );
};

export default Table;
