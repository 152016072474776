import { type FC, Fragment, useState, useMemo } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { nanoid } from 'nanoid';
import DatePicker from 'react-datepicker';
import { useForm, yupResolver } from '@mantine/form';
import times from 'lodash.times';
import addWeeks from 'date-fns/addWeeks';
import * as yup from 'yup';
import { getNetworkError } from 'common/utils';
import { Button } from 'ui';

import { ErrorToast, SuccessToast } from 'components/toasts';
import { CounterInput, Input } from 'components/inputs';
import { noop, parseCsvFile, uploadImportFile } from 'core/utils';
import { type CustomerSubscription, type ICustomer } from 'core/types';
import { createPresignedUploadUrl } from 'core/api';
import { FileDrop } from '../file-drop';

type ImportDetails = {
  totalContacts: number;
  lists: Array<{
    tempId: string;
    contacts: number;
    importAt: Date;
  }>;
};

type UploadContactsModalProps = {
  open: boolean;
  onClose: () => void;
  refetch: () => Promise<void>;
  customerSubscription?: CustomerSubscription;
};

type LocationState = {
  customer: ICustomer;
};

const formValidation = yup.object({
  lists: yup.array().of(yup.object({ contacts: yup.number().min(1, 'Invalid number.') })),
});

const UploadContactsModal: FC<UploadContactsModalProps> = ({ open, onClose, refetch, customerSubscription }) => {
  const [loading, setLoading] = useState(false);
  const [importFile, setImportFile] = useState<File | null>(null);
  const location = useLocation();
  const form = useForm<ImportDetails>({
    initialValues: { totalContacts: 0, lists: [] },
    validate: yupResolver(formValidation),
    validateInputOnChange: true,
  });

  const customer = (location.state as LocationState | undefined)?.customer;

  const handleFileChange = async (file: File): Promise<void> => {
    const data = await parseCsvFile(file);
    setImportFile(file);
    const contacts = data.length - 1; // Subtract header row
    form.setValues({
      totalContacts: contacts,
      lists: [{ tempId: nanoid(), contacts, importAt: new Date() }],
    });
  };

  const handleClose = (): void => {
    form.reset();
    setImportFile(null);
    onClose();
  };

  const handleCounterChange = (value: number): void => {
    const listsNumber = Math.max(Math.min(value, form.values.totalContacts), 1);

    const lists = times(listsNumber, (num) => {
      let contacts = Math.floor(form.values.totalContacts / listsNumber);
      if (num + 1 === listsNumber) {
        contacts = form.values.totalContacts - contacts * num;
      }
      return {
        tempId: nanoid(),
        contacts,
        importAt: addWeeks(new Date(), num),
      };
    });

    form.setValues({ lists });
  };

  const handleUpload = async (): Promise<void> => {
    if (!importFile || !customer?.tenantId) return;
    setLoading(true);
    try {
      const uploadUrl = await createPresignedUploadUrl({
        tenantId: customer.tenantId,
        filename: importFile.name,
        importLists: form.values.lists.map((list) => ({
          contacts: list.contacts,
          importAt: list.importAt.toISOString(),
        })),
      });
      const arrayBuffer = await importFile.arrayBuffer();
      await uploadImportFile(uploadUrl, arrayBuffer, importFile.type);
      await refetch();
      toast.custom((t) => <SuccessToast visible={t.visible} message="File uploaded successfully!" />, {
        id: 'handleUploadSuccess',
      });
      handleClose();
    } catch (err) {
      toast.custom((t) => <ErrorToast visible={t.visible} message={getNetworkError(err)} />, {
        id: 'handleUploadError',
      });
    }
    setLoading(false);
  };

  const listsTotalContactsError = useMemo(() => {
    const listsTotalContacts = form.values.lists.reduce((totalContacts, list) => {
      totalContacts += list.contacts;
      return totalContacts;
    }, 0);
    return listsTotalContacts !== form.values.totalContacts;
  }, [form.values.lists, form.values.totalContacts]);

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={loading ? noop : handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-950/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-screen">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative w-full overflow-hidden bg-white-100 text-left align-middle transition-all">
                <div className="border-slate-50 flex items-center justify-between border-b px-6 py-4">
                  <Dialog.Title as="h2" className="text-center text-2xl font-bold leading-6 text-gray-900">
                    Upload Contacts
                  </Dialog.Title>

                  <Button title="Cancel" onClick={handleClose} disabled={loading} />
                </div>

                <div className="h-full p-6">
                  {customerSubscription?.status === 'active' && (
                    <h3 className="text-lg">Subscription Plan: {customerSubscription.planName}</h3>
                  )}

                  <FileDrop
                    loading={loading}
                    onChange={(file) => {
                      void handleFileChange(file);
                    }}
                  />

                  {form.values.totalContacts > 0 && (
                    <form noValidate className="w-fit">
                      <p className="mb-3 text-base font-medium text-gray-900 dark:text-gray-300">
                        {form.values.totalContacts.toLocaleString()} contacts to upload
                      </p>

                      <div className="w-fit">
                        <p className="mb-3 text-base font-medium text-gray-900 dark:text-gray-300">
                          How many list do you want to create?
                        </p>
                        <CounterInput
                          id="lists-number"
                          containerClassName="mb-0 mr-2"
                          value={form.values.lists.length}
                          onChange={handleCounterChange}
                        />
                      </div>

                      <ul>
                        {form.values.lists.map((list, idx) => (
                          <li key={list.tempId} className="mt-4">
                            <p className="mb-1 text-base text-gray-900 dark:text-gray-300">List {idx + 1}</p>
                            <div className="flex space-x-3">
                              <div>
                                <p className="mb-1 text-sm font-medium text-gray-900 dark:text-gray-300">Contacts</p>
                                <Input
                                  id={`${list.tempId}-contacts`}
                                  type="number"
                                  containerClassName="!mb-0"
                                  value={list.contacts.toString()}
                                  onChange={(e) => {
                                    form.setFieldValue(`lists.${idx}.contacts`, Number(e.target.value));
                                  }}
                                  error={form.errors[`lists.${idx}.contacts`] as string | undefined}
                                />
                              </div>
                              <div>
                                <p className="mb-1 text-sm font-medium text-gray-900 dark:text-gray-300">Import at</p>
                                <DatePicker
                                  className={`dark:shadow-sm-light
                                  block
                                  w-fit
                                  rounded-lg
                                  border
                                  border-gray-100
                                  p-2.5
                                  text-base
                                  text-gray-900
                                  shadow-sm
                                  focus:border-primary-500
                                  focus:ring-primary-500
                                  dark:border-gray-600
                                  dark:bg-gray-700
                                  dark:text-white-100
                                  dark:placeholder:text-gray-400
                                  dark:focus:border-primary-500`}
                                  selected={list.importAt}
                                  onChange={(date) => {
                                    form.setFieldValue(`lists.${idx}.importAt`, date);
                                  }}
                                  minDate={new Date()}
                                />
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </form>
                  )}

                  {listsTotalContactsError && (
                    <p className="mt-4 text-sm italic text-red-500">
                      Lists total contacts&nbsp;
                      <span className="font-semibold">
                        ({form.values.lists.reduce((acc, list) => (acc += list.contacts), 0).toLocaleString()})
                      </span>
                      &nbsp;do not match file contacts to upload.
                    </p>
                  )}

                  <Button
                    title="Confirm"
                    color="primary"
                    onClick={() => {
                      void handleUpload();
                    }}
                    disabled={loading || listsTotalContactsError}
                    className="my-8"
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default UploadContactsModal;
