import { useEffect, useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { FormCard, Loader, MultiSelectInput, type MultiSelectOption } from 'ui';

import { useSuggestedDraftsStore } from 'stores/suggested-drafts';
import { useCustomersStore } from 'stores/customers/store';

type CustomersFormProps = {
  open: boolean;
  onClick: () => void;
  disabled?: boolean;
};

const CustomersForm = (props: CustomersFormProps): JSX.Element => {
  const { open, onClick, disabled = false } = props;
  const { tenantIds, excludedTenantIds, updateDraftDetails, resetSingleCustomerDetails } = useSuggestedDraftsStore(
    useShallow((state) => ({
      tenantIds: state.draftDetails.tenantIds,
      excludedTenantIds: state.draftDetails.excludedTenantIds,
      updateDraftDetails: state.updateDraftDetails,
      resetSingleCustomerDetails: state.resetSingleCustomerDetails,
    })),
  );
  const { loading, activeCustomers, getCustomers } = useCustomersStore(
    useShallow((state) => ({
      loading: state.loading,
      activeCustomers: state.activeCustomers,
      getCustomers: state.getCustomers,
    })),
  );

  useEffect(() => {
    void getCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (values: string[]): void => {
    let ids = values;
    if (ids.slice(-1)[0] === 'all') {
      ids = ['all'];
    } else if (ids.includes('all')) {
      ids = ids.filter((id) => id !== 'all');
    }
    updateDraftDetails({ tenantIds: ids });
    if (ids.length > 1 || ids.includes('all')) {
      resetSingleCustomerDetails();
      updateDraftDetails({ excludedTenantIds: [] });
    }
  };

  const sortedOptions = useMemo(() => {
    return activeCustomers
      .map(
        (customer): MultiSelectOption => ({
          id: customer.tenantId,
          name: customer.name || customer.email || customer.user?.email || '',
          type: 'customer',
        }),
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [activeCustomers]);

  const customerSections = useMemo(() => {
    const sections = [];
    if (sortedOptions.length > 0) {
      sections.push({
        title: 'Customers',
        options: sortedOptions,
        type: 'customer',
      });
    }
    sections.push({ title: 'All', options: [{ id: 'all', name: 'All Customers', type: 'all' }] });
    return sections;
  }, [sortedOptions]);

  return (
    <FormCard
      title="Customers"
      subtitle="Which customers should get this campaign?"
      isExpanded={open}
      handleClick={onClick}
      checked={tenantIds.length > 0}
    >
      <div className="relative space-y-6">
        <MultiSelectInput
          id="select-customers"
          label="Select Customers"
          value={tenantIds}
          onChange={handleChange}
          sections={customerSections}
          required
          disabled={disabled}
        />

        {tenantIds[0] === 'all' && (
          <MultiSelectInput
            id="exclude-customers"
            label="Exclude Customers"
            value={excludedTenantIds}
            onChange={(values) => {
              updateDraftDetails({ excludedTenantIds: values });
            }}
            options={sortedOptions}
            disabled={disabled}
          />
        )}
        {loading && <Loader />}
      </div>
    </FormCard>
  );
};

export default CustomersForm;
