import { type FC } from 'react';
import { ArrowPathIcon, CheckCircleIcon, XCircleIcon, ArrowUpCircleIcon } from '@heroicons/react/24/solid';

import { type ImportJob, ImportJobStatus } from 'core/types';
import { formatDate } from 'core/utils';

const getJobIcon = (status: ImportJobStatus): JSX.Element | null => {
  switch (status) {
    case ImportJobStatus.NOT_STARTED:
    case ImportJobStatus.VALIDATED:
      return <ArrowUpCircleIcon className="mr-4 size-7 text-gray-500" />;
    case ImportJobStatus.PROCESSING:
      return <ArrowPathIcon className="text-yellow-400 mr-4 size-7" />;
    case ImportJobStatus.DONE:
      return <CheckCircleIcon className="mr-4 size-7 text-green-400" />;
    case ImportJobStatus.FAILED:
      return <XCircleIcon className="mr-4 size-7 text-red-500" />;
    default:
      return null;
  }
};

type ImportInfoProps = {
  info: ImportJob;
};

const ImportInfo: FC<ImportInfoProps> = ({
  info: {
    filename,
    status,
    importAt,
    listNumber,
    startIndex = 0,
    endIndex = 0,
    invalidContacts,
    suppressedContacts,
    createdContacts,
    ignoredContacts,
    totalContacts,
  },
}) => {
  // Some import jobs do not have this info, eg. FAILED jobs
  const ignoredContactsPercentage =
    !isNaN(ignoredContacts) && !isNaN(totalContacts) ? Math.round((ignoredContacts / totalContacts) * 100) : 0;

  return (
    <div className="flex items-center">
      {getJobIcon(status)}
      <div className="flex-1">
        <p className="text-lg font-bold text-gray-950 dark:text-white-100">{filename}</p>
        {!!importAt && (
          <p className="text-sm font-bold text-gray-950 dark:text-white-100">
            Import at: {formatDate(importAt, false)}
          </p>
        )}
        {!!listNumber && (
          <p className="text-sm text-gray-950 dark:text-white-100">
            <span className="font-medium">List {listNumber}</span> - {endIndex - startIndex + 1} contacts, from contact{' '}
            {startIndex + 1} to contact {endIndex + 1}
          </p>
        )}
        {!!createdContacts && (
          <p className="text-[11px] leading-tight text-gray-950 dark:text-white-100">
            <span className="font-medium">{createdContacts} created contacts</span>
          </p>
        )}
        {!!ignoredContacts && (
          <>
            <p className="text-[11px] leading-tight text-gray-950 dark:text-white-100">
              <span className="font-medium">{ignoredContacts} ignored contacts</span>
            </p>

            <p
              className={`text-[11px] leading-tight dark:text-white-100 ${ignoredContactsPercentage > 5 ? 'text-red-500' : 'text-gray-950'}`}
            >
              <span className="font-medium">{ignoredContactsPercentage}% ignored contacts</span>
            </p>
          </>
        )}
        {!!invalidContacts && (
          <p className="text-[11px] leading-tight text-gray-950 dark:text-white-100">
            {invalidContacts} invalid contacts
          </p>
        )}
        {!!suppressedContacts && (
          <p className="text-[11px] leading-tight text-gray-950 dark:text-white-100">
            {suppressedContacts} suppressed contacts
          </p>
        )}
      </div>
    </div>
  );
};

export default ImportInfo;
