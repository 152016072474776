import { useCallback, useEffect, useState } from 'react';
import { SETTINGS } from 'core/constants';
import axios from 'axios';
import { getNetworkError } from 'common/utils';

type UseCustomerDomain = {
  loading: boolean;
  error: string;
  domain: string;
  donationPageSubdomain: string;
};

type CustomerDomain = {
  id: number;
  domain: string;
  valid: boolean;
  tenantId: string;
  createdAt: string;
  updatedAt: string;
};

type ResponseData = {
  domain: CustomerDomain;
  donationPageSubdomain: string;
};

const API_URL = `${SETTINGS.apiUrl}/customer-management/domain`;

const useCustomerDomain = (tenantId?: string): UseCustomerDomain => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [data, setData] = useState<ResponseData | null>(null);

  const getCustomerDomains = useCallback(async () => {
    if (!tenantId) return;
    setLoading(true);
    try {
      const response = await axios.get<ResponseData>(`${API_URL}/${tenantId}`);
      setData(response.data);
    } catch (err) {
      setError(getNetworkError(err));
    }
    setLoading(false);
  }, [tenantId]);

  useEffect(() => {
    void getCustomerDomains();
  }, [getCustomerDomains]);

  return {
    loading,
    error,
    domain: data?.domain?.domain ?? '',
    donationPageSubdomain: data?.donationPageSubdomain ?? '',
  };
};

export default useCustomerDomain;
