import { useState, useCallback, type Dispatch, type SetStateAction } from 'react';
import toast from 'react-hot-toast';
import axios from 'axios';
import { getNetworkError } from 'common/utils';
import { SwitchInput, ConfirmationModal } from 'ui';

import { type CustomerAccount } from 'core/types';
import { ErrorToast, SuccessToast } from 'components/toasts';
import { SETTINGS } from 'core/constants';
import { type Tenant } from '../../types';

type ToggleContactAnonymizationProps = {
  tenantId?: string;
  account: CustomerAccount | null;
  setTenant: Dispatch<SetStateAction<Tenant>>;
};

const API_URL = `${SETTINGS.apiUrl}/customer-management`;

const ToggleContactAnonymization = (props: ToggleContactAnonymizationProps): JSX.Element => {
  const { tenantId, account, setTenant } = props;
  const isContactAnonymizationEnabled = typeof account?.obscureContacts !== 'boolean' || account.obscureContacts;

  const [confirmModal, setConfirmModal] = useState({ open: false, obscureContacts: isContactAnonymizationEnabled });
  const [loading, setLoading] = useState(false);

  const setCustomerContactAnonymization = useCallback(
    async (obscureContacts: boolean) => {
      if (!tenantId) return;
      setLoading(true);
      try {
        await axios.patch(`${API_URL}/contact-anonymization/${tenantId}`, { obscureContacts });
        setTenant((prevState) => {
          if (prevState.account) {
            return { ...prevState, account: { ...prevState.account, obscureContacts } };
          }
          return prevState;
        });
        setConfirmModal((prev) => ({ ...prev, open: false }));
        toast.custom(
          (t) => (
            <SuccessToast
              visible={t.visible}
              message={`Customer contact anonymization successfully ${obscureContacts ? 'enabled' : 'disabled'}!`}
            />
          ),
          {
            id: 'setCustomerContactAnonymizationSuccess',
          },
        );
      } catch (err) {
        toast.custom((t) => <ErrorToast visible={t.visible} message={getNetworkError(err)} />, {
          id: 'setCustomerContactAnonymizationError',
        });
      }
      setLoading(false);
    },
    [setTenant, tenantId],
  );

  return (
    <>
      <SwitchInput
        label={`${isContactAnonymizationEnabled ? 'Disable' : 'Enable'} Contact Anonymization`}
        checked={isContactAnonymizationEnabled}
        onChange={(checked) => {
          setConfirmModal({ open: true, obscureContacts: checked });
        }}
        disabled={!tenantId}
      />

      <ConfirmationModal
        open={confirmModal.open}
        onClose={() => {
          setConfirmModal({ open: false, obscureContacts: isContactAnonymizationEnabled });
        }}
        onConfirm={() => {
          void setCustomerContactAnonymization(confirmModal.obscureContacts);
        }}
        message={
          confirmModal.obscureContacts
            ? 'Enabling contact anonymization will hide contact details from this customer.'
            : 'Disabling contact anonymization will make contact details fully visible to this customer.'
        }
        loading={loading}
      />
    </>
  );
};

export default ToggleContactAnonymization;
