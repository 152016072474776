import { useEffect, useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';
import debounce from 'lodash.debounce';
import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';
import { DatePicker, TimePicker, HelperText, FormCard, SwitchInput } from 'ui';

import { useSuggestedDraftsStore } from 'stores/suggested-drafts';

type CustomerSendTimeFormProps = {
  open: boolean;
  onClick: () => void;
  disabled?: boolean;
};

type FormValues = {
  customerSendTime: Date;
};

const DEBOUNCE_DELAY_TIME_MS = 500;

const getInitialValues = (sendTime: string): Date => {
  if (sendTime) {
    return new Date(sendTime);
  }
  const defaultDate = new Date();
  defaultDate.setHours(defaultDate.getHours() + 1);
  return defaultDate;
};

export const formValidation = yup.object({
  customerSendTime: yup
    .date()
    .required('Customer send time is required')
    .test(
      'is-future-date',
      'Customer send time must be a date and time at least 15 minutes into the future',
      (value) => (value ? value.getTime() > Date.now() + 1000 * 60 * 15 : false),
    ),
});

const CustomerSendTimeForm = (props: CustomerSendTimeFormProps): JSX.Element => {
  const { open, onClick, disabled = false } = props;
  const { customerSendTime, scheduleSend, updateDraftDetails } = useSuggestedDraftsStore(
    useShallow((state) => ({
      customerSendTime: state.draftDetails.customerSendTime,
      scheduleSend: state.draftDetails.scheduleSend,
      updateDraftDetails: state.updateDraftDetails,
    })),
  );
  const form = useForm<FormValues>({
    initialValues: { customerSendTime: getInitialValues(customerSendTime) },
    validate: yupResolver(formValidation),
    validateInputOnChange: true,
  });

  useEffect(() => {
    if (disabled) return;
    if (form.values.customerSendTime) {
      // Update initial value
      updateDraftDetails({ customerSendTime: form.values.customerSendTime.toISOString() });
    }
    form.validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  const debouncedUpdateDraftDetails = useMemo(
    () => debounce(updateDraftDetails, DEBOUNCE_DELAY_TIME_MS),
    [updateDraftDetails],
  );

  const handleChange = (date: Date): void => {
    form.setFieldValue('customerSendTime', date);
    debouncedUpdateDraftDetails({ customerSendTime: date.toISOString() });
  };

  return (
    <FormCard
      title="Customer Send Time"
      subtitle="When should customers get this campaign?"
      isExpanded={open}
      handleClick={onClick}
      checked={scheduleSend && form.isValid()}
    >
      <div className="space-y-6">
        <SwitchInput
          label="Schedule send"
          checked={scheduleSend}
          onChange={() => {
            updateDraftDetails({ scheduleSend: !scheduleSend });
          }}
          disabled={disabled}
        />

        <div>
          <div className="flex items-center space-x-3">
            <DatePicker
              value={form.values.customerSendTime}
              onChange={handleChange}
              required
              disabled={disabled || !scheduleSend}
            />

            <TimePicker
              value={form.values.customerSendTime}
              onChange={handleChange}
              required
              disabled={disabled || !scheduleSend}
            />
          </div>

          {scheduleSend && !!form.errors.customerSendTime && (
            <HelperText message={form.errors.customerSendTime as string} error containerClassName="mt-1.5" />
          )}
        </div>
      </div>
    </FormCard>
  );
};

export default CustomerSendTimeForm;
