import { type ChangeEvent } from 'react';
import RadioInput from './RadioInput';

type RadioGroupOption = {
  label: string;
  value: string;
};

type RadioGroupProps = {
  legend: string;
  options: RadioGroupOption[];
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  name?: string;
};

const RadioGroup = ({ legend, options, value, onChange, disabled, name }: RadioGroupProps): JSX.Element => {
  return (
    <fieldset className="space-y-1">
      <legend className="text-base font-semibold">{legend}</legend>
      <div className="flex space-x-4">
        {options.map((option) => (
          <RadioInput
            key={option.value}
            id={option.value}
            label={option.label}
            name={name}
            value={option.value}
            checked={value === option.value}
            onChange={onChange}
            containerClassName="flex-row-reverse items-center space-x-2 space-x-reverse !mb-0"
            disabled={disabled}
          />
        ))}
      </div>
    </fieldset>
  );
};

export default RadioGroup;
