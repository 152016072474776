import { type FC, useState, useMemo, useCallback, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { ArrowLeftIcon, DocumentArrowDownIcon } from '@heroicons/react/24/solid';
import toast from 'react-hot-toast';
import axios from 'axios';
import { useInterval } from 'usehooks-ts';
import fileDownload from 'js-file-download';
import isToday from 'date-fns/isToday';
import { parseDonationAmount, getNetworkError } from 'common/utils';
import { SwitchInput, Button, IconButton } from 'ui';

import { SmallTable } from 'components/table';
import { formatDate, getPercentage, sortData } from 'core/utils';
import { type Employee, type ICustomer, type ImportJob, ImportJobStatus, type SelectInputOption } from 'core/types';
import { SETTINGS } from 'core/constants';
import { Loader } from 'components/loader';
import { ErrorToast, SuccessToast } from 'components/toasts';
import { Spinner } from 'components/spinner';
import { InfoItem } from './components/info-item';
import { UploadContactsModal } from './components/upload-contacts-modal';
import { Table } from './components/table';
import { ToggleContactAnonymization } from './components/toggle-contact-anonymization';
import { type Tenant } from './types';

type LocationState = {
  customer: ICustomer;
};

const API_URL = `${SETTINGS.apiUrl}/customer-management`;
const EMPLOYEES_API_URL = `${SETTINGS.apiUrl}/employees`;

const POLLING_INTERVAL_IN_MS = 15000;

const createPresignedDownloadUrl = async (
  tenantId: string,
  documentKey: string,
  bucketName?: string,
): Promise<{ url: string }> => {
  const response = await axios.post(`${API_URL}/download/${tenantId}`, { documentKey, bucketName });
  return response.data;
};

const Customer: FC = () => {
  const [loadingImports, setLoadingImports] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [importJobs, setImportJobs] = useState<ImportJob[]>([]);
  const [loadingAccount, setLoadingAccount] = useState(false);
  const [downloadingDocument, setDownloadingDocument] = useState(false);
  const [employeeOptions, setEmployeeOptions] = useState<SelectInputOption[]>([]);
  const [representative, setRepresentative] = useState<Employee | null>(null);
  const [updatingActiveStatus, setUpdatingActiveStatus] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const initialCustomer = (location.state as LocationState | undefined)?.customer ?? {};
  const [{ account, ...customer }, setTenant] = useState<Tenant>({ ...initialCustomer, account: null });
  const { tenantId } = useParams<{ tenantId: string }>();

  useEffect(() => {
    return () => {
      toast.remove();
    };
  }, []);

  const getCustomerAccount = useCallback(async () => {
    if (!tenantId) return;
    setLoadingAccount(true);
    try {
      const response = await axios.get(`${API_URL}/account/${tenantId}`);
      if (response.data) {
        setTenant(response.data as Tenant);
      }
    } catch (err) {
      toast.custom((t) => <ErrorToast visible={t.visible} message={getNetworkError(err)} />, {
        id: 'getCustomerAccountError',
      });
    }
    setLoadingAccount(false);
  }, [tenantId]);

  const getCustomerImports = useCallback(async () => {
    if (!tenantId) return;
    setLoadingImports(true);
    try {
      const response = await axios.get(`${API_URL}/imports/${tenantId}`);
      setImportJobs(sortData<ImportJob>((response.data ?? []) as ImportJob[], 'createdAt'));
    } catch (err) {
      toast.custom((t) => <ErrorToast visible={t.visible} message={getNetworkError(err)} />, {
        id: 'getCustomerImportsError',
      });
    }
    setLoadingImports(false);
  }, [tenantId]);

  const getEmployees = useCallback(async () => {
    if (!tenantId) return;
    try {
      const response = await axios.get(EMPLOYEES_API_URL);
      const employees: Employee[] = response.data ?? [];
      const options = [
        { label: '', value: '' },
        ...employees.map((employee) => ({ value: employee.employeeId, label: employee.name })),
      ];
      setEmployeeOptions(options);

      const representative = employees.find((employee) => employee.tenantIds.includes(tenantId));
      if (representative) {
        setRepresentative(representative);
      }
    } catch (err) {
      toast.custom((t) => <ErrorToast visible={t.visible} message={getNetworkError(err)} />, {
        id: 'getEmployeesError',
      });
    }
  }, [tenantId]);

  useEffect(() => {
    if (customer?.error) return;
    const getPageData = async (): Promise<void> => {
      await Promise.all([getCustomerImports(), getCustomerAccount(), getEmployees()]);
    };
    void getPageData();
  }, [customer?.error, getCustomerAccount, getCustomerImports, getEmployees]);

  const isInProgress = useMemo(() => {
    return importJobs.some(({ status, importAt }) => {
      const notDone = [ImportJobStatus.NOT_STARTED, ImportJobStatus.VALIDATED, ImportJobStatus.PROCESSING].includes(
        status,
      );
      if (notDone && importAt) {
        return isToday(new Date(importAt));
      }
      return notDone;
    });
  }, [importJobs]);

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  useInterval(getCustomerImports, isInProgress ? POLLING_INTERVAL_IN_MS : null);

  const handleDownloadDocument = async (): Promise<void> => {
    const documentKey = account?.taxDocumentKey;
    if (!documentKey || !tenantId) return;

    setDownloadingDocument(true);
    try {
      const { url: downloadUrl } = await createPresignedDownloadUrl(tenantId, documentKey);
      const res = await axios.get(downloadUrl, { responseType: 'blob', headers: { Authorization: '' } });
      fileDownload(res.data as Blob, documentKey.split('/').slice(-1)[0]);
    } catch (err) {
      toast.custom((t) => <ErrorToast visible={t.visible} message={getNetworkError(err)} />, {
        id: 'handleDownloadDocumentError',
      });
    }
    setDownloadingDocument(false);
  };

  const handleDontionPageSubdomainUpdate = async (subdomain: string): Promise<void> => {
    if (!tenantId) return;
    setLoadingAccount(true);
    try {
      await axios.post(`${API_URL}/subdomain/${tenantId}`, { subdomain });
      await getCustomerAccount();
      setLoadingAccount(false);
      toast.custom((t) => <SuccessToast visible={t.visible} message="Subdomain updated successfully!" />, {
        id: 'handleUploadSuccess',
      });
    } catch (err) {
      setLoadingAccount(false);
      throw err;
    }
  };

  const handleRepresentativeUpdate = async (employeeId: string): Promise<void> => {
    if (!tenantId) return;
    setLoadingAccount(true);

    try {
      // Should delete if either removing representative or changing representative
      if (!employeeId || !!representative) {
        await axios.delete(`${EMPLOYEES_API_URL}/${representative?.employeeId}/tenants/${tenantId}`);
      }

      if (!employeeId) {
        setRepresentative(null);
      } else {
        const response = await axios.post(`${EMPLOYEES_API_URL}/${employeeId}/tenants/${tenantId}`);
        setRepresentative(response.data as Employee);
      }

      toast.custom((t) => <SuccessToast visible={t.visible} message="Representative updated successfully!" />, {
        id: 'handleRepresentativeUpdateSuccess',
      });
    } catch (err) {
      toast.custom((t) => <ErrorToast visible={t.visible} message={getNetworkError(err)} />, {
        id: 'handleRepresentativeUpdateError',
      });
    }

    setLoadingAccount(false);
  };

  const deleteImportJob = useCallback(
    async (createdAt: string) => {
      if (!tenantId) return;
      setLoadingImports(true);
      try {
        await axios.delete(`${API_URL}/imports/${tenantId}?created_at=${createdAt}`);
        setImportJobs((prevState) => prevState.filter((iJ) => iJ.createdAt !== createdAt));
        toast.custom((t) => <SuccessToast visible={t.visible} message="Upload job deleted successfully!" />, {
          id: 'deleteImportJobSuccess',
        });
      } catch (err) {
        toast.custom((t) => <ErrorToast visible={t.visible} message={getNetworkError(err)} />, {
          id: 'deleteImportJobError',
        });
      }
      setLoadingImports(false);
    },
    [tenantId],
  );

  const downloadImportErrors = useCallback(
    async (bucketName: string, documentKey: string, fileName: string) => {
      if (!tenantId) return;
      setLoadingImports(true);
      try {
        const { url: downloadUrl } = await createPresignedDownloadUrl(tenantId, documentKey, bucketName);
        const res = await axios.get(downloadUrl, { responseType: 'blob', headers: { Authorization: '' } });
        fileDownload(res.data as Blob, fileName);
      } catch (err) {
        toast.custom((t) => <ErrorToast visible={t.visible} message={getNetworkError(err)} />, {
          id: 'downloadImportErrorsError',
        });
      }
      setLoadingImports(false);
    },
    [tenantId],
  );

  const setCustomerActiveStatus = useCallback(
    async (active: boolean) => {
      if (!tenantId) return;
      setUpdatingActiveStatus(true);
      try {
        await axios.patch(`${API_URL}/active/${tenantId}`, { active });
        setTenant((prevState) => ({ ...prevState, active }));
        toast.custom(
          (t) => (
            <SuccessToast visible={t.visible} message={`Customer successfully ${active ? 'enabled' : 'disabled'}!`} />
          ),
          {
            id: 'setCustomerActiveStatusSuccess',
          },
        );
      } catch (err) {
        toast.custom((t) => <ErrorToast visible={t.visible} message={getNetworkError(err)} />, {
          id: 'setCustomerActiveStatusError',
        });
      }
      setUpdatingActiveStatus(false);
    },
    [tenantId],
  );

  const customerData = useMemo(() => {
    return [
      { label: 'Customer #', value: tenantId ?? '-' },
      { label: '# of Contacts', value: customer?.contacts?.totalCount || 0 },
      { label: '# of DS Contacts', value: customer?.contacts?.dsCount || 0 },
      {
        label: '% DonorSpring',
        value:
          customer?.contacts?.totalCount && customer?.contacts?.dsCount
            ? getPercentage(customer.contacts.dsCount, customer.contacts.totalCount)
            : '0%',
      },
      { label: '# of Opted-in DS contacts', value: customer?.contacts?.optedInDsCount || 0 },
      {
        label: '% opted-in DS',
        value:
          customer?.contacts?.dsCount && customer?.contacts?.optedInDsCount
            ? getPercentage(customer.contacts.optedInDsCount, customer.contacts.dsCount)
            : '0%',
      },
      { label: 'Total Raised', value: parseDonationAmount((customer?.donations?.netAmount || 0) / 100) }, // Amount is in penies
      { label: 'Total Raised via DS', value: parseDonationAmount((customer?.donations?.dsNetAmount || 0) / 100) }, // Amount is in penies
      { label: 'Sign Up', value: formatDate(customer?.signedUpAt ? Number(customer.signedUpAt) * 1000 : '') },
      { label: 'Last Login', value: formatDate(customer?.lastLoginAt ? Number(customer.lastLoginAt) * 1000 : '') },
    ];
  }, [tenantId, customer]);

  const { error, cause } = useMemo(() => {
    let errorCause: Record<string, any> = {};
    try {
      if (customer?.error?.Cause) {
        errorCause = JSON.parse(customer.error.Cause);
      }
    } catch (error) {
      /** */
    }
    return { error: customer?.error?.Error, cause: errorCause };
  }, [customer?.error]);

  const isTenantActive = typeof customer?.active === 'undefined' || !!customer.active;

  return (
    <div className="flex flex-col">
      <div className="flex justify-between">
        <IconButton
          Icon={<ArrowLeftIcon className="size-5" />}
          srOnly="Go back"
          onClick={() => {
            navigate(-1);
          }}
          className="size-10"
        />
        <h1 className="mt-4 text-center text-3xl">
          {customer?.name ?? customer?.email ?? customer?.user?.email ?? 'Customer Name'}
          {!!error && (
            <>
              <br />
              <span className="text-lg">{customer?.email}</span>
            </>
          )}
        </h1>
        <div className="size-5" />
      </div>

      <div className="mt-8 flex w-full max-w-7xl flex-col self-center">
        {error ? (
          <div
            className="mb-10 mt-2 flex w-3/4 self-center rounded-lg bg-red-100 p-4 text-base text-red-700 dark:bg-red-200 dark:text-red-800"
            role="alert"
          >
            <svg
              aria-hidden="true"
              className="mr-3 inline size-5 shrink-0"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Error</span>
            <div>
              <span className="font-medium">Error: {error}</span>
              {cause && (
                <ul className="ml-4 mt-1.5 list-inside list-disc text-red-700">
                  {Object.keys(cause).map((key) => (
                    <li key={key}>
                      {key}: {cause[key]}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className="mb-2 flex h-10 w-fit items-center space-x-2">
              <SwitchInput
                label={isTenantActive ? 'Active' : 'Inactive'}
                checked={isTenantActive}
                onChange={(checked) => {
                  void setCustomerActiveStatus(checked);
                }}
                disabled={updatingActiveStatus}
              />
              {updatingActiveStatus && <Spinner className="size-6" />}
            </div>

            <div className="mb-2 flex h-10 w-fit items-center space-x-2">
              <ToggleContactAnonymization tenantId={tenantId} account={account} setTenant={setTenant} />
              {loadingAccount && <Spinner className="size-6" />}
            </div>

            <div className="flex flex-1">
              <div className="w-5/12">
                <div className="w-10/12">
                  <SmallTable data={customerData} />
                </div>
              </div>
              <div className="relative w-7/12 rounded-lg bg-white-100 p-6 shadow-md">
                <InfoItem label="Organization Name:" value={account?.organizationName ?? ''} />
                <InfoItem label="Legal Name:" value={account?.legalName ?? ''} />
                <InfoItem label="Website URL:" value={account?.websiteUrl ?? ''} />
                <InfoItem label="Tax ID:" value={account?.taxId ?? ''} />
                <InfoItem label="Legal Address:" value={account?.legalAddress ?? ''} />
                <InfoItem
                  label="Donation Page Subdomain:"
                  value={account?.donationPageSubdomain ?? ''}
                  editable
                  validate={(value) => (/^[A-Za-z0-9-]+$/.test(value) ? '' : 'Invalid subdomain.')}
                  onSave={handleDontionPageSubdomainUpdate}
                />
                {account?.subscription?.status === 'active' && (
                  <>
                    <InfoItem label="Subscription Plan:" value={account.subscription.planName ?? ''} />
                    <InfoItem
                      label="Subscription Start Date:"
                      value={account.subscription.startDate ? formatDate(account.subscription.startDate * 1000) : ''}
                    />
                  </>
                )}
                <InfoItem
                  label="Representative:"
                  value={representative?.employeeId ?? ''}
                  editable
                  inputType="select"
                  options={employeeOptions}
                  onSave={handleRepresentativeUpdate}
                />

                <div className="mt-10 flex flex-col">
                  <p className="text-2xl font-semibold ">Document upload of proof of tax exempt status:</p>
                  <div className="mt-4 self-center">
                    <IconButton
                      Icon={<DocumentArrowDownIcon className="size-10" />}
                      srOnly="Download document"
                      onClick={() => {
                        void handleDownloadDocument();
                      }}
                      disabled={downloadingDocument || !account?.taxDocumentKey}
                      loading={downloadingDocument}
                    />
                  </div>
                </div>

                {loadingAccount && <Loader />}
              </div>
            </div>

            <div className="relative mt-14 flex flex-col items-center space-y-4">
              <Button
                title="Import New List"
                color="primary"
                className="w-fit"
                onClick={() => {
                  setOpenUpload(true);
                }}
              />

              <div className="w-full">
                <Table
                  data={importJobs}
                  deleteImportJob={deleteImportJob}
                  downloadImportErrors={downloadImportErrors}
                />
              </div>

              {loadingImports && <Loader />}
            </div>
          </>
        )}
      </div>

      <UploadContactsModal
        open={openUpload}
        onClose={() => {
          setOpenUpload(false);
        }}
        refetch={getCustomerImports}
        customerSubscription={account?.subscription}
      />
    </div>
  );
};

export default Customer;
