import { type UnlayerOptions } from 'react-email-editor';
import { DONATION_TYPES, DONATION_TYPE_LABELS, DONATE_BUTTON_DEFAULT_SETTINGS } from 'common/constants';

import { SETTINGS } from 'core/constants';

type LinkType = {
  name: string;
  enabled?: boolean;
  label?: string;
  attrs?: Record<string, string>;
  fields?: Array<{
    name: string;
    label: string;
    defaultValue: string;
    inputType?: string | null;
    placeholderText?: string | null;
    options?: Array<{
      value: string;
      label: string;
    }>;
  }>;
};

export const getDonationLink = (subdomain: string = '', params?: Record<string, string>): string => {
  let url = 'https://';
  if (subdomain) url += `${subdomain}.`;
  url += `${SETTINGS.donationPageDomain}`;
  if (params) {
    const queryParams = new URLSearchParams(params);
    url += `/?${queryParams.toString()}`;
  }
  return url;
};

export const getLinkTypes = (donationPageUrl: string): LinkType[] => [
  {
    name: 'web',
    enabled: false,
  },
  {
    name: 'phone',
    enabled: false,
  },
  {
    name: 'email',
    enabled: false,
  },
  {
    name: 'sms',
    enabled: false,
  },
  {
    name: 'static_donation_page_link',
    label: 'Go To Donation Page',
    attrs: {
      href: `${donationPageUrl}&amount={{amount}}&type={{type}}`,
      target: '_blank',
    },
    fields: [
      {
        name: 'amount',
        label: 'Amount',
        defaultValue: DONATE_BUTTON_DEFAULT_SETTINGS.amount,
        inputType: 'number',
        placeholderText: null,
      },
      {
        name: 'type',
        label: 'Type',
        defaultValue: DONATE_BUTTON_DEFAULT_SETTINGS.type,
        inputType: null,
        placeholderText: null,
        options: [
          { value: DONATION_TYPES.recurring, label: DONATION_TYPE_LABELS.recurring },
          { value: DONATION_TYPES.oneTime, label: DONATION_TYPE_LABELS[DONATION_TYPES.oneTime] },
        ],
      },
    ],
  },
];

export const getEditorOptions = (campaignId: string, donationPageSubdomain: string): UnlayerOptions => ({
  tools: {
    button: {
      properties: {
        // @ts-expect-error custom button config
        buttonColors: {
          value: {
            color: DONATE_BUTTON_DEFAULT_SETTINGS.textColor,
            backgroundColor: DONATE_BUTTON_DEFAULT_SETTINGS.backgroundColor,
          },
        },
        // @ts-expect-error custom button config
        borderRadius: {
          value: '0px',
        },
        // @ts-expect-error custom button config
        text: {
          value: DONATE_BUTTON_DEFAULT_SETTINGS.buttonText,
        },
        // @ts-expect-error custom button config
        href: {
          value: {
            name: 'static_donation_page_link',
            attrs: {
              href: `${getDonationLink(donationPageSubdomain, {
                campaignId,
                amount: DONATE_BUTTON_DEFAULT_SETTINGS.amount,
                type: DONATE_BUTTON_DEFAULT_SETTINGS.type,
              })}`,
              target: '_blank',
            },
          },
        },
      },
    },
  },
});
