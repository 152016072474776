import { type FC, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { getNetworkError } from 'common/utils';
import { Button } from 'ui';

import { CreateInviteModal } from './components/create-invite-modal';
import { SETTINGS } from 'core/constants';
import { ErrorToast } from 'components/toasts';
import { sortData } from 'core/utils';
import { type Invite } from 'core/types';
import { Loader } from 'components/loader';
import { Table } from './components/table';

const API_URL = `${SETTINGS.apiUrl}/customer-management/invites`;

const Invites: FC = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invites, setInvites] = useState<Invite[]>([]);

  const getInvites = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(API_URL);
      setInvites(sortData<Invite>((response.data ?? []) as Invite[], 'createdAt'));
    } catch (err) {
      toast.custom((t) => <ErrorToast visible={t.visible} message={getNetworkError(err)} />, {
        id: 'getInvitesError',
      });
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    void getInvites();
  }, [getInvites]);

  const handleClose = (): void => {
    setCreateModalOpen(false);
    void getInvites();
  };

  return (
    <div className="relative w-full max-w-7xl space-y-6 self-center">
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-semibold">Invites</h2>

        <Button
          title="Create Invite"
          color="primary"
          onClick={() => {
            setCreateModalOpen(true);
          }}
        />
      </div>

      <div className="relative">
        <Table data={invites} />

        {loading && <Loader />}
      </div>

      <CreateInviteModal open={createModalOpen} onClose={handleClose} />
    </div>
  );
};

export default Invites;
